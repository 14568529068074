import {JsonProperty} from 'json-object-mapper';

export class DashboardLearningApp {
  @JsonProperty() public alphaAverageProductivity: number;
  @JsonProperty() public averageProductivity: number;
  @JsonProperty() public learningAppName: string;
  @JsonProperty() public learningUnit: string;
  @JsonProperty() public learningUnitPassed: string;
  @JsonProperty() public learningUnitPassedDescription: string;
  @JsonProperty() public levelsCompleted: number;
  @JsonProperty() public minutesSpent: number;
  @JsonProperty() public relativeProductivity: number;

  public constructor() {
    this.alphaAverageProductivity      = null;
    this.averageProductivity           = null;
    this.learningAppName               = null;
    this.learningUnit                  = null;
    this.learningUnitPassed            = null;
    this.learningUnitPassedDescription = null;
    this.levelsCompleted               = null;
    this.minutesSpent                  = null;
    this.relativeProductivity          = null;
  }
}
