import { ResourceType, LearningResource } from '@coach-bot/data-access/math-skills-stack';

export class LearningResourceHelperUtility {
  public static getLearnSectionResources(
    learningResources: LearningResource[],
  ): LearningResource[] {
    return learningResources.filter(
      (resource) => resource.type === ResourceType.Learning);
  }

  public static getPracticeSectionResources(
    learningResources: LearningResource[],
  ): LearningResource[] {
    return learningResources.filter(
      (resource) => resource.type === ResourceType.Practice);
  }

  public static getAdditionalSectionResources(
    learningResources: LearningResource[],
  ): LearningResource[] {
    return learningResources.filter(
      (resource) => resource.type === ResourceType.AdditionalResource);
  }
}
