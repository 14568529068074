import {Course, Standard} from 'libs/coach-bot/data-access/math-skills-stack/src/lib/models';
import {ObjectMapper} from 'json-object-mapper';

/** get updated courses to visually present that the standard is now self assessed or not **/
export function getData(data: Course[], standardExternalId: string, name: string): Course[] {
  return data.map(course => {
    if(course.name == name) {
      return getCourse(course, standardExternalId);
    }

    return course;
  });
}

function getCourse(course: Course, standardExternalId: string): Course {
  let updatedStandards = [];
  let updatedCourse: Course = ObjectMapper.deserialize(Course, course);

  for(let standardRow of course.standards) {
    let newRow = [];
    for(let standardCol of Object.values(standardRow)) {
      let newCol = ObjectMapper.deserialize(Standard, standardCol);
      if(standardCol.externalId == standardExternalId) {
        newCol.isSelfAssessed = !standardCol.isSelfAssessed;
      }
      newRow.push(newCol);
    }
    updatedStandards.push(newRow);
  }
  updatedCourse.standards = updatedStandards as any;
  return updatedCourse;
}
