import {Component} from '@angular/core';

import StudentFacade from '@app/core/facades/student.facade';
import {showHide} from '@app/shared/animations/show-hide.animation';
import AuthenticationFacade from '@app/core/facades/authentication.facade';
import {NotificationsFacade} from '@app/modules/notifications/facades/notifications.facade';

@Component({
  animations: [showHide],
  selector: 'notification-popup',
  styleUrls: ['./notification-popup.component.scss'],
  templateUrl: './notification-popup.component.html',
})
export class NotificationPopupComponent {
  constructor(
    public _notificationsFacade: NotificationsFacade,
    public _studentFacade: StudentFacade,
    public _authfacade: AuthenticationFacade
  ) { }

  /** Toggle the notification popup on bell icon click */
  public toggleNotification(event: MouseEvent): void {
    this._notificationsFacade.showNotifications();
    event.stopPropagation();
  }
}
