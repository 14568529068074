import {Inject, Injectable} from '@angular/core';
import API from '@aws-amplify/api';
import {GRAPHQL_AUTH_MODE, GraphQLOptions} from '@aws-amplify/api-graphql';

import {Auth} from '@aws-amplify/auth';
import {NormalizedCacheObject} from 'apollo-cache-inmemory';
import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync';
import gql from 'graphql-tag';
import {defer, from, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import * as ZenObservable from 'zen-observable';

import {Environment} from '../definitions/environment';

import {AWSResponse, AwsSubscribeResponse} from '../models';
import {ENVIRONMENT} from '../tokens';

@Injectable()
export class AppSyncHelper {
  private static appSyncClient: AWSAppSyncClient<NormalizedCacheObject>              = undefined;
  private static appSyncClientViaApiKeyAuth: AWSAppSyncClient<NormalizedCacheObject> = undefined;

  constructor(@Inject(ENVIRONMENT) private readonly env: Environment) {
    // AppSyncHelper config with COGNITO AUTH TYPE
    if (!AppSyncHelper.appSyncClient) {
      AppSyncHelper.appSyncClient = new AWSAppSyncClient({
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
        }, disableOffline: true, region: 'us-east-1', url: this.env.aws_appsync_graphqlEndpoint
      });
    }

    // AppSyncHelper config with API KEY AUTH TYPE
    if (!AppSyncHelper.appSyncClientViaApiKeyAuth) {
      AppSyncHelper.appSyncClientViaApiKeyAuth = new AWSAppSyncClient({
        auth: {
          type: AUTH_TYPE.API_KEY, apiKey: this.env.aws_appsync_apiKey
        }, disableOffline: true, region: 'us-east-1', url: this.env.aws_appsync_graphqlEndpoint
      });
    }
  }

  public mutate<T, V>(mutation: string, variables: V): Observable<T> {
    return defer(() => AppSyncHelper.appSyncClient.mutate({
      variables, fetchPolicy: 'no-cache', mutation: gql(mutation)
    })).pipe(map((res: AWSResponse<T>) => res.data));
  }

  public query<T, V>(query: string, variables: V): Observable<T> {
    return defer(() => AppSyncHelper.appSyncClient.query({
      variables, fetchPolicy: 'network-only', query: gql(query)
    })).pipe(map((res) => res.data as T));
  }

  public queryViaApiKeyAuth<T, V>(query: string, variables: V): Observable<T> {
    return defer(() => AppSyncHelper.appSyncClientViaApiKeyAuth.query({
      variables, fetchPolicy: 'network-only', query: gql(query)
    })).pipe(map((res) => res.data as T));
  }

  public mutateViaApiKeyAuth<T, V>(mutation: string, variables: V): Observable<T> {
    return defer(() => AppSyncHelper.appSyncClientViaApiKeyAuth.mutate({
      variables, fetchPolicy: 'no-cache', mutation: gql(mutation)
    })).pipe(map((res) => res.data as T));
  }

  /**
   * AWS Helper function to handle graphQl subscriptions
   * @param {string} query
   * @param {V} variables
   * @returns {Observable<T>}
   */
  public subscribe<T, V>(query: string, variables: V): Observable<T> {
    return new Observable((observer) => {
      return this.getJWTToken().subscribe((token) => {
        const sub = API.graphql({
          query, variables, authToken: token, authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        } as unknown as GraphQLOptions) as unknown as ZenObservable<AwsSubscribeResponse<T>>;

        return sub.subscribe((data) => observer.next(data.value));
      });
    }).pipe(map((res: AWSResponse<T>) => res.data));
  }

  public watchQuery<T, V>(query: string, variables: V): Observable<T> {
    return new Observable((observer) => {
      AppSyncHelper.appSyncClient
        .watchQuery({
          variables, fetchPolicy: 'cache-and-network', query: gql(query)
        })
        .subscribe((res) => observer.next(res));
    }).pipe(map((item: AWSResponse<T>) => item?.data));
  }

  private getJWTToken() {
    return from(Auth.currentSession()).pipe(take(1), map((res) => res.getIdToken().getJwtToken()));
  }
}
