import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';

import {STORAGE_KEY, VERSION_FILE_NAME} from '../../config/app.config';
import {RangeSliderService} from '../range-slider/range-slider.service';

interface Version {
  version: string;
}

@Injectable()
export class VersionService {
  public constructor(
    private readonly _http: HttpClient,
    private readonly _rangeSliderService: RangeSliderService
  ) {
    this.get()
    .pipe(
      take(1),
      map(({version}) => version)
    )
    .subscribe(version => localStorage.setItem(STORAGE_KEY.version, version));
  }

  /**
   * Gets the latest version of the application contained in the `version.json` file at the root of the project.
   * The timestamp parameter is used to bypass possible request caches.
   */
  public get(): Observable<Version> {
    return this._http
    .get<Version>(`${VERSION_FILE_NAME}?timestamp=${new Date().getTime()}`)
    .pipe(catchError(_ => of({version: null})));
  }

  /**
   * Cleans up all non-auth and version related local storage data and reloads the page.
   */
  public getLatestApplicationVersion(): void {
    localStorage.removeItem(STORAGE_KEY.level);
    localStorage.removeItem(STORAGE_KEY.studentId);
    localStorage.removeItem(STORAGE_KEY.range);
    location.reload();
  }

  public checkIfVersionsAreDifferent(): Observable<boolean> {
    return forkJoin([this.get(), of(localStorage.getItem(STORAGE_KEY.version))]).pipe(
      map(([{version}, previous]) => {
        localStorage.setItem(STORAGE_KEY.version, version);

        if(version === null || previous === null) {
          return false;
        }

        return version !== previous;
      })
    );
  }

  public processCheckIfVersionsAreDifferent(different: boolean): void {
    if(different) {
      this.getLatestApplicationVersion();
    }

    if(this._rangeSliderService.isRangeStale()) {
      this._rangeSliderService.expireRange();
    }
  }
}
