import {Injectable} from '@angular/core';

import {ObjectMapper} from 'json-object-mapper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppSyncHelper} from '@coach-bot/data-access/core';
import {CreateNotification} from '@app/modules/notifications/models/create-notification-response';
import {GetNotificationsQuery} from '@app/modules/notifications/models/get-notfication-query.model';
import {NewNotification} from '@app/modules/notifications/models/new-notification.model';
import {NotificationInput} from '@app/modules/notifications/models/notification-input.model';
import {NotificationModel} from '@app/modules/notifications/models/notification.model';
import {CREATE_NOTIFICATION} from '@app/modules/notifications/resolvers/create-notification.mautation';
import {NEW_NOTIFICATION} from '@app/modules/notifications/resolvers/notification.subscription';
import {GET_NOTIFICATIONS} from '@app/modules/notifications/resolvers/notifications.query';
import {UPDATE_NOTIFICATION} from '@app/modules/notifications/resolvers/update-notification.mutation';

@Injectable({providedIn: 'root'})
export class NotificationsService {
  constructor(private readonly _appSyncHelper: AppSyncHelper) {
  }

  /** To be used to fetch the notifications list from backend. */
  public getNotifications(studentId: string): Observable<NotificationModel[]> {
    return this._appSyncHelper
      .query<GetNotificationsQuery, {studentId: string}>(GET_NOTIFICATIONS, {studentId})
      .pipe(map(response => ObjectMapper.deserializeArray(NotificationModel, response.getNotifications)));
  }

  /** To be used to create a new notification. */
  public createNotification(notification: NotificationInput): Observable<NotificationModel> {
    return this._appSyncHelper.mutate(CREATE_NOTIFICATION, {
      input: notification
    }).pipe(map(
      (res: CreateNotification) => ObjectMapper.deserialize(NotificationModel, res.createNotification)
    ));
  }

  /** To be used to fetch new notification by subscribing the notification api. */
  public onNewNotification(studentId: string): Observable<NotificationModel> {
    return this._appSyncHelper.subscribe<NewNotification, {studentId: string}>(NEW_NOTIFICATION, {studentId})
      .pipe(map(res => ObjectMapper.deserialize(NotificationModel, res.onCreateNotification)));
  }

  /** To be used to update the notification status using notification id. */
  public updateNotification(notificationId: string): Observable<NotificationModel[]> {
    return this._appSyncHelper
        .mutate(UPDATE_NOTIFICATION, {notificationId});
  }
}
