import {JsonProperty} from 'json-object-mapper';

export class Assignment {
  @JsonProperty() public studentId: string;
  @JsonProperty() public houseId: string;
  @JsonProperty() public houseName: string;
  @JsonProperty() public houseImage?: string | null;
  @JsonProperty() public guideId: string;
  @JsonProperty() public guideName: string;

  public constructor() {
    this.studentId  = null;
    this.houseId    = null;
    this.houseName  = null;
    this.houseImage = null;
    this.guideId    = null;
    this.guideName  = null;
  }
}
