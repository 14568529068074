import {Course} from '@app/core/models/course.model';
import {GradeLevel} from '@app/core/models/grade-level.model';

/** sort by gradeLeve */
export function gradeLevelSorting(a: Course, b: Course) {
  const gradeSeparator = ' - ';
  const limit = 2;
  const nameASplit = a.name.split(gradeSeparator, limit);
  const nameBSplit = b.name.split(gradeSeparator, limit);
  const [startA] = nameASplit;
  const [startB] = nameBSplit;

  if (nameASplit.length > 1 && nameBSplit.length > 1 && startA === startB) {
    const gradeLevelOrder = Object.values(GradeLevel) as string[];
    const endA = nameASplit[1];
    const endB = nameBSplit[1];
    return gradeLevelOrder.indexOf(endA) - gradeLevelOrder.indexOf(endB);
  }

  return a.name.localeCompare(b.name, undefined, { numeric: true });
}

/** compare two values give the asc order*/
export function compare(a: number | string, b: number | string, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
