import {animate, style, transition, trigger} from '@angular/animations';

export const showHide = trigger('showHide', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.35s ease', style({ 'opacity': '1' }))
  ]) ,
  transition(':leave', [
    style({ opacity: 1 }),
    animate('0.35s ease', style({ 'opacity': 0 }))
  ])
]);
