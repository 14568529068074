import { JsonProperty } from 'json-object-mapper';
import {LearningResourseAppNames} from '../enums';

const HUNDRED = 100;
const MASTERY_COLOR = '#E0F1DF';
const NOT_FULLY_MASTERY_COLOR = '#E8F9EA';
const NOT_MASTERY_COLOR = '#F2F2F2';
const LIGHT_GREEN_COLOR = '#208437';
const BLACK_COLOR = '#000';


export class LearningResource {
  @JsonProperty() public name = '';
  @JsonProperty() public url: string;
  @JsonProperty() public type: string;
  @JsonProperty() public order: number;
  @JsonProperty({name: 'learning_app_name'}) public learningAppName: string;
  @JsonProperty() public masteryPercentage?: number;
  @JsonProperty() public label?: boolean;

  constructor() {
    this.name = '';
    this.url = '';
    this.type = '';
    this.learningAppName = null;
    this.order = null;
    this.masteryPercentage = null;
    this.label = false;
  }

  public get learningResourceAppName(): LearningResourseAppNames | string {
    if(this.learningAppName) return this.learningAppName;
    try {
      const { hostname } = new URL(this.url);
      if (hostname.toLowerCase().includes('khan')) {
        return LearningResourseAppNames.Khan;
      } else if (hostname.toLowerCase().includes('knowre')) {
        return LearningResourseAppNames.Knowre;
      } else if (hostname.toLowerCase().includes('ixl')) {
        return LearningResourseAppNames.IXL;
      } else if (hostname.toLowerCase().includes('youtube')) {
        return LearningResourseAppNames.Youtube;
      }
      return LearningResourseAppNames.Knowre;
    } catch (error) {
      return LearningResourseAppNames.Knowre;
    }
  }

  public get color(): string {
    if (this.masteryPercentage === HUNDRED) {
      return MASTERY_COLOR;
    } else if (this.masteryPercentage === 0) {
      return NOT_MASTERY_COLOR;
    } else {
      return NOT_FULLY_MASTERY_COLOR;
    }
  }

  public get textColor(): string {
    return this.masteryPercentage === 0 ? BLACK_COLOR : LIGHT_GREEN_COLOR;
  }

  public get displayName(): string {
    return `${this.learningResourceAppName} - ${this.name}`;
  }

  public get smartScore(): string | number {
    return this.masteryPercentage ?? '-';
  }

  /**
   * @author Milos Sretin
   * @since 1.0.0
   */
  public get shortName(): string {
    return this.name.replace(this.uid, '')
  }

  /**
   * @author Milos Sretin
   * @since 1.0.0
   */
  public get uid(): string {
      const startIndex = this.name.indexOf('(');
      const endIndex = this.name.indexOf(')');
      return this.name.substring(startIndex, endIndex + 1);
  }
}
