export const GET_URL_FOR_LEADERBOARD = /* GraphQL */ `
  query GetUrlForLeaderboard($leaderboardId: String!, $studentId: String!) {
    getUrlForLeaderboard(leaderboardId: $leaderboardId, studentId: $studentId) {
      embedUrl
      parameters {
        studentId
        leaderboardKey
        metricHeader
        additionalDataHeader
        periodicity
        period
      }
    }
  }
`;
