import { Pipe, PipeTransform } from '@angular/core';

import {intervalToDuration} from 'date-fns';

@Pipe({ name: 'age' })
export class AgePipe implements PipeTransform {
  transform(dateOfBirth: string): string {
    const startDate = dateOfBirth ? new Date(dateOfBirth) : new Date();
    const { years, months } = intervalToDuration({ start: startDate, end: new Date()});
    return `${years}y, ${months}m`;
  }
}
