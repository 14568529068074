<div fxFlex="376px"
     fxLayout="column"
     class="resource-container"
     *ngIf="learningApps"
>
  <div class="standard-resource">
    <strong>Math Standard:</strong> {{ standardCol.description }}
  </div>
  <div><strong>Standard ID:</strong> {{ standardCol.externalId }}</div>
  <ng-container [ngTemplateOutlet]="learnTPl"></ng-container>
  <ng-container [ngTemplateOutlet]="practiceTpl"></ng-container>
  <ng-container [ngTemplateOutlet]="additionalTpl"></ng-container>
  <ng-container [ngTemplateOutlet]="markButton"></ng-container>
</div>

<ng-template #learnTPl>
  <div
    *ngIf="standardCol.learnSectionResources?.length"
    class="k-d-flex-col"
    [style.row-gap.px]="4"
  >
    <div *ngIf="mathStack">To <strong>learn</strong> this standard, use the following resources:</div>
    <div *ngIf="!mathStack">
      <div class="header-section" [style.background]="standardCol.headerColor">
        <span *ngIf="!standardCol.isMastered">To <strong>master</strong> this standard, achieve a smart score of 100
                                              in each of the below IXL skills:</span>
        <span *ngIf="standardCol.isMastered"><strong>Mastered!</strong> You have achieved a smart score of 100
                                                                        in each of the below IXL skills.</span>
        <circle-progress
          class="circle-progress-ixl"
          *ngIf="standardCol"
          [percent]="standardCol.masteryPercentage"
          [showUnits]="false"
          [showZeroOuterStroke]="false"
          [outerStrokeGradient]="false"
          [showTitle]="false"
          [outerStrokeColor]="standardCol.progressColor"
          [subtitle]="standardCol.progressValue"
          [showSubtitle]="true"
          [animation]="false"
        ></circle-progress>
      </div>
    </div>
    <ng-container
      [ngTemplateOutlet]="resourceListTpl"
      [ngTemplateOutletContext]="{ $implicit: standardCol.learnSectionResources }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #practiceTpl>
  <div
    *ngIf="standardCol?.practiceSectionResources?.length"
    class="k-d-flex-col"
    [style.row-gap.px]="4"
  >
    <div *ngIf="mathStack">To <strong>practice</strong> this standard, use the following resources:</div>
    <div *ngIf="!mathStack">
      To <strong>master</strong> this standard, achieve a skills score of 100 in each of the below IXL skills:</div>
    <ng-container
      [ngTemplateOutlet]="resourceListTpl"
      [ngTemplateOutletContext]="{ $implicit: standardCol.practiceSectionResources }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #additionalTpl>
  <div
    *ngIf="standardCol.additionalSectionResources?.length"
    class="k-d-flex-col"
    [style.row-gap.px]="4"
  >
    <div>Additional resources if needed:</div>
    <ng-container
      [ngTemplateOutlet]="resourceListTpl"
      [ngTemplateOutletContext]="{ $implicit: standardCol.additionalSectionResources }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #markButton>
  <div class="mark-as-learned" *ngIf="standardCol.isShowSelfAssessedFeature && selfAssessment">
    <span> Confident that you learned the standard? </span>
    <button class="k-mt-2"
            [fillMode]="getFillMode()"
            kendoButton
            themeColor="primary"
            type="button"
            (click)="toggleLearned()">
      <kendo-loader
        *ngIf="updating"
        size="small"
        themeColor="light"
        type="pulsing"></kendo-loader>
      {{ getButtonText() }}
    </button>
  </div>
</ng-template>

<ng-template #resourceListTpl let-resources>
  <div *ngFor="let resource of resources" class="k-d-flex k-align-items-center learning-container"
       [style.column-gap.px]="4">
    <div class="resource-img w-100" *ngIf="resource.learningResourceAppName">
      <img *ngIf="getAppImage(resource.learningResourceAppName) as appName" [src]="appName" />
    </div>
    <div class="w-100">
      <ng-container *ngIf="noDialog else dialogLink">
        <div *ngIf="mathStack">
          <a *ngIf="resource.url" target="_blank" [href]="resource.url">{{ resource.displayName }}</a>
          <span *ngIf="!resource.url">{{ resource.displayName }}</span>
        </div>

        <div class="learning-resource-item" *ngIf="!mathStack">
          <a *ngIf="resource.url" target="_blank" [href]="resource.url">{{ resource.name }}</a>
          <span *ngIf="!resource.url" [class.strong]="resource.label">{{ resource.name }}</span>
          <div class="percentage-container"
               *ngIf="resource.masteryPercentage !== null"
               [style.color]="resource?.textColor"
               [style.background]="resource?.color">
            {{ resource.masteryPercentage}}
          </div>
        </div>

      </ng-container>
    </div>
    <ng-template #dialogLink>
      <a (click)="showDialog.emit()">{{ resource.displayName }}</a>
    </ng-template>
  </div>
</ng-template>
