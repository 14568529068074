import {Injectable} from '@angular/core';

interface JiraCollectorWindow extends Window {
  showCollectorDialog?: () => void;
  ATL_JQ_PAGE_PROPS?: {fieldValues: {[key: string]: string | string[]}, triggerFunction: (showCollectorDialog: () => void) => void};
}

function getWindow(): JiraCollectorWindow {
  return window;
}

@Injectable()
export class WindowRefService {
  get nativeWindow(): JiraCollectorWindow {
    return getWindow();
  }
}
