import {Injectable} from '@angular/core';

import {LoginState} from '@app/modules/auth/states/login-state';

@Injectable({ providedIn: 'root' })
export class LoginFacade {
  public error$ =                this.state.error$;
  public loading$ =              this.state.loading$;
  public maintenance$ =          this.state.maintenance$;
  public maintenanceEndTime$ =   this.state.maintenanceEndTime$;
  public maintenanceStartTime$ = this.state.maintenanceStartTime$;
  public submitted$ =            this.state.submitted$;
  public isLoggedIn$ =           this.state.isLoggedIn$;
  public isSessionCreated$ =     this.state.isSessionCreated$;
  public isTermsModalOpened$ =   this.state.isTermsModalOpened$;
  public resetForm$ =            this.state.resetForm$;
  public disableBackground$ =    this.state.disableBackground$;

  public getRedirectUrl =        this.state.getRedirectUrl.bind(this.state);
  public setRedirectUrl =        this.state.setRedirectUrl.bind(this.state);
  public login =                 this.state.login.bind(this.state);
  public register =              this.state.register.bind(this.state);
  public resetError =            this.state.resetError.bind(this.state);

  constructor(private readonly state: LoginState) { }
}
