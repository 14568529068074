import {Injectable} from '@angular/core';

import {ObjectMapper} from 'json-object-mapper';
import {BehaviorSubject, combineLatest, EMPTY, Observable, timer} from 'rxjs';
import {debounce, distinctUntilChanged, filter, first, map, shareReplay, take, tap} from 'rxjs/operators';

import {ENROLL_STUDENT_IN_PROGRAM} from '@app/core/resolvers/mutations/enroll-student-in-program.mutation';
import {GET_STUDENT} from '@app/core/resolvers/queries/get-student.query';
import {LIST_STUDENT_USERS} from '@app/core/resolvers/queries/list-student-users.query';
import {UPDATE_STUDENT_MAP_GRADE_LEVEL} from '@app/core/resolvers/mutations/update-student-grade.mutation';
import {
  EnrollStudentInProgramMutation,
  GetStudentOutput,
  GetStudentQuery,
  GetStudentQueryVariables,
  ListStudentUsersQuery,
  UpdateStudentGradeMutationVariables,
  UpdateStudentGradeMutation,
  UpsertStudentPlacementGradeMutation,
  UpsertStudentPlacementGradeMutationVariables,
} from '../../../API.service';
import {AppSyncHelper} from '@coach-bot/data-access/core';
import {STORAGE_KEY} from '../../config/app.config';
import {Student} from '@app/core/models/student.model';
import {AlphaLevelService} from '../alpha-level/alpha-level.service';
import {AuthenticationService} from '@coach-bot/data-access/auth';
import {ProgramEnrollment} from '@app/core/models/program-enrollment.model';
import {StudentUser} from '@app/core/models/student-user.model';
import {MapGradeLevel} from '@app/core/models/enumerations/map-grade-level.enum';
import { GradeLevel } from '../../models/enumerations/grade-level.enum';
import { UPSERT_STUDENT_PLACEMENT_GRADE } from '../../resolvers/mutations/upsert-student-placement-grade.mutation';

@Injectable({providedIn: 'root'})
export class StudentService {
  private readonly _studentFilterEnabled = new BehaviorSubject<boolean>(false);
  private readonly _allEnabled$          = new BehaviorSubject<boolean>(false);
  private readonly _previousStudentId$   = new BehaviorSubject<string>(
    localStorage.getItem(STORAGE_KEY.previousStudentId)
  );
  private readonly _studentId$           = new BehaviorSubject<string>(this._getDefault());
  private readonly _students$            = new BehaviorSubject<StudentUser[]>([]);
  private _debounced                     = false;
  public _studentFilterEnabled$          = this._studentFilterEnabled.asObservable().pipe(distinctUntilChanged());

  public students$ = this._students$.asObservable().pipe(
    tap(students => {
      if (!students.length) {
        this.getAll();
      }
    }),
    map(students => students.map(student => {
      student.fullName = `${student?.firstName} ${student?.lastName}`;
      return student;
    })),
    filter(students => Boolean(students.length)),
    shareReplay(1),
  );

  private sortStudentByNameWithNumbers(a: StudentUser, b: StudentUser): number {
    const fullA = `${a.preferredName || a.firstName} ${a.lastName}`;
    const fullB = `${b.preferredName || b.firstName} ${b.lastName}`;
    const nameA = fullA.match(/([^0-9]+)/)[0].trim();
    const nameB = fullB.match(/([^0-9]+)/)[0].trim();
    const numberA = fullA.match(/[0-9]+/) ? parseInt(fullA.match(/[0-9]+/)[0], 10) : 0;
    const numberB = fullB.match(/[0-9]+/) ? parseInt(fullB.match(/[0-9]+/)[0], 10) : 0;

    if (nameA !== nameB) {
      return nameA.localeCompare(nameB);
    }

    return numberA - numberB;
  }

  public studentsFiltered$ = combineLatest([this.students$, this.alphaLevelService.level$])
    .pipe(map(
      ([students, level]) =>
        students.filter(student => level !== 'ALL'
          ? student.alphaLearningLevel === level && student.isActive
          : student.isActive).sort(this.sortStudentByNameWithNumbers),
    ),
  );

  public studentId$ = combineLatest([
    this._studentId$,
    this.studentsFiltered$,
    this.authService.isStudent$,
    this.authService.user$
  ]).pipe(
    debounce(_ => {
      if (this._debounced) {
        return timer(0)
      } else {
        this._debounced = true;
        return EMPTY
      }
    }),
    tap(([id, studentsFiltered, isStudent, user]) => {
      if(!this._allEnabled$.value) {
        const [defaultStudentId] = studentsFiltered.map(student => student.studentId).filter(i => i);
        if (!id || studentsFiltered.every(student => student.studentId !== id)) {
          this.setPreviousId(studentsFiltered[0].studentId);
          this.updateStudentId(isStudent ? user.attributes['custom:studentID'] : defaultStudentId);
        }
      }
    }),
    map(([id]) => id),
    filter(id => Boolean(id)),
    distinctUntilChanged(),
    shareReplay(1)
  );

  student$ = combineLatest([this.studentId$, this.students$]).pipe(
    map(([id, students]) => id !== 'ALL' ?
      students.find(value => value.studentId === id) : students.find(value => value.studentId ===
        this._previousStudentId$.value))
  );

  constructor(
    private readonly alphaLevelService: AlphaLevelService,
    private readonly appSyncHelper: AppSyncHelper,
    private readonly authService: AuthenticationService
  ) {
  }

  /** Enables option to select All Students */
  public enableAll(value: boolean): void {
    this._allEnabled$.next(value);
    if (value) {
      this.updateStudentId('ALL');
    } else {
      this._debounced = false;
      this.updateStudentId(localStorage.getItem(STORAGE_KEY.previousStudentId));
    }
  }

  public getAll(): void {
    this.appSyncHelper
      .query(LIST_STUDENT_USERS, null)
      .pipe(
        take(1),
        map((response: ListStudentUsersQuery) => ObjectMapper.deserializeArray(StudentUser, response.listStudentUsers)),
        map(students => [...students].sort((a, b) => (a.preferredName || a.firstName).localeCompare(b.preferredName || b.firstName)))
      )
      .subscribe(students => this.updateStudents(students));
  }

  public getStudentByAlphaStudentId(alphaId: string): Observable<Student> {
    return this.students$.pipe(
      filter(students => Boolean(students && students.length)),
      take(1),
      map(students => students.find(student => student.alphaStudentId === alphaId))
    );
  }

  setPreviousId(id: string): void {
    this._previousStudentId$.next(id);
    localStorage.setItem(STORAGE_KEY.previousStudentId, id);
  }

  public updateStudentId(value: string): void {
    this._studentId$.next(value);
    if (value !== 'ALL') {
      this.setPreviousId(value);
    }
    localStorage.setItem(STORAGE_KEY.studentId, value);
  }

  public updateStudents(values: StudentUser[]): void {
    this._students$.pipe(first()).subscribe(students => {
      const studentsToUpdate = students.map(student => {
        const studentToUpdate = values.find(value => value.studentId === student.studentId);
        return studentToUpdate ? { ...student, ...studentToUpdate } : student;
      });

      const studentsToAdd = values.filter(value => students.every(student => student.studentId !== value.studentId));

      this._students$.next(
        [...studentsToUpdate, ...studentsToAdd].sort((a, b) => (a.preferredName || a.firstName).localeCompare(b.preferredName || b.firstName)),
      );
    });
  }

  private _getDefault(): string {
    return this._allEnabled$.value ? 'ALL' : localStorage.getItem(STORAGE_KEY.studentId)
  }

  public setStudentFilter(value: boolean) {
    this._studentFilterEnabled.next(value);
  }

  public getStudent(studentId: string, includeInactiveEnrollments: boolean): Observable<GetStudentOutput> {
    return this.appSyncHelper
      .query<GetStudentQuery, GetStudentQueryVariables>(GET_STUDENT, { studentId, includeInactiveEnrollments })
      .pipe(  
        map((response: GetStudentQuery) => response.getStudent)
      );
  }

  public getStudentList(): Observable<StudentUser[]> {
    return this.appSyncHelper
      .query(LIST_STUDENT_USERS, '')
      .pipe(
        map((response: ListStudentUsersQuery) => ObjectMapper.deserializeArray(StudentUser, response.listStudentUsers))
      );
  }

  public enrollStudentInProgram(studentId: string, program: string, voucher?: string): Observable<ProgramEnrollment> {
    return this.appSyncHelper
      .mutate(ENROLL_STUDENT_IN_PROGRAM, { studentId, program, voucher })
      .pipe(
        map((response: EnrollStudentInProgramMutation) => ObjectMapper.deserialize(ProgramEnrollment, response.enrollStudentInProgram))
      );
  }

  public updateStudentMapGradeLevel(platformStudentId: string, mapGradeLevel: MapGradeLevel): Observable<void> {
    return this.appSyncHelper
      .mutate<UpdateStudentGradeMutation, UpdateStudentGradeMutationVariables>(
        UPDATE_STUDENT_MAP_GRADE_LEVEL, { input: { platformStudentId, gradeLevel: mapGradeLevel } }
      )
      .pipe(
        map(() => {}),
        tap(() => {
          const students = this._students$.value?.map((student: StudentUser) => {
            if (student?.studentId === platformStudentId) {
              student.mapGradeLevel = mapGradeLevel;
            }
            return student;
          });
          this._students$.next(students);
        })
      );
  }

  public updateStudentPlacementGradeForSubject(
    platformStudentId: string,
    subject: string,
    placementGrade: GradeLevel
  ): Observable<void> {
    return this.appSyncHelper
      .mutate<UpsertStudentPlacementGradeMutation, UpsertStudentPlacementGradeMutationVariables>(
        UPSERT_STUDENT_PLACEMENT_GRADE, { input: { platformStudentId, subject, placementGrade } }
      )
      .pipe(map(() => {}));
  }
}
