export const LIST_SCHOOLS = /* GraphQL */ `
  query ListSchools {
    listSchools {
      id
      name
      schoolKey
      schoolIcon
    }
  }
`;
