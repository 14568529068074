import { ViewportRuler } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-image-uploader',
  styleUrls: ['./image-uploader.component.scss'],
  templateUrl: './image-uploader.component.html',
})
export class ImageUploaderComponent {
  event: Event;
  image = new Image();
  imageCropped: string;
  imageOriginal: string;
  loading = true;
  maxHeight: string;
  maxWidth: string;
  reader = new FileReader();
  resizeToWidth: number;
  show = false;

  constructor(
    private readonly http: HttpClient,
    private readonly ref: MatDialogRef<ImageUploaderComponent>,
    private readonly ruler: ViewportRuler,
    @Inject(MAT_DIALOG_DATA) public data: { ratio: number; image: string; round: boolean; width: number },
  ) {
    this.image.onload = this.resizeCropper;
    this.reader.onloadend = this.convertImageToBase64AndLoadNewImage;

    this.http
      .get(this.data.image, { responseType: 'blob' })
      .pipe(first())
      .subscribe(response => this.reader.readAsDataURL(response));
  }

  convertImageToBase64AndLoadNewImage = () => {
    this.imageOriginal = this.reader.result as string;
    this.image.src = this.imageOriginal;
  };

  resizeCropper = () => {
    if (this.image && this.image.naturalWidth && this.image.naturalHeight) {
      const width = this.image.naturalWidth;
      const height = this.image.naturalHeight;
      const ratioOriginal = width / height;
      const viewport = this.ruler.getViewportSize();
      const heightPercent = 0.65;
      const maxHeight = Math.min(height, viewport.height * heightPercent);
      const maxWidth = maxHeight * ratioOriginal;
      this.maxHeight = `${maxHeight}px`;
      this.maxWidth = `${maxWidth}px`;
      this.resizeToWidth = this.data.width ? Math.min(width, this.data.width) : width;
    }

    this.loading = false;
  };

  save() {
    this.ref.close(this.imageCropped);
  }

  updateEventAndReadNewFile(event: Event) {
    this.loading = true;
    this.event = event;
    this.reader.readAsDataURL((event.target as HTMLInputElement).files[0]);
  }
}
