export const ENROLL_STUDENT_IN_PROGRAM = /* GraphQL */ `
  mutation EnrollStudentInProgram($program: String!, $studentId: String!, $voucher: String) {
    enrollStudentInProgram(program: $program, studentId: $studentId, voucher: $voucher) {
      endDate
      programId
      startDate
      studentId
    }
  }
`;
