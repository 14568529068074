import {Injectable} from '@angular/core';
import {Subject} from '@app/core/models/subject.model';
import {SUBJECT_LIST} from '@app/core/resolvers/queries/subject-list.query';
import {AppSyncHelper} from '@coach-bot/data-access/core';

import {ListSubjectsQuery} from 'app/API.service';

import {ObjectMapper} from 'json-object-mapper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class NonMapSubjectService {
  public constructor(private readonly _appSync: AppSyncHelper) {}

  public list(onlyMapSubjects: boolean): Observable<Subject[]> {
    return this._appSync
      .query<ListSubjectsQuery, { onlyMapSubjects: boolean }>(SUBJECT_LIST, {onlyMapSubjects})
      .pipe(map(res => ObjectMapper.deserializeArray(Subject, res.listSubjects)));
  }
}
