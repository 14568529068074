import {Injectable} from '@angular/core';
import {DEFAULT_FLAGS} from '@app/core/config/launch-darkly-default.config';
import {initialize, LDClient} from 'launchdarkly-js-client-sdk';
import {BehaviorSubject, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment.develop';

@Injectable({providedIn: 'root'})
export class LaunchDarklyState {
  private _client: LDClient;
  private readonly _flags    = new BehaviorSubject<{ [key: string]: unknown }>(DEFAULT_FLAGS);
  public readonly flags$     = this._flags.asObservable();
  private readonly _isReady  = new BehaviorSubject<boolean>(false);
  private readonly _isReady$ = this._isReady.asObservable();
  private readonly _subscription: Subscription;

  constructor() {
    this._subscription = this._isReady$.subscribe(val => {
      if (val) {
        const allFlags = this._client.allFlags();
        for (const flag in DEFAULT_FLAGS) {
          if (allFlags[flag] === null || allFlags[flag] === undefined) {
            allFlags[flag] = DEFAULT_FLAGS[flag];
          }
        }
        this._flags.next(allFlags);
      }
    });
  }

  public init(userId: string, schoolName: string) {
    this._isReady.next(false);

    this._closeClient()
      .then(() => {
        this._client = initialize(
          environment.launchDarklyKey,
          {key: userId, custom: {schoolKey: String(schoolName).replace(' ', '-').toLowerCase()}}
        );

        this._client.waitForInitialization()
          .then(() => {
            this._isReady.next(true);
          })
          .catch(this._onError.bind(this));
      })
      .catch(this._onError.bind(this));
  }

  public ngOnDestroy(): void {
    this._closeClient().then();
    this._subscription.unsubscribe();
  }

  private async _closeClient() {
    this._client?.close();
    this._client = null;
  }

  private _onError() {
    this._isReady.next(false);
    this._flags.next(DEFAULT_FLAGS);
  }
}
