import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MathSkillsGuard } from './guards/math-skills.guard';
import { RestrictedStudentGuard } from './guards/restricted-student.guard';
import { AdminOnlyGuard } from './guards/admin-only-guard';

import { AuthenticationService } from './services/authentication.service';

@NgModule({
  imports: [CommonModule],
  providers: [AuthenticationService, RestrictedStudentGuard, AdminOnlyGuard, MathSkillsGuard],
})
export class CoachBotDataAccessAuthModule {}
