import {Injectable} from '@angular/core';
import {Permission} from '@app/core/models/permission.model';
import {PERMISSION} from '@app/core/resolvers/queries/permission.query';

import {AppSyncHelper} from '@coach-bot/data-access/core';
import {ListPermissionsQuery} from 'app/API.service';

import {ObjectMapper} from 'json-object-mapper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PermissionService {
  public constructor(private readonly _appSync: AppSyncHelper) {}

  public list(): Observable<Permission[]> {
    return this._appSync
      .query<ListPermissionsQuery, string>(PERMISSION, null)
      .pipe(map(response => ObjectMapper.deserializeArray(Permission, response.listPermissions)));
  }
}
