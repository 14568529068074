<kendo-card width="408px">
  <kendo-card-header>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div kendoCardTitle class="k-font-weight-bold">Notifications</div>
      <button kendoButton fillMode="flat" rounded="full" icon="close" (click)="onClose($event)"></button>
    </div>
  </kendo-card-header>

  <kendo-card-body class="notifications-card__content content k-p-0">
    <ng-container *ngIf="hasNotifications else noSelectedStudent">
      <ng-container *ngIf="notifications?.length else noData" >
        <ng-container *ngFor="let item of notifications; let last = last;">
          <notification-item
            [dataCreated]="item.createdOn"
            [type]="item.notificationType"
            [image]="item.image"
            [title]="item.title"
            [isRead]="item.isRead"
            [message]="item.message"></notification-item>
          <hr *ngIf="!last" kendoCardSeparator />
        </ng-container>
      </ng-container>
    </ng-container>
  </kendo-card-body>
</kendo-card>

<ng-template #noData>
    <app-empty class="no-data">
      <h1 class="title">No Notifications</h1>

      <p class="description">
        There are no notifications
        <span *ngIf="!isStudent">for {{ student.firstName }} {{student.lastName}}</span>
      </p>
    </app-empty>
</ng-template>

<ng-template #noSelectedStudent>
  <app-empty class="no-data">
    <h1 class="title">No Notifications</h1>

    <p class="description">
      No student selected.
    </p>
  </app-empty>
</ng-template>
