import { Injectable } from '@angular/core';
import { SubHeaderState } from '@app/core/states/sub-header.state';

@Injectable({ providedIn: 'root' })
export class SubHeaderFacade {
  public filters$ = this._state.filters$;
  public setFilters = this._state.setFilters.bind(this._state);
  public setHeader = this._state.setSubheader.bind(this._state);
  public setShowAllSubjects = this._state.setShowAllSubjects.bind(this._state);
  public setTitle = this._state.setTitle.bind(this._state);
  public setIcon = this._state.setIcon.bind(this._state);
  public setWidget = this._state.setWidget.bind(this._state);
  public removeWidget = this._state.removeWidget.bind(this._state);
  public showAllSubjects$ = this._state.showAllSubjects$;
  public showHeader$ = this._state.showHeader$;
  public title$ = this._state.title$;
  public icon$ = this._state.icon$;
  public widget$ = this._state.widget$;

  constructor(private readonly _state: SubHeaderState) {}
}
