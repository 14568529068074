import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class ErrorService {
  public checkOnline(): string {
    return !navigator.onLine ? 'No Internet Connection' : '';
  }

  public getClientMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  public getClientStack(error: Error): string {
    return error.stack;
  }

  public getServerMessage(error: HttpErrorResponse): string {
    const msg = error.error.Message;

    return !!msg
      ? `${msg} : ${error.error.ExceptionMessage}`
      : `Application can not execute because API hasn't been started`;
  }

  public getStaticMessage(): string {
    return `We've encountered an unexpected error with the application.
     Try refreshing the page. If that doesn't solve the issue, please contact your CoachBot administrator.`;
  }

  public getServerStack(error: HttpErrorResponse): string {
    return error.error.StackTrace;
  }
}
