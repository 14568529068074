import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import StudentFacade from 'app/core/facades/student.facade';

@Component({
  selector: 'cb-top-bar',
  templateUrl: './coach-bot-top-bar.component.html',
  styleUrls: ['./coach-bot-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoachBotTopBarComponent {
  @Input() public background?: string;
  @Input() public image?: string;
  @Input() public isAdmin = true;
  @Input() public isGuide = false;
  @Input() public isGuideOrAdmin = false;
  @Input() public isViewSkillsStackAccess = false;
  @Input() public isStudent = false;
  @Output() public logout = new EventEmitter();
  @Output() public uploadPhoto = new EventEmitter<string>();

  constructor(public studentFacade: StudentFacade) {}
}
