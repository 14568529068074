import {DashboardActivityDataPoint} from '@app/core/models/dashboard-activity-data-point.model';
import {DashboardLearningApp} from '@app/core/models/dashboard-learning-app.model';
import {DashboardProductivityDataPoint} from '@app/core/models/dashboard-productivity-data-point.model';
import {DashboardTimeDataPoint} from '@app/core/models/dashboard-time-data-point.model';
const MOMENT_ISO_DATE = 'Y-MM-DD';

import * as moment from 'moment-timezone';

/** To be used to generate the passed activity data. */
export function processPassedActivity(points: DashboardActivityDataPoint[]) {
  const dates = [...new Set(points.map(point => point.date))];

  const apps = [
    ...new Set(
      points
        .filter(point => point.activityUnitsCorrect !== null && point.learningAppName)
        .map(point => point.learningAppName),
    ),
  ];

  return dates.map(date => ({
    name: date,
    series: apps.map(app => {
      const point = points.find(item => item.date === date && item.learningAppName === app);
      const value = point ? point.activityUnitsCorrect || 0 : 0;
      const raw = point && point.activityUnitsAttempted !== null ? point : null;
      return { raw, value, name: app, original: value };
    }),
  }));
}

/** To be used to generate the productivity data. */
export function processProductivity(
  activity: DashboardActivityDataPoint[],
  productivity: DashboardProductivityDataPoint[],
) {
  if (productivity.every(value => value.totalRelativeProductivity === null)) {
    return [];
  }

  const max = 500;
  const percent = 100;

  return productivity.map(value => {
    let raw = null;
    const original = Math.floor(value.totalRelativeProductivity * percent);

    if (value.totalRelativeProductivity !== null) {
      const learningApps = value.learningApps.map(learningApp => {
        const [mostUsedApp] = activity
          .filter(item => {
            const saturday = moment(value.date);
            const fridayNext = moment(value.date).add(1, 'week').subtract(1, 'day').endOf('day');
            const current = moment(item.date);

            return (
              current.isSameOrAfter(saturday) &&
              current.isSameOrBefore(fridayNext) &&
              item.learningAppName === learningApp.learningAppName
            );
          })
          .sort((a, b) => b.activityUnitsAttempted - a.activityUnitsAttempted);

        const name = mostUsedApp && mostUsedApp.mostProgressCourseName ? mostUsedApp.mostProgressCourseName : null;
        return { ...learningApp, mostProgressCourseName: name };
      });

      raw = { ...value, learningApps };
    }

    return {
      name: value.date,
      series: [{ original, raw, name: 'Productivity', value: Math.min(max, original) }],
    };
  });
}

/** To be used to generate the progress data. */
export function processProgress(
  activity: DashboardActivityDataPoint[],
  productivity: DashboardProductivityDataPoint[],
) {
  if (activity.every(value => value.topicLevelsPassed === null)) {
    return [];
  }

  const saturdays = [
    ...new Set(activity.map(value => moment(value.date).startOf('week').subtract(1, 'day').format(MOMENT_ISO_DATE))),
  ];

  const uniqueApps = [
    ...new Set(
      activity
        .filter(value => value.learningAppName && value.topicLevelsPassed !== null)
        .map(value => value.learningAppName),
    ),
  ];

  const learningApps: DashboardLearningApp[] = productivity
    .filter(value => value.learningApps)
    .reduce((result, current) => [...result, ...current.learningApps], []);

  return saturdays.map(saturday => {
    const start = moment(saturday);
    const end = moment(saturday).add(1, 'week').endOf('day').subtract(1, 'day');

    const dataWeek = activity.filter(value => {
      const today = moment(value.date);
      return today.isSameOrAfter(start) && today.isSameOrBefore(end);
    });

    const series = uniqueApps.map(app => {
      const dataApp = dataWeek.filter(value => value.learningAppName === app);
      const dataValid = dataApp.some(value => value.topicLevelsPassed !== null);

      if (dataValid) {
        const learningApp = learningApps.find(item => item.learningAppName === app);

        const raw = dataApp.reduce(
          (result, current) => {
            return {
              ...learningApp,
              ...current,
              activityUnitsAttempted: result.activityUnitsAttempted + current.activityUnitsAttempted,
              topicLevelsPassed: result.topicLevelsPassed + current.topicLevelsPassed,
            };
          },
          { activityUnitsAttempted: 0, topicLevelsPassed: 0 },
        );

        const value = raw.topicLevelsPassed;
        return { raw, value, name: app, original: value };
      }

      return { name: app, original: 0, raw: null, value: 0 };
    });

    return { series, name: saturday };
  });
}

/** To be used to generate the time data. */
export function processTimeData(points: DashboardTimeDataPoint[]) {
  const days = [...new Set(points.map(value => value.date))];

  return days.map(day => {
    const currentDayPoints = points.filter(point => point.date === day);
    const totalHome = Math.floor(currentDayPoints.reduce((prev, curr) => prev + curr.minutesAtHome, 0));
    const totalSchool = Math.floor(currentDayPoints.reduce((prev, curr) => prev + curr.minutesAtSchool, 0));
    const validHome = points.some(point => point.minutesAtHome !== null);
    const validSchool = points.some(point => point.minutesAtSchool !== null);
    const valid = validHome || validSchool;
    const home = validHome ? totalHome : 0;
    const school = validSchool ? totalSchool : 0;

    const raw = valid
      ? currentDayPoints.map(item => ({
          ...item,
          total: Math.floor(item.minutesAtHome + item.minutesAtSchool),
        }))
      : null;

    return {
      name: day,
      series: [
        { raw, name: 'Core Skills', value: school, original: school },
        { raw, name: 'Student-Driven', value: home, original: home },
      ].sort((a, b) => a.name.localeCompare(b.name)),
    };
  });
}

/** To be used to generate the total activity data. */
export function processTotalActivity(points: DashboardActivityDataPoint[]) {
  const dates = [...new Set(points.map(point => point.date))];

  return dates.map(date => {
    const pointsAtDate = points.filter(value => value.date === date);
    const correctUnits = pointsAtDate.reduce((result, current) => result + current.activityUnitsCorrect, 0);

    const incorrectUnits = pointsAtDate.reduce(
      (result, current) => result + (current.activityUnitsAttempted - current.activityUnitsCorrect)
    , 0);

    const valid = pointsAtDate.some(value => value.activityUnitsAttempted !== null);
    const raw = valid ? pointsAtDate : null;

    return {
      name: date,
      series: [
        { raw, name: 'correct', value: correctUnits, original: correctUnits },
        { raw, name: 'incorrect', value: incorrectUnits, original: incorrectUnits },
      ],
    };
  });
}
