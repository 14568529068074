export const STUDENT_DASHBOARD_DETAILS = /* GraphQL */ `
  query GetStudentDashboardDetails(
    $studentId: String!
    $periodStartDate: String!
    $periodEndDate: String!
  ) {
    getStudentDashboardDetails(
      studentId: $studentId
      periodStartDate: $periodStartDate
      periodEndDate: $periodEndDate
    ) {
      firstName
      lastName
      alphaLearningLevel
      mapGradeLevel
      alphaStudentId
      isActive
      subjects {
        savedMAPPercentile
        goalMAPPercentile
        subjectName
        subjectId
        lastYearMAPPercentile
        minutesAtHome
        minutesAtSchool
        goalMinutesPerDay
        goalProductiveTime
        estimatedGoalDate
        isMAPSubject
        minutesPerDayDataPoints {
          date
          minutesAtHome
          minutesAtSchool
          learningAppName
          mostTimeWorkedCourseName
          mostTimeWorkedTopicName
        }
        productivityDataPoints {
          date
          totalMinutesSpent
          totalRelativeProductivity
          learningApps {
            learningAppName
            levelsCompleted
            minutesSpent
            averageProductivity
            alphaAverageProductivity
            relativeProductivity
            learningUnit
            learningUnitPassed
            learningUnitPassedDescription
          }
        }
        appActivityDataPoints {
          date
          learningAppName
          activityUnitLabel
          activityUnitsCorrect
          activityUnitsCorrectLabel
          activityUnitsAttempted
          activityProductivityMean
          topicLevelsPassed
          mostActivityCourseName
          mostActivityTopicName
          mostProgressCourseName
          mostProgressTopicName
          learningUnit
          learningUnitPassed
        }
      }
    }
  }
`;
