import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-accepted-terms-dialog',
  styleUrls: ['./accepted-terms-dialog.component.scss'],
  templateUrl: './accepted-terms-dialog.component.html',
})
export class AcceptedTermsDialogComponent {
  public checked = false;
  public privacyPolicyLinkCoachBot = 'https://gt.school/privacy-policy/';
  public termsOfServiceLink = 'https://gt.school/terms-of-service/';
  public isBlocksHostname$: Observable<boolean>;
  constructor(public dialogRef: MatDialogRef<AcceptedTermsDialogComponent>) {}

  public checkBoxChange(event: MatCheckboxChange) {
    this.checked = event.checked;
  }

  public next() {
    this.dialogRef.close(this.checked);
  }
}
