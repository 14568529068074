import {JsonProperty} from 'json-object-mapper';

export class DashboardMapDataPoint {
  @JsonProperty() public date: string;
  @JsonProperty() public percentile: number;
  @JsonProperty() public ritScore: number;
  @JsonProperty() public termName: string;
  @JsonProperty() public termSeason: string;

  public constructor() {
    this.date       = null;
    this.percentile = null;
    this.ritScore   = null;
    this.termName   = null;
    this.termSeason = null;
  }
}
