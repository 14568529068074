/** Default static image for user avatar use across application */
export const AVATAR = '../../../../assets/img/profile/avatar.svg';
/** Default static image for user cover use across application */
export const COVER = '../../../../assets/img/dashboard/cover.jpg';
export const appTimeIcon = '/assets/img/recommends/time.svg';
/** Default static text for title*/
export const APP_TIME_TITLE = 'Your Daily App Time should be';
/** Default static text for label*/
export const APP_TIME_LABEL = 'minutes/day';
export const appActivityName = 'Your Daily App Activity should be';
export const numberOfWeeks = 12;
export const appActivityIcon = '/assets/img/recommends/activity.svg';
export const appWeeklyProgressName = 'Your Weekly Progress should be';
export const appWeeklyProgressLabel = 'levels/week';
export const appWeeklyProgressIcon = '/assets/img/recommends/progress.svg';
export const imageExtension = 'webp';
export const defaultGoalMAPPercentile = 99;
export const minutesAtSchool = 25;
export const ALL = 'ALL';
export const ALL_LEVELS = 'All Levels';
export const ALL_YEARS = 'All Years at Alpha';
export const ALL_SCORE_COMPONENTS = 'All Score Components';
export const ALL_CAMPUSES = 'All Campuses';
export const ALL_STUDENTS = 'All Students';
export const ALL_MAP_PERCENT = 'All MAP%';
export const ALL_SUBJECTS = 'All Subjects';
export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
/** Default max value to use for chart in quadrant report */
export const MAX_LEARNING_RATE = 5;
/** Default min value to use for chart in quadrant report */
export const MIN_LEARNING_RATE = -2;

/** default title to show in analytics page when no matching criteria */
export const NO_DATA_TITLE = "We currently don't have any results matching this criteria";

/** default text to show in analytics page when no matching criteria */
export const NO_DATA_TEXT = 'Please change the chosen filters to search for another selection';
