import { JsonProperty } from 'json-object-mapper';

/**
 * A simple mapper for Skill Recommendation API response.
 *
 * @author Milos Sretin
 * @since 1.0.0
 */
export class SkillRecommendation {
  @JsonProperty({ name: 'standardExternalId' }) public externalId: string;

  constructor() {
    this.externalId = null;
  }
}
