import { JsonProperty } from 'json-object-mapper';

export class ProgramEnrollment {
  @JsonProperty() public studentId: string;
  @JsonProperty() public programId: string;
  @JsonProperty() public startDate: string;
  @JsonProperty() public endDate?: string;

  public constructor() {
    this.studentId = null;
    this.programId = null;
    this.startDate = null;
    this.endDate = null;
  }
}
