import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {imageExtension} from '@app/core/config/defaults.config';

import Amplify from '@aws-amplify/core';
import {Storage} from '@aws-amplify/storage';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {v1 as uuid} from 'uuid';

import {environment} from '../../../environments/environment.develop';

@Injectable()
export class StorageHelper {
  public constructor(private readonly http: HttpClient) {
    Amplify.configure({
      Storage: { AWSS3: { bucket: environment.aws_s3_students_assets_bucket, region: environment.aws_appsync_region } },
    });
  }

  /**
   * Upload image to S3 bucket.
   * @param image either in base64 or in URL form.
   */
  public upload(image: string): Observable<string> {
    return this.http.get(image, { responseType: 'blob' }).pipe(
      switchMap(file => {
        const name = `${uuid()}.${imageExtension}`;
        return from(
          Storage.put(name, file, { contentType: `image/${imageExtension}` }).then(
            _ => `${environment.aws_s3_students_assets_url}/${name}`,
          ),
        );
      }),
    );
  }
}
