import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Standard } from '@coach-bot/data-access/math-skills-stack';
import { LearningApp } from '@app/modules/learning-apps/models/learning-app.model';

@Component({
  selector: 'cb-skill-popup',
  templateUrl: './coach-bot-skill-popup.component.html',
  styleUrls: ['./coach-bot-skill-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoachBotSkillPopupComponent {
  @Input() public noDialog = true;
  @Input() public standardCol: Standard;
  @Input() public learningApps: LearningApp[];
  @Input() public selfAssessment = true;
  @Input() public mathStack = true;
  @Input() public updating = false;
  @Output() public showDialog = new EventEmitter();
  @Output() public toggleStandard = new EventEmitter<boolean>();

  public getAppImage(name: string): string {
    return this.learningApps?.find(app => app?.learningAppName.includes(name))?.learningAppImage;
  }

  public getButtonText(): string {
    return this.standardCol.isSelfAssessed ? 'Unmark as Learned' : 'Mark as Learned';
  }

  public getFillMode(): string {
    return this.standardCol.isSelfAssessed ? 'solid' : 'outline';
  }

  public toggleLearned(): void {
    this.toggleStandard.emit(!this.standardCol.isSelfAssessed);
  }
}
