import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { CoachBotDataAccessAuthModule } from '@coach-bot/data-access/auth';
import { ENVIRONMENT } from '@coach-bot/data-access/core';
import { LeaderboardFacade } from '@coach-bot/feature/leaderboards';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MenusModule } from '@progress/kendo-angular-menu';
import { NotificationService } from '@progress/kendo-angular-notification';

import { FeedbackModule } from 'app/shared/components/feedback/feedback.module';
import { environment } from '../environments/environment.develop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    CoachBotDataAccessAuthModule,
    AppRoutingModule,
    CoreModule,
    FeedbackModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    MenusModule,
  ],
  providers: [
    NotificationService,
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    LeaderboardFacade,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {}
