import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationStart } from '@angular/router';
import { CoachBotPagesConfiguration} from 'app/shared/configuration/CoachBotPagesConfiguration';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-feedback></app-feedback>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  constructor(private readonly router: Router, private readonly title: Title) {}

  ngOnInit(): void {
    this.subscriptions.add(this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.updatePageTitle(event.url);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private updatePageTitle(url: string){
    const customTitle = CoachBotPagesConfiguration.CustomPageTitles[url];
    const titleToSet = customTitle ?? CoachBotPagesConfiguration.DefaultTitle;
    this.title.setTitle(titleToSet);
  }
}
