import {Injectable} from '@angular/core';

import {MaintenanceModel} from '@app/core/models/maintenance.model';

import {AppSyncHelper} from '@coach-bot/data-access/core';
import {GetMaintenanceStatusQuery} from 'app/API.service';

import {getMaintenanceStatus} from 'graphql/queries';

import {ObjectMapper} from 'json-object-mapper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class MaintenanceService {
  public constructor(private readonly _appSync: AppSyncHelper) {}

  public getMaintenanceStatus(): Observable<MaintenanceModel> {
    return this._appSync.queryViaApiKeyAuth<GetMaintenanceStatusQuery, {}>(getMaintenanceStatus, {})
      .pipe(map(response => ObjectMapper.deserialize(MaintenanceModel, response.getMaintenanceStatus)));
  }

  public getMaintenanceStatusByUserId(userId: string): Observable<MaintenanceModel> {
    return this._appSync.query<GetMaintenanceStatusQuery, { userId: string }>(getMaintenanceStatus, {userId})
      .pipe(map(response => ObjectMapper.deserialize(MaintenanceModel, response.getMaintenanceStatus)));
  }
}
