import {Component, Input} from '@angular/core';
import {QuicksightDashboardParametersModel, QuicksightEmbeddedDashboardModel} from '@coach-bot/data-access/core';
import {ObjectMapper} from 'json-object-mapper';
//@ts-ignore
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

@Component({
  selector: 'cb-quick-sight-dashboard',
  templateUrl: './quick-sight-dashboard.component.html',
  styleUrls: ['./quick-sight-dashboard.component.scss'],
})
export class QuickSightDashboardComponent  {
  public dashboardLoaded = false;
  private _studentId: string;
  private _leaderBoardId: string;
  private _embeddedBoardData: QuicksightEmbeddedDashboardModel;

  @Input()
  public get embeddedBoardData(): QuicksightEmbeddedDashboardModel {
    return this._embeddedBoardData;
  }

  public set embeddedBoardData(value: QuicksightEmbeddedDashboardModel) {
    if(value) {
      this.setDashboard(value);
    }
    this._embeddedBoardData = value;
  }

  @Input()
  public get studentId(): string {
    return this._studentId;
  }

  public set studentId(value: string) {
    if (value && value !== this._studentId) {
      this.resetContainer();
      this.dashboardLoaded = false;
    }

    this._studentId = value;
  }

  @Input()
  public get leaderboardId(): string {
    return this._leaderBoardId;
  }

  public set leaderboardId(value: string) {
    if (value && value !== this._leaderBoardId) {
      this.resetContainer();
      this.dashboardLoaded = false;
    }

    this._leaderBoardId = value;
  }

  private resetContainer() {
    const containerDiv = document.getElementById('dashboardContainer');
    if (containerDiv) {
      containerDiv.querySelector('iframe')?.remove();
    }
    return containerDiv;
  }

  private onDashboardLoad(): void {
    this.dashboardLoaded = true;
  }

  private setDashboard(embeddedBoardData: QuicksightEmbeddedDashboardModel): void {
    const containerDiv = this.resetContainer();
    const dynamicOptions = ObjectMapper.deserialize(
      QuicksightDashboardParametersModel,
      embeddedBoardData.parameters
    );

    if (containerDiv) {
      const options = {
        url: embeddedBoardData.embedUrl || embeddedBoardData.url,
        container: containerDiv,
        scrolling: 'yes',
        height: '100%',
        width: '100%',
        parameters: {...dynamicOptions}
      };

      const dashboard = QuickSightEmbedding.embedDashboard(options);
      dashboard.on('load', this.onDashboardLoad.bind(this));
    }
  }
}
