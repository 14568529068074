import { DashboardSubject } from '@app/core/models/dashboard-subject.model';
import { JsonProperty } from 'json-object-mapper';
import { MapGradeLevel } from './enumerations/map-grade-level.enum';

export class StudentDashboardDetails {
  @JsonProperty() public alphaLearningLevel: string;
  @JsonProperty() public alphaStudentId: string;
  @JsonProperty() public firstName: string;
  @JsonProperty() public isActive: boolean;
  @JsonProperty() public lastName: string;
  @JsonProperty() public mapGradeLevel: MapGradeLevel;

  @JsonProperty({name: 'subjects', type: DashboardSubject}) public subjects: DashboardSubject[];

  public constructor() {
    this.alphaLearningLevel = '';
    this.alphaStudentId     = '';
    this.firstName          = '';
    this.isActive           = false;
    this.lastName           = '';
    this.mapGradeLevel      = '' as MapGradeLevel;
    this.subjects           = [];
  }
}
