import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import {combineLatest} from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class MathSkillsGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private readonly _router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot) {
    return combineLatest([this.authService.isStudent$, this.authService.isViewSkillsStackAccess$]).pipe(
      tap(([isStudent, isViewSkillsStackAccess]) => {
        if (isStudent && !isViewSkillsStackAccess) {
          this._router.navigate(['not-found'], {});
        }
      }),
      map(([isStudent, isViewSkillsStackAccess]) => isStudent ? isViewSkillsStackAccess : true)
    );
  }
}
