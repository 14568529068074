import { Component } from '@angular/core';

import { LoginFacade } from 'app/modules/auth/facades/login-facade';

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  constructor(public readonly facade: LoginFacade) {}
}
