import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

import {addMonths, differenceInSeconds, isBefore, isSameDay, startOfDay, subDays, subYears} from 'date-fns';
import {CustomStepDefinition, Options} from 'ng5-slider';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay} from 'rxjs/operators';

import {STORAGE_KEY} from '@app/core/config/app.config';
import {Range} from '@app/core/models/range.model';
import { DateUtility, PIPE_SHORT_MONTH_YEAR } from '@alpha-coach-bot/shared-util';

@Injectable({providedIn: 'root'})
export class RangeSliderService {
  public range = new BehaviorSubject<Range>(null);

  public range$ = this.range.asObservable().pipe(
    filter(range => Boolean(range)),
    distinctUntilChanged()
  );

  public label$ = this.range$.pipe(
    filter(range => Boolean(range && range.start && range.end)),
    map(range => {
      const format = 'mediumDate';

      if(range.start === range.end) {
        return this._datePipe.transform(range.start, format);
      } else {
        const start = this._datePipe.transform(range.start, format);
        const end   = this._datePipe.transform(subDays(range.end, 1), format);
        return `${start} - ${end}`;
      }
    }),
    shareReplay(1)
  );

  private readonly _labels = new BehaviorSubject<string[]>(null);

  public labels$ = this._labels.asObservable().pipe(
    filter(labels => Boolean(labels && labels.length)),
    distinctUntilChanged()
  );

  private readonly _options = new BehaviorSubject<Options>(null);

  public options$ = this._options.asObservable().pipe(
    filter(options => Boolean(options)),
    distinctUntilChanged()
  );

  public constructor(private readonly _datePipe: DatePipe) {
    const end      = DateUtility.nextSaturday(startOfDay(new Date()), true);
    const twoYears = 2;
    const start    = DateUtility.prevSaturday(subYears(end, twoYears));
    const labels   = this.generateLabels(end, start);
    this.updateLabels(labels);
    const stepsArray = this.generateSteps(end, start);

    // Initialize the range slider component.
    this.updateOptions({stepsArray, animate: false, hideLimitLabels: true, hidePointerLabels: true});

    // Check the validity of any range slider state stored in the local storage.
    const stored     = JSON.parse(localStorage.getItem(STORAGE_KEY.range));
    const rangeStale = this.isRangeStale();

    if(rangeStale || !stored) {
      this.expireRange();
    } else {
      this.updateRange({start: stored.start, end: stored.end}, false);
    }
  }

  private get defaultRange(): Range {
    const end   = DateUtility.nextSaturday(startOfDay(new Date()));
    const start = DateUtility.prevSaturday(DateUtility.prevSaturday(end));
    return {start: start.getTime(), end: end.getTime()};
  }

  public expireRange(): void {
    localStorage.setItem(STORAGE_KEY.rangeExpired, JSON.stringify(new Date().getTime()));
    this.updateRange(this.defaultRange);
  }

  public isRangeStale(): boolean {
    const twelveHours = 43200;
    const lastChange  = JSON.parse(localStorage.getItem(STORAGE_KEY.rangeChanged));
    const lastExpiry  = JSON.parse(localStorage.getItem(STORAGE_KEY.rangeExpired));
    const staleChange = !lastChange || (lastChange && differenceInSeconds(new Date(), lastChange) >= twelveHours);
    const staleExpiry = !lastExpiry || (lastExpiry && differenceInSeconds(new Date(), lastExpiry) >= twelveHours);
    return staleChange && staleExpiry;
  }

  public updateLabels(values: string[]): void {
    this._labels.next([...values]);
  }

  public updateOptions(options: Options): void {
    this._options.next({...options});
  }

  public updateRange(value: Range, saveUpdateTimestamp = true): void {
    const steps = this._options.value.stepsArray;
    const ceil = steps[steps.length - 1].value;
    const end  = Math.min(value.end, ceil);
    this.range.next({...value, end});
    localStorage.setItem(STORAGE_KEY.range, JSON.stringify(value));

    if(saveUpdateTimestamp) {
      localStorage.setItem(STORAGE_KEY.rangeChanged, JSON.stringify(new Date().getTime()));
    }
  }

  /**
   * Labels that will be shown below the range slider.
   * They will show evey two months with the format Jun 2021.
   * @param {Date} end date of the slider.
   * @param {Date} start date of the slider.
   * @returns {string[]} an array of labels.
   * @private
   */
  private generateLabels(end: Date, start: Date): string[] {
    const everySecondLabel = 2;
    const labels: string[] = [];
    let pointer            = new Date(start);

    while(isSameDay(pointer, end) || isBefore(pointer, end)) {
      labels.push(this._datePipe.transform(pointer.valueOf(), PIPE_SHORT_MONTH_YEAR));
      pointer = addMonths(pointer, everySecondLabel);
    }

    return labels;
  }

  /**
   * Steps to be used by the slider.
   * They should always be Saturdays.
   * @param {Date} end
   * @param {Date} start
   * @returns {CustomStepDefinition[]}
   * @private
   */
  private generateSteps(end: Date, start: Date): CustomStepDefinition[] {
    const steps: CustomStepDefinition[] = [];
    let pointer                         = new Date(start);

    while(isSameDay(pointer, end) || isBefore(pointer, end)) {
      steps.push({value: pointer.getTime()});
      pointer = DateUtility.nextSaturday(pointer);
    }

    return steps;
  }
}
