import {LayoutModule} from '@angular/cdk/layout';
import {OverlayModule} from '@angular/cdk/overlay';
import {DatePipe, DecimalPipe, LowerCasePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {GlobalErrorHandler} from '@app/core/handlers/global-error.handler';
import {ErrorService} from '@app/core/services/error.service';
import {ListSchoolsService} from '@app/core/services/list-schools/list-schools.service';
import {MaintenanceService} from '@app/core/services/maintenance/maintenance.service';
import {NonIntegratedAppService} from '@app/core/services/non-integrated-app/non-integrated-app.service';
import {StudentGradeLevelService} from '@app/core/services/student-grade-level/student-grade-level.service';
import {SubjectService} from '@app/core/services/subject/subject.service';
import {TooltipService} from '@app/core/services/tooltip.service';
import {YearsAtAlphaService} from '@app/core/services/years-at-alpha/years-at-alpha.service';
import {AcceptedTermService} from '@app/modules/auth/services/accepted-term.service';
import {LoggedInGuard} from '@app/shared/guards/logged-in-guard.service';

import {AppSyncHelper} from '@coach-bot/data-access/core';

import {NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {StorageHelper} from './aws/storage.helper';
import {NoCacheHeadersInterceptor} from './interceptors/no-cache-headers.interceptor';
import {AlphaLevelService} from './services/alpha-level/alpha-level.service';
import {DashboardService} from './services/dashboard/dashboard.service';
import {EnabledAppService} from './services/enabled-app/enabled-app.service';
import {PermissionService} from './services/permission/permission.service';
import {StudentHouseAssignmentService} from './services/student-house-assignment/student-house-assignment.service';
import {NonMapSubjectService} from './services/subject/non-map-subject.service';
import {VersionService} from './services/version/version.service';
import {WindowRefService} from './services/window.service';

/**
 * The Core module is used to hold all root-level providers. It should only be imported in the AppModule.
 */
@NgModule({
  /** Place all forRoot() imports here */
  imports: [BrowserAnimationsModule, BrowserModule, FormsModule, HttpClientModule, LayoutModule, NgbDropdownModule,
    NgbTooltipModule, OverlayModule, ReactiveFormsModule],
  /** Place all services/providers/injection tokens here here */
  providers: [/** Provide your app wide services here */

    // Services.
    AcceptedTermService, AlphaLevelService, DashboardService, EnabledAppService, ErrorService,
    MaintenanceService, NonIntegratedAppService, NonMapSubjectService, PermissionService, StudentGradeLevelService,
    StudentHouseAssignmentService, SubjectService, TooltipService, VersionService, WindowRefService,
    YearsAtAlphaService, ListSchoolsService,

    // AWS Helpers.
    AppSyncHelper, StorageHelper,

    // Handlers.
    {provide: ErrorHandler, useClass: GlobalErrorHandler},

    // Pipes.
    DatePipe, DecimalPipe, LowerCasePipe,

    // Guards.
    LoggedInGuard, {
      provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true
    }]
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
