const ONE = 1;
const TWO = 2;
const THREE = 3;
const TEN = 10;
const ELEVEN = 11;
const TWELVE = 12;
const THIRTEEN = 13;
const HUNDRED = 100;

export class StringUtility {
  static ordinalSuffixOf(number: number): string {
    const j = number % TEN;
    const k = number % HUNDRED;

    if (j === ONE && k !== ELEVEN) {
      return `${number}st`;
    }

    if (j === TWO && k !== TWELVE) {
      return `${number}nd`;
    }

    if (j === THREE && k !== THIRTEEN) {
      return `${number}rd`;
    }

    return `${number}th`;
  }
}
