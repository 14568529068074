import {Injectable} from '@angular/core';

import {NotificationsState} from '@app/modules/notifications/states/notifications.state';

@Injectable({
  providedIn: 'root'
})
export class NotificationsFacade {
  /** Observable emitted to verify if data is loaded or not. */
  public loadingCompleted$ = this._state.loadingCompleted$;

  /** Observable emitted for goal tracker session data. */
  public notifications$ = this._state.notifications$;

  /** Observable emitted for isRetrying */
  public hasError$ = this._state.hasError$;

  /** To be used to identify if the student is selected or not to show the notifications for that specific student.*/
  public isStudentSelected$ = this._state.isStudentSelected$;

  /** Number of new notifications */
  public notificationsCount = this._state.notificationsCount$;

  /** Whether or not the notification popup is visible. */
  public isVisible$ = this._state.isVisible$;

  /** To be used to crete a new notification. */
  public createNotification$ = this._state.createNotification.bind(this._state);

  /** To be used to update the notification. */
  public updateNotification$ = this._state.updateNotification.bind(this._state);

  /** To be used to set the notification popup visibility status.*/
  public showNotifications = this._state.show.bind(this._state);

  public constructor(private readonly _state: NotificationsState) {}

  /** Retries the data fetching in case there was an error. */
  public retry(): void {
    this._state.retry();
  }
}
