import { Injectable, OnDestroy } from '@angular/core';
import { ListSchoolsService } from '@app/core/services/list-schools/list-schools.service';

import { StudentService } from '@app/core/services/student/student.service';
import { LaunchDarklyState } from '@app/core/states/launch-darkly/launch-darkly.state';
import { AuthenticationService } from '@coach-bot/data-access/auth';
import { combineLatest, Subscription } from 'rxjs';

import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export default class StudentFacade implements OnDestroy {
  public enableAll = this._service.enableAll.bind(this._service);
  public notAllSelected$ = this._service.studentId$.pipe(map((id) => id !== 'ALL'));
  public schools$ = this._listSchoolsService.schools$;
  public setStudentFilter = this._service.setStudentFilter.bind(this._service);
  public student$ = this._service.student$;
  public studentFilterEnabled$ = this._service._studentFilterEnabled$;
  public studentId$ = this._service.studentId$;
  public students$ = this._service.students$;
  public studentsFiltered$ = this._service.studentsFiltered$;
  public updateStudentId = this._service.updateStudentId.bind(this._service);
  public updateStudents = this._service.updateStudents.bind(this._service);
  private _subscription: Subscription;

  public constructor(
    private readonly _service: StudentService,
    private readonly _listSchoolsService: ListSchoolsService,
    private readonly _authService: AuthenticationService,
    private readonly _launchDarkly: LaunchDarklyState
  ) {
    this._initializeLaunchDarkly();
  }

  public isNotAlphaStudent() {
    return this.student$.pipe(map((student) => student.schoolName.toLowerCase() !== 'alpha'));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _initializeLaunchDarkly(): void {
    this._subscription = combineLatest([this._authService.userDetails$.pipe(take(1)), this.student$]).subscribe(
      ([user, student]) => {
        if (user && student) {
          this._launchDarkly.init(user.id, student.schoolName);
        }
      }
    );
  }
}
