<div class="notification-item__container" fxLayout="row" fxLayoutGap="8px">
  <div class="notification-item__image"
       [class.notification-item__image--insight]="isAppInsight"
       [class.notification-item__image--reward]="isReward">
    <img width="20" height="18" alt="achievement type image" [src]="image">
  </div>
  <div fxFlex="328px" fxLayout="column" fxLayoutGap="4px">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center">
        <div kendoCardSubtitle class="k-text-dark k-mb-0 k-font-weight-bold">{{title}}</div>
        <ng-container *ngIf="!isRead">
          <div class="notification-item__new k-bg-error" ></div>
        </ng-container>
      </div>

      <div>{{dataCreated | date : dateFormat }}</div>
    </div>
    <div class="notification-item__message"> {{message}} </div>
  </div>
</div>
