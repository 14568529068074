import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'yearLabel' })
export class YearLabelPipe implements PipeTransform {
  transform(year: string): string {
    const suffix = year !== '1' ? 'S' : '';
    return `${year} YEAR${suffix}`;
  }

}
