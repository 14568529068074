import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from '@coach-bot/data-access/auth';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private readonly _router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isLoggedIn$.pipe(tap(isLoggedIn => {
      if (!isLoggedIn) {
        this.authService.redirectUrl = state.url;
        this._router.navigateByUrl('/auth/login', {});
      }
    }), map(isLoggedIn => isLoggedIn));
  }
}
