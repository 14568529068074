import { Injectable } from '@angular/core';
import { ObjectMapper } from 'json-object-mapper';
import { SkillRecommendation } from 'libs/coach-bot/data-access/core/src/lib/models/skill-recommendation.model';
import { map } from 'rxjs/operators';
import { AppSyncHelper } from '../helpers';
import { SKILL_STACK_RESOURCE_RECOMMENDATIONS } from '../resolvers';

/**
 * Core Service responsible for API communication related to Skill Stack operations.
 * The Skill Stack operations are usually to be used with our 2D and 3D SkillStacks (Jenga-like)
 * but as this is a core CoachBot data we are not separating it into separate modules to improve maintainability.
 *
 * Please note that there are still other services in different modules containing other Skill Stack related
 * functions used with our older 2D version of Skill Stack but as per the latest architecture those should be
 * deprecated and transferred to this service.
 */
@Injectable({providedIn: 'root'})
export class SkillStackService {
  constructor(public appSyncHelper: AppSyncHelper) {}

  /**
   * Returns a list of recommended skills student should learn next.
   * Though it can return multiple recommendations, we usually use this with our 3D stack where we need only one skill.
   *
   * @param studentId ID of the student for which we need recommendation
   * @param gapFillingStartGrade Minimum grade to be used to get recommendation
   * @param studentGrade Current student's grade
   * @param subjectName Name of the subject for which we need recommendation - default Math
   * @param appName Name of the app for which we need recommendation - default IXL
   * @param numberOfResources Number of recommendations - default 1
   */
  public getResourceRecommendations(
    studentId: string,
    gapFillingStartGrade: number,
    studentGrade: number,
    subjectName: string = 'Math',
    appName: string = 'IXL',
    numberOfResources: number = 1
  ) {
    console.info('Fetching skill recommendations...');
    return this.appSyncHelper.query(SKILL_STACK_RESOURCE_RECOMMENDATIONS, {
      appName,
      gapFillingStartGrade,
      studentGrade,
      subjectName,
      studentId,
      numberOfResources
    }).pipe(map((res: {getResourcesRecommendations: SkillRecommendation}) => {
      return res.getResourcesRecommendations ? ObjectMapper.deserializeArray(SkillRecommendation,res.getResourcesRecommendations) : [];
    }));
  }
}
