import { Observable, from, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen, scan, switchMap, takeUntil } from 'rxjs/operators';

const ONE_SECOND_MS = 1000;
const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_RETRY_TIMEOUT_MS = 15 * ONE_SECOND_MS;
const ERROR_MESSAGE = 'An error occurred. Please try again.';
const MAX_RETRY_DELAY = 60 * ONE_SECOND_MS;

export function withRetry<T>(
  observable: Observable<T>,
  maxRetries: number = DEFAULT_MAX_RETRIES,
  retryTimeoutMs: number = DEFAULT_RETRY_TIMEOUT_MS,
  allowErrors = false
): Observable<T> {
  let modifiedObservable = observable;

  if (retryTimeoutMs > 0) {
    const timeoutSignal = timer(retryTimeoutMs).pipe(switchMap(() => throwError(new Error('Retry timeout exceeded'))));
    modifiedObservable = modifiedObservable.pipe(takeUntil(timeoutSignal));
  }

  return modifiedObservable.pipe(retryWhen((errors) => handleRetryErrors(errors, maxRetries, allowErrors)));
}

function handleRetryErrors(errors: Observable<Error[]>, maxRetries: number, allowErrors: boolean): Observable<number> {
  return errors.pipe(
    scan((acc, error) => {
      if (acc < maxRetries) {
        console.log(`Attempt ${acc + 1}/${maxRetries} failed. ${error}`);
        return acc + 1;
      } else {
        if (!allowErrors) {
          console.error(`No more retries left, throwing error. ${error}`);
          throw error;
        } else {
          return acc;
        }
      }
    }, 0),
    mergeMap((retryCount) =>
      retryCount < maxRetries
        ? timer(Math.min(ONE_SECOND_MS * Math.pow(2, retryCount), MAX_RETRY_DELAY))
        : throwError(new Error(ERROR_MESSAGE))
    )
  );
}

export function withRetryPromise<T>(
  promise: Promise<T>,
  maxRetries: number = DEFAULT_MAX_RETRIES,
  retriesTimeoutMs: number = DEFAULT_RETRY_TIMEOUT_MS,
  allowErrors = false
): Promise<T> {
  return withRetry(from(promise), maxRetries, retriesTimeoutMs, allowErrors).toPromise();
}
