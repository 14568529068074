import { JsonProperty } from 'json-object-mapper';

import { PermissionKey } from './permission-key.model';

export class Permission {
  @JsonProperty() isPermissionOn: boolean;
  @JsonProperty() permissionKey: PermissionKey;

  constructor() {
    this.isPermissionOn = false;
    this.permissionKey = null;
  }
}
