import {Injectable} from '@angular/core';
import {AppSyncHelper} from '@coach-bot/data-access/core';
import {ObjectMapper} from 'json-object-mapper';
import {GET_URL_FOR_LEADERBOARD} from 'libs/coach-bot/data-access/leaderboards/src/lib/resolvers';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {QuicksightEmbeddedDashboardModel} from '@coach-bot/data-access/core';
import {Leaderboard} from '../models/leaderboard.model';
import {GET_LEADERBOARDS_V2} from '../resolvers/queries/get-leaderboards.query';

@Injectable()
export class LeaderboardsService {
  constructor(private readonly appSyncHelper: AppSyncHelper) {}

  public getLeaderboardUrl(studentId: string, leaderboardId: string) {
    return this.appSyncHelper.query(GET_URL_FOR_LEADERBOARD, {studentId, leaderboardId}).pipe(map(
      (response: { getUrlForLeaderboard: QuicksightEmbeddedDashboardModel }) => ObjectMapper.deserialize(QuicksightEmbeddedDashboardModel,
        response.getUrlForLeaderboard)));
  }

  public getLeaderboards(): Observable<Leaderboard[]> {
    return this.appSyncHelper.query(GET_LEADERBOARDS_V2, {}).pipe(map(
      (response: { getLeaderboardsV2: Leaderboard[] }) => ObjectMapper.deserializeArray(Leaderboard,
        response.getLeaderboardsV2)));
  }
}
