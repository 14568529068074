import { MOMENT_ISO_DATE } from '../config/date-format.config';

import {
  differenceInBusinessDays,
  isMonday,
  isSaturday,
  isSunday,
  nextMonday,
  nextSaturday,
  subWeeks,
  previousSaturday,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as moment from 'moment-timezone';

const HOUR_IN_MS = 3600000;
const MINUTE_IN_MS = 60000;
const SECOND_IN_MS = 1000;

export class DateUtility {
  public static convertHMSToMS(hour: number, minutes: number, seconds: number) {
    return hour * HOUR_IN_MS + minutes * MINUTE_IN_MS + seconds * SECOND_IN_MS;
  }

  public static convertMSToHrs(ms: number) {
    return ms / HOUR_IN_MS;
  }

  static convertToIso(date: string): string {
    return moment(date).format(MOMENT_ISO_DATE);
  }

  public static getLocalTime(date: Date) {
    return utcToZonedTime(date, 'UTC').getTime();
  }

  /**
   * Calculate the business days between two dates.
   * Negative result values are supported. It will happen when "end" comes after "start".
   * Time will be removed before doing the calculation.
   * @param {Date} start date. It will be first in the operation.
   * @param {Date} end date. It will be last in the operation.
   * @param {boolean} inclusive decide whether "start" should be included in the final result.
   * @returns {number}
   */
  public static differenceInBusinessDays(start: Date, end: Date, inclusive = false): number {
    let result = differenceInBusinessDays(start, end);

    if (inclusive && !isSaturday(start) && !isSunday(start)) {
      result += 1;
    }

    return result;
  }

  /**
   * Gets the next Monday.
   * @param {Date} date from which to find the next Monday.
   * @param {boolean} inclusive if you want to include "today" as the return date if it is a Monday.
   * @returns {Date} the next Monday.
   */
  static nextMonday(date: Date, inclusive = false): Date {
    return inclusive && isMonday(date) ? date : nextMonday(date);
  }

  public static previousSaturday(data = new Date()) {
    return previousSaturday(data);
  }

  /**
   * Gets the next Saturday.
   * @param {Date} date from which to find the next Saturday.
   * @param {boolean} inclusive if you want to include "today" as the return date if it is a Saturday.
   * @returns {Date} the next Saturday.
   */
  static nextSaturday(date: Date, inclusive = false): Date {
    return inclusive && isSaturday(date) ? date : nextSaturday(date);
  }

  /**
   * Gets the previous Saturday.
   * @param {Date} date from which to find the previous Saturday.
   * @param {boolean} inclusive if you want to include "today" as the return date if it is a Saturday.
   * @returns {Date} the previous Saturday.
   */
  static prevSaturday(date: Date, inclusive = false): Date {
    return inclusive && isSaturday(date) ? date : DateUtility.nextSaturday(subWeeks(date, 1), true);
  }
}
