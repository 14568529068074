import {Observable, Subject} from 'rxjs';

import {ChartData} from '@app/core/models/chart-data.model';

import {ChartDataWebWorkerRequest} from './chart-data-web-worker-request.model';

export class ChartDataWebWorkerHelper {
  public data$: Observable<ChartData[] | ChartData[][]>;

  private readonly _data: Subject<ChartData[]>;
  private readonly _worker: Worker;

  public constructor() {
    this._data             = new Subject();
    this.data$             = this._data.asObservable();
    this._worker           = new Worker('./chart-data.worker.ts', {type: 'module'});
    this._worker.onmessage = this.updateData.bind(this);
  }

  public post(message: ChartDataWebWorkerRequest) {
    this._worker.postMessage(message);
  }

  private updateData(event: MessageEvent) {
    const {data} = event;
    this._data.next(data);
    this._data.complete();
    this._worker.terminate();
  }
}
