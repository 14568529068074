/** Duration used for opening a snackbar */
export const DURATION = 6000;

/** panelClass value to use on success */
export const TAG = {
  success: 'success-snackbar'
};

export const DISMISS_BTN_TEXT   = 'DISMISS';
export const SNACK_BAR_POSITION = 'bottom';
