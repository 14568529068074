import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from '@app/shared/components/not-found/not-found.component';
import { LoggedInGuard } from '@app/shared/guards/logged-in-guard.service';
import { ErrorPageComponent } from 'apps/alpha-coach-bot/src/app/shared/components/error-page/error-page.component';

const CALENDLY_LINK = 'https://calendly.com/scott-weingold-gtschool/introductory-meeting';
const QUICK_LEARN_MATH_ACC_LINK = 'https://buy.stripe.com/aEU6pXfLL6qw5qwcN1';
const LEARNING_LOSS_REGISTRATION_TITLE_DEFAULT = 'Get the MathBuilder Program';
const LEARNING_LOSS_REGISTRATION_TITLE_QUICKLEARN = 'Get the MathAssess Program';

export const routes: Routes = [
  {
    path: '',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('app/containers/main/main.module').then((M) => M.MainModule),
  },
  {
    path: 'bootcamp-leaderboard',
    loadChildren: () => import('app/modules/bootcamp/bootcamp.module').then((M) => M.BootcampModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('app/containers/auth/auth.module').then((M) => M.AuthModule),
  },
  {
    path: 'tracker',
    loadChildren: () => import('app/modules/tracker/tracker.module').then((M) => M.TrackerModule),
  },
  {
    path: 'mathbuilder-program-registration',
    loadChildren: () =>
      import('@coach-bot/feature/student-registration').then((m) => m.CoachBotFeatureStudentRegistrationModule),
    data: {
      stripeRedirection: true,
      useApiRedirectUrl: true,
      redirectUrl: undefined,
      title: LEARNING_LOSS_REGISTRATION_TITLE_DEFAULT,
    },
  },
  {
    path: 'covid-learning-loss-report-registration-2',
    loadChildren: () =>
      import('@coach-bot/feature/student-registration').then((M) => M.CoachBotFeatureStudentRegistrationModule),
    data: {
      stripeRedirection: false,
      useApiRedirectUrl: false,
      redirectUrl: CALENDLY_LINK,
      title: LEARNING_LOSS_REGISTRATION_TITLE_DEFAULT,
    },
  },
  {
    path: 'mathassess-program-registration',
    loadChildren: () =>
      import('@coach-bot/feature/student-registration').then((M) => M.CoachBotFeatureStudentRegistrationModule),
    data: {
      stripeRedirection: true,
      useApiRedirectUrl: false,
      redirectUrl: QUICK_LEARN_MATH_ACC_LINK,
      appendParentEmail: true,
      title: LEARNING_LOSS_REGISTRATION_TITLE_QUICKLEARN,
    },
  },
  {
    path: 'parent-consent',
    loadChildren: () => import('@coach-bot/feature/parent-consent').then((M) => M.CoachBotFeatureParentConsentModule),
  },
  {
    path: 'parent-consent-gt',
    loadChildren: () =>
      import('@coach-bot/feature/parent-consent-gt').then((M) => M.CoachBotFeatureParentConsentGtModule),
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class AppRoutingModule {}
