import {JsonProperty} from 'json-object-mapper';

export class DashboardTimeDataPoint {
  @JsonProperty() public date: string;
  @JsonProperty() public learningAppName: string;
  @JsonProperty() public minutesAtHome: number;
  @JsonProperty() public minutesAtSchool: number;
  @JsonProperty() public mostTimeWorkedCourseName: string;
  @JsonProperty() public mostTimeWorkedTopicName: string;

  public constructor() {
    this.date                     = null;
    this.learningAppName          = null;
    this.minutesAtHome            = null;
    this.minutesAtSchool          = null;
    this.mostTimeWorkedCourseName = null;
    this.mostTimeWorkedTopicName  = null;
  }
}
