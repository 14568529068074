import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

import {NOTIFICATION_DATE_FORMAT} from '@app/core/config/app.config';
import {NotificationType} from '@app/modules/notifications/models/notification-type';

@Component({
  selector: 'notification-item', styleUrls: ['./notification-item.component.scss'],
  templateUrl: './notification-item.component.html'
})
export class NotificationItemComponent {
  /** To be used to set the date for notification. */
  @Input() public dataCreated: Date | string;
  /** Date format used for notification. */
  public dateFormat = NOTIFICATION_DATE_FORMAT;
  /** To be used to set the image for notification. */
  @Input() public image: string;
  /** To be used to set the read status notification. */
  @Input() public isRead: boolean;

  /** To be used to set the message for notification. */
  @Input() public message: string;
  /** To be used to represent the notification type. */
  public notificationType = NotificationType;
  /** Emit the event on click. */
  @Output() public select: EventEmitter<boolean> = new EventEmitter();
  /** To be used to set the title for notification. */
  @Input() public title: string;
  /** To be used to set status for notification. if it is new or not.*/
  @Input() public type: NotificationType;

  /** Whether the notification is insight type or not. */
  public get isAppInsight(): boolean {
    return this.type === this.notificationType.AppInsight;
  }

  /** Whether the notification is reward type or not. */
  public get isReward(): boolean {
    return this.type === this.notificationType.Reward;
  }

  /** To be used to trigger the click event*/
  @HostListener('click')
  public onClick() {
    this.select.emit(true);
  }
}
