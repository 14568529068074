export const NEW_NOTIFICATION = `
 subscription OnCreateNotification(
  $studentId: ID!
 ) {
  onCreateNotification(studentId: $studentId) {
    createdOn
    id
    isRead
    message
    notificationType
    studentId
    title
  }
}`;
