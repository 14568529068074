import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LEADERBOARD_FEATURE_KEY, leaderboardAdapter, State} from './leaderboard.reducer';

const {selectAll, selectEntities} = leaderboardAdapter.getSelectors();

// Lookup the 'Leaderboard' feature state managed by NgRx
export const getLeaderboardState = createFeatureSelector<State>(LEADERBOARD_FEATURE_KEY);

export const getLeaderboards = createSelector(getLeaderboardState, (state: State) => selectAll(state));

export const loaded = createSelector(getLeaderboardState, (state: State) => state.loaded);

export const error = createSelector(getLeaderboardState, (state: State) => state.error);

export const getSelectedEntities = createSelector(getLeaderboardState, (state: State) => selectEntities(state));

export const getSelectedLeaderboard = createSelector(getLeaderboardState, getSelectedEntities,
  (state, entities) => state.selectedLeaderboardId ? entities[state.selectedLeaderboardId] : undefined);

export const getEmbeddedLeaderboard = createSelector(getLeaderboardState, (state: State) => state.embeddedLeaderboard);
