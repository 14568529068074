export const USER_DETAILS = /* GraphQL */ `
  query GetUserDetails($userId: String!) {
    getUserDetails(userId: $userId) {
      id
      firstName
      lastName
      preferredName
      email
      roleName
      locale
      profileImageURL
      coverImageURL
      acceptedTerms
      permissions
    }
  }
`;
