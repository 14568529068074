import { Component } from '@angular/core';

import { LoginFacade } from '@app/modules/auth/facades/login-facade';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  /** Whether to show the error code or not */
  public showErrorCode: boolean;

  constructor(public readonly facade: LoginFacade) {
    this.showErrorCode = !Boolean(window.history.state?.noErrorCode);
  }
}
