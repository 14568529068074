import { JsonProperty } from 'json-object-mapper';
import { CourseStatus } from '../enums/course-status.enum';
import { MasteryExternalObjective } from './mastery-external-objective.model';
import { Standard } from './standard.model';

export class Course {
  @JsonProperty() public description: string;
  @JsonProperty() public grade: number;
  @JsonProperty() public order: number;
  @JsonProperty() public masteryExternalObjectives: MasteryExternalObjective[];
  @JsonProperty() public standards: Standard[];
  @JsonProperty() public courseStatus: CourseStatus;
  @JsonProperty() public masteryPercentage: number;
  @JsonProperty() public name: string;
  @JsonProperty() public testUrl: string;

  constructor() {
    this.description = '';
    this.grade = null;
    this.order = null;
    this.masteryExternalObjectives = null;
    this.standards = null;
    this.courseStatus = CourseStatus.NOT_STARTED;
    this.masteryPercentage = 0;
    this.name = '';
    this.testUrl = null;
  }

  public get isMastered() {
    return this.courseStatus === CourseStatus.MASTERED;
  }

  public get isNotStarted() {
    return this.courseStatus === CourseStatus.NOT_STARTED;
  }
}
