export const LIST_STUDENT_USERS = /* GraphQL */ `
  query ListStudentUsers {
    listStudentUsers {
			user_id
			first_name
			last_name
			email
			role_name
			student_id
			campus_id
			alpha_student_id
			alpha_learning_level
			date_of_birth
			preferred_name
			map_grade_level
			gender
			is_active
			profile_image
			cover_image
			school_id
			school_name
		}
  }
`;
