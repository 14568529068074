import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StorageHelper } from 'app/core/aws/storage.helper';
import AuthenticationFacade from 'app/core/facades/authentication.facade';
import StudentFacade from 'app/core/facades/student.facade';
import { ImageUploaderComponent } from 'app/shared/components/image-uploader/image-uploader.component';
import { combineLatest, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

const IMAGE_RATIO = 1;
const IMAGE_SIZE = 384;

@Injectable()
export class ImageUploaderService {
  constructor(
    protected readonly authFacade: AuthenticationFacade,
    protected readonly dialog: MatDialog,
    protected readonly storage: StorageHelper,
    protected readonly studentFacade: StudentFacade
  ) {}

  public openImageUploader(image: string): void {
    this.dialog
      .open(ImageUploaderComponent, { data: { image, ratio: IMAGE_RATIO, width: IMAGE_SIZE, round: true } })
      .afterClosed()
      .pipe(
        filter((result) => Boolean(result)),
        switchMap((result) => combineLatest([of(result), this.studentFacade.student$, this.authFacade.isStudent$])),
        take(1),
        tap(([profileImageURL, student, isStudent]) => {
          this.authFacade.updateStateUserDetails({ profileImageURL });

          if (isStudent) {
            this.studentFacade.updateStudents([{ ...student, profileImage: profileImageURL }]);
          }
        }),
        switchMap(([newImage]) => combineLatest([this.storage.upload(newImage), this.authFacade.user$])),
        take(1),
        switchMap(([profileImageURL, user]) => {
          const details = { profileImageURL, userId: user.attributes['custom:userID'] };
          return this.authFacade.updateUserProfileImage(details);
        }),
        take(1)
      )
      .subscribe();
  }
}
