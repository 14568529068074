import {Injectable} from '@angular/core';
import {School} from '@app/core/models/school.model';
import {LIST_SCHOOLS} from '@app/core/resolvers/queries/list-schools.query';
import {AppSyncHelper} from '@coach-bot/data-access/core';

import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, filter, first, shareReplay, tap} from 'rxjs/operators';
import {ListSchoolsQuery} from '../../../API.service';

@Injectable()
export class ListSchoolsService {
  private readonly _schools = new BehaviorSubject<School[]>([]);
  public schools$           = this._schools.asObservable().pipe(tap(schools => {
    if (!schools.length) {
      this.getAll();
    }
  }), filter(levels => Boolean(levels.length)), distinctUntilChanged(), shareReplay(1));

  constructor(private readonly appSyncHelper: AppSyncHelper) {
  }

  public getAll() {
    this.appSyncHelper
      .query<ListSchoolsQuery, string>(LIST_SCHOOLS, '')
      .pipe(first())
      .subscribe(data => this._schools.next(data.listSchools));
  }
}
