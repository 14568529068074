import {Injectable} from '@angular/core';
import {LeaderboardsService} from '@coach-bot/data-access/leaderboards';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import * as LeaderboardActions from './leaderboard.actions';

const ERROR = 'Something went wrong';

@Injectable()
export class LeaderboardEffects {
  getLeaderboardUrl$ = createEffect(() => this.actions$.pipe(ofType(LeaderboardActions.selectLeaderBoard),
    switchMap(action => this.service.getLeaderboardUrl(action.studentId, action.id)
      .pipe(map(embeddedLeaderboard => LeaderboardActions.loadLeaderboardSuccess({embeddedLeaderboard})),
        catchError(() => of(LeaderboardActions.loadLeaderboardFailure({error: ERROR})))))));

  init = createEffect(
    () => this.actions$.pipe(ofType(LeaderboardActions.init), switchMap(() => this.service.getLeaderboards()
      .pipe(map(leaderboards => LeaderboardActions.loadLeaderboardListSuccess({leaderboards})),
        catchError(() => of(LeaderboardActions.loadLeaderboardListFailure({error: ERROR})))))));

  constructor(private readonly actions$: Actions, private service: LeaderboardsService) {}
}
