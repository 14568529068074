import { MasteryStatus } from '../enums/mastery-status.enum';

export const MasteryColorConfig: { [key: string]: string } = {
  [MasteryStatus.Mastered]: '#28A745',
  [MasteryStatus.NotFullyMastered]: '#63D97F',
  [MasteryStatus.Proficient]: '#28A745',
  [MasteryStatus.NotMastered]: '#FFFFFF',
  [MasteryStatus.NotCovered]: '#E9ECEF',
  [MasteryStatus.NotEvaluated]: '#E1E5E9',
  [MasteryStatus.NotProficient]: '#FFFFFF',
};
