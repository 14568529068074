import {Injectable} from '@angular/core';

import AuthenticationFacade from '@app/core/facades/authentication.facade';
import StudentFacade from '@app/core/facades/student.facade';
import {NotificationInput} from '@app/modules/notifications/models/notification-input.model';
import {NotificationModel} from '@app/modules/notifications/models/notification.model';
import {NotificationsService} from '@app/modules/notifications/services/notifications.service';
import RapidLoggerFacade from '@app/rapid-loggerfacades/rapid-logger.facade';

import {ObjectMapper} from 'json-object-mapper';
import {BehaviorSubject, combineLatest, forkJoin, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NotificationsState {
  /** Used to set the state for notification count. */
  private readonly notificationsCount = new BehaviorSubject<number>(0);

  /** Used to set the state for notification popup visisbility. */
  private readonly isVisible = new BehaviorSubject<boolean>(false);

  /** Used to set the state if data is loaded or not. */
  private readonly loadingCompleted = new BehaviorSubject<boolean>(false);

  /** Holds the my passes data. */
  private readonly notifications = new BehaviorSubject<NotificationModel[]>([]);

  /** A flag determining if the state has errors. */
  private readonly hasError = new BehaviorSubject<boolean>(false);

  /** Observable to check if data is loaded or not. */
  public loadingCompleted$ = this.loadingCompleted.asObservable();

  /** Observable to emit the my passes data to view. */
  public notifications$ = this.notifications.asObservable().pipe(tap(res => {
    this.notificationsCount.next(
      res?.filter(notification => !notification.isRead).length
    );
  }));

  /** Observable to emit the state has errors */
  public hasError$ = this.hasError.asObservable();

  /** Whether or not to show the notification popup.*/
  public isVisible$ = this.isVisible.asObservable();

  /** Verify if the student is selected or not. */
  public isStudentSelected$: Observable<boolean>;

  /** Observable for notification count. */
  public notificationsCount$ = this.notificationsCount.asObservable();

  public constructor(
    private readonly _service: NotificationsService,
    private readonly _logger: RapidLoggerFacade,
    private readonly _studentFacade: StudentFacade,
    private readonly _authFacade: AuthenticationFacade,
  ) {
    this.verifyIfStudentActive();
    this.getNewNotifications();
    this.init();
  }

  /** Retries the data fetching in case there was an error. */
  public retry(): void {
    this.hasError.next(false);
    this.loadingCompleted.next(false);
  }

  /** Verify if the user is student or the filter contains student selector */
  private verifyIfStudentActive() {
   this.isStudentSelected$ = combineLatest([this._studentFacade.studentFilterEnabled$, this._authFacade.isStudent$])
      .pipe(
        map(([filterEnabled, isStudent]) => (filterEnabled || isStudent)),
        debounceTime(0),
        tap(isStudent => {
          if(!isStudent) {
           this.notificationsCount.next(0);
          }
        }),
      )
  }

  private getNewNotifications(): void {
    combineLatest([this._authFacade.isStudent$, this._studentFacade.studentId$])
      .pipe(
      filter(([isStudent]) => Boolean(isStudent)),
      switchMap(([_isStudent, id]) => this._service.onNewNotification(id))
      )
      .subscribe(notification =>
         this.notifications.next([notification, ...this.notifications.value])
      );
  }

  /** Initialize the service to fetch notifications on load. */
  private init() {
    combineLatest([
      this._studentFacade.studentId$,
      this.isStudentSelected$
    ])
      .pipe(
        filter(([_studentId, hasStudent]) => hasStudent),
        distinctUntilChanged(),
        map(([studentId]) => studentId),
        switchMap(id => this.getNotifications(id))
      ).subscribe(res => {
      this.notifications.next(res);
      this.loadingCompleted.next(true);
    }, e => {
      this.hasError.next(true);
      this._logger.handleError(new Error(e), true);
    });
  }

  /** Fetch the notification from service end point */
  private getNotifications(studentId: string) {
    return this._service.getNotifications(studentId).pipe(take(1),
      map(res => res.filter(item => item.studentId === studentId)));
  }

  /** Trigger the api to create new notification */
  public createNotification(notification: NotificationInput) {
    this._service.createNotification(notification)
      .pipe(take(1)).subscribe(
        res => {
          this.notifications.next([res, ...this.notifications?.value])
        },
        e => {
          this.hasError.next(true);
          this._logger.handleError(new Error(e), true);
      });
  }

  /** To be used to update the bulk notifications status. */
  public updateNotification() {
    if (this.notifications.value?.length) {
      const notificationsObservable = this.notifications.value
        .filter(item => !item.isRead)
        .map(item => this.onUpdate(item.id));

      forkJoin([...notificationsObservable])
        .pipe(take(1)).subscribe(
          () => {
            const notifications = ObjectMapper.deserializeArray(
              NotificationModel,
              this.notifications?.value?.map(item => ({...item, isRead: true}))
            );
            this.notifications.next(notifications)
          },
          e => {
            this.hasError.next(true);
            this._logger.handleError(new Error(e), true);
          });
    }
  }

  /** Triggers the update service method for updating the notification status. */
  public onUpdate(notificationId: string) {
    return this._service.updateNotification(notificationId)
  }

  /** To be used to set the notification popup visibility. */
  public show() {
    if (!this.isVisible.value && this.notifications.value?.length) {
      this._authFacade.isStudent$.pipe(filter(isStudent => Boolean(isStudent))).subscribe(() =>
        this.updateNotification()
      );
    }

    this.isVisible.next(!this.isVisible.value);
  }
}
