import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppNamePipe } from 'libs/shared/util/src/pipes/app-name.pipe';

import { LevelLabelPipe } from '../pipes/level-label.pipe';
import {
  NgAbsPipeModule,
  NgFloorPipeModule,
  NgRoundPipeModule,
  NgOrdinalPipeModule,
  NgCeilPipeModule,
} from 'angular-pipes';
import { NumberWithPercentPipe } from '../pipes/number-with-percent.pipe';

import { AgePipe } from '../pipes/age.pipe';
import { MinPipe } from '../pipes/min.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { YearLabelPipe } from '../pipes/year-label.pipe';

const PIPES = [AgePipe, YearLabelPipe, TruncatePipe, MinPipe, NumberWithPercentPipe, LevelLabelPipe, AppNamePipe];
/** Pipes coming from angular-pipes package **/
const NG_PIPES = [NgAbsPipeModule, NgRoundPipeModule, NgFloorPipeModule, NgOrdinalPipeModule, NgCeilPipeModule];

@NgModule({
  declarations: [...PIPES],
  exports: [...PIPES, ...NG_PIPES],
  imports: [CommonModule, ...NG_PIPES],
})
export class SharedUtilModule {}
