import {Injectable} from '@angular/core';

import {AuthenticationService} from '@coach-bot/data-access/auth';
import { combineLatest, Observable } from "rxjs";

import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export default class AuthenticationFacade {
  public deleteUser                 = this._service.deleteUser.bind(this._service);
  public isAdmin$                   = this._service.isAdmin$;
  public isViewSkillsStackAccess$   = this._service.isViewSkillsStackAccess$;
  public noViewSkillsStackAccess$   = this._service.isViewSkillsStackAccess$.pipe(map(access => !access));
  public noFullReportView$          = combineLatest([this._service.isStudent$, this._service.isFullReportAccess$]).pipe(
    map(([isStudent, isFullReportAccess]) => isStudent ? !isFullReportAccess : false));
  public noCovidLearningLossAccess$ = combineLatest([this._service.isStudent$, this._service.isViewLearningLossReportAccess$]).pipe(
    map(([isStudent, isViewingLearningLossAccess]) => isStudent ? !isViewingLearningLossAccess : false));
  public isReducedFullReportView    = combineLatest([this._service.isStudent$, this._service.isReducedFullReport$]).pipe(
    map(([isStudent, isFullReportReduced]) => isStudent ? isFullReportReduced : false));
  public notReducedFullReportView$  = this.isReducedFullReportView.pipe(map(access => !access));
  public isGuide$                   = this._service.isGuide$;
  public isLoggedIn$                = this._service.isLoggedIn$;
  public isStudent$                 = this._service.isStudent$;
  public logout                     = this._service.logout.bind(this._service);
  public studentId$                 = this._service.user$.pipe(
    map(user => user?.signInUserSession.idToken.payload['custom:studentID'] || null));
  public updateCoverImage           = this._service.updateCoverImage.bind(this._service);
  public updateStateUserDetails     = this._service.updateStateUserDetails.bind(this._service);
  public updateUserDetails          = this._service.updateUserDetails.bind(this._service);
  public updateUserProfileImage     = this._service.updateUserProfileImage.bind(this._service);
  public user$                      = this._service.user$;
  public userDetails$               = this._service.userDetails$;
  public userId$                    = this._service.user$.pipe(
    map(user => user?.signInUserSession.idToken.payload['custom:userID'] || null));
  /**
   * Determines if the current user has special controls allowing to select and control students.
   * This type of user is either an Admin or a Guide.
   *
   * @author Milos Sretin
   * @since 2.0.0
   */
  public isSuperUser$: Observable<boolean> = combineLatest([this.isAdmin$, this.isGuide$])
    .pipe(map(([isAdmin, isGuide]) => isAdmin || isGuide))

  public constructor(private readonly _service: AuthenticationService) {}
}
