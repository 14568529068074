import {PaginationOptions} from '@app/shared/models/pagination-options';

/** displays time for animation */
export const ANIMATION_TIME     = 225;
/** Animation transition */
export const ANIMATION          = `${ANIMATION_TIME}ms cubic-bezier(0.4, 0.0, 0.2, 1)`;
export const debounce           = 500;
export const latency            = 800;
export const SNACK_BAR_DURATION = 5000;
export const second             = 1000;

export const STORAGE_KEY = {
  appId: 'appId', clientId: 'clientId', courseId: 'courseId', effort: 'effort', latestDashboardMessage: 'latestDashboardMessage',
  level: 'level', mapPercentage: 'mapPercentage', nonIntegratedAppId: 'nonIntegratedAppId', nonMapSubject: 'nonMapSubject',
  previousStudentId: 'previousStudentId', range: 'range', rangeChanged: 'rangeChanged', rangeExpired: 'rangeExpired',
  requestParams: 'requestParams', selectedGuidePerLevelId: 'selectedGuidePerLevelId',
  selectedStudentPerGuideId: 'selectedStudentPerGuideId',
  selectedTimeRange: 'selectedTimeRange', sessionCustom: 'sessionCustom', sessionFilteredId: 'sessionFilteredId',
  sessionWithCustomRangeId: 'sessionWithCustomRangeId', signUpEmail: 'signUpEmail',
  signUpProgramEnrollmentsData: 'signUpProgramEnrollmentsData', studentGradeLevel: 'studentGradeLevel',
  studentId: 'studentId', user: 'user', username: 'username', version: 'version', yearAtAlpha: 'yearAtAlpha'
};

/** displays version file name */
export const VERSION_FILE_NAME               = 'version.json';
export const DEFAULT_URL                     = '/';
export const LOGIN_URL                       = '/auth/login';
export const REGISTER_URL                    = '/auth/register';
export const MAINTENANCE_DATE_FORMAT         = 'MMM Do, YYYY, h:mma';
export const UPDATED_AT_DATE_FORMAT          = 'h:mmaaaaa\'m\' CT, EEEE, MMMM d, y';
export const CARD_EXPIRY_DATE_FORMAT         = 'dd/yy';
export const TRANSACTION_DATE_FORMAT         = 'h:mmaaaaa\'m\', MMM d, y';
export const NOTIFICATION_DATE_FORMAT        = TRANSACTION_DATE_FORMAT;
export const DAY_NAME_FORMAT                 = 'dddd';
export const DATE_FORMAT                     = 'YYYY-MM-DD';
export const DEFAULT_PAGE_SIZE               = 20;
export const CENTRAL_TIME_ZONE               = 'America/Chicago';
export const PAGE_OPTIONS: PaginationOptions = {
  pageSize: DEFAULT_PAGE_SIZE, showFirstLastButtons: true, pageIndex: 0, length: 0
};
