import { Injectable } from '@angular/core';
import { AppSyncHelper } from '@coach-bot/data-access/core';
import { Observable } from 'rxjs';
import * as mutations from '../../../../graphql/mutations';
import { UpdateUserTermInput, UpdateUserTermMutationVariables } from '../../../API.service';

@Injectable()
export class AcceptedTermService {
  constructor(private readonly appSyncHelper: AppSyncHelper) {}

  acceptTerm(variables: UpdateUserTermInput): Observable<UpdateUserTermMutationVariables> {
    return this.appSyncHelper.mutate<UpdateUserTermMutationVariables, { userDetails: UpdateUserTermInput }>(
      mutations.updateUserTerm,
      {
        userDetails: variables,
      }
    );
  }
}
