import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class RestrictedStudentGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private readonly _router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot) {
    return this.authService.isStudent$.pipe(
      tap((isStudent) => {
        if (isStudent) {
          this._router.navigate(['not-found'], {});
        }
      }),
      map((isStudent) => !isStudent)
    );
  }
}
