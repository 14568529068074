import {JsonProperty} from 'json-object-mapper';

export class Subject {
  @JsonProperty() public id: string;
  @JsonProperty() public image: string;
  @JsonProperty() public name: string;

  public constructor() {
    this.id    = null;
    this.image = null;
    this.name  = null;
  }
}
