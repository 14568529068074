import aws_exports from 'apps/alpha-coach-bot/src/aws-exports';

export const environment = {
  production: true,
  mode: 'production',
  sentryID: 'https://7a14caea54f94af1b011fa5893144a29@o4504227135291392.ingest.sentry.io/4504227149053952',
  productUrl: 'https://coachbot.gt.school/',
  launchDarklyKey: '61df22d9ccbc38143bd4c9fe',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  cloudfrontURL: 'https://d2nl2v1gvl9ttt.cloudfront.net',
  aws_s3_students_assets_bucket: 'alpha-coach-bot-students-assets',
  aws_s3_students_assets_url: 'https://alpha-coach-bot-students-assets.s3.amazonaws.com/public',
  activtrak_installer_mac:
    'https://gt-school-uploads.s3.amazonaws.com/activtrak-installer/ATMac638170_1EODbYcoqw8Y_35850289920.pkg',
  activtrak_installer_windows:
    'https://gt-school-uploads.s3.amazonaws.com/activtrak-installer/ATAcct638170_1EODbYcoqw8Y_7125952300.msi',
  bootcampLeaderboardApiUrl: 'https://sheets.googleapis.com/v4/spreadsheets/',
  sheetId: '1nyP-BD4qzomjSfXGDrabLIq-qw-HdTufjS3SYlLZ4pM',
  bootcampLeaderboardApiKey: 'AIzaSyAhvANeAZZwZd6klHMHbb631iupagJFtZ4',
  reportContentJSON: 'https://s3.amazonaws.com/coachbot.gtschool-assets-prod/covid-learning-loss-report/content.json',
  learningReportBucket: 'learning-loss-math-reports-prod',
  parentConsentApi: 'https://d2nl2v1gvl9ttt.cloudfront.net/data-collection-consent',
  parentConsentApiKey: 'ghQjvFccWz8HuslEiijRv7PEYuTpXZ3u7hobeFCB',
  ...aws_exports,
};
