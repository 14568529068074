import {Injectable} from '@angular/core';
import {STORAGE_KEY} from '@app/core/config/app.config';
import {CourseType} from '@app/core/models/course-type.model';
import {Course} from '@app/core/models/course.model';
import {LearningApp} from '@app/core/models/learning-app.model';
import {gradeLevelSorting} from '@app/shared/utilities/sort.utility';

import {AppSyncHelper} from '@coach-bot/data-access/core';

import {GetCourseEffortEnabledAppsQuery} from 'app/API.service';
import {getCourseEffortEnabledApps} from 'graphql/queries';

import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, take, tap} from 'rxjs/operators';

@Injectable()
export class EnabledAppService {
  public app$: Observable<LearningApp>;
  public appId$: Observable<string>;
  public apps$: Observable<LearningApp[]>;
  public appsRaw$: Observable<GetCourseEffortEnabledAppsQuery['getCourseEffortEnabledApps']>;
  public course$: Observable<Course>;
  public courseId$: Observable<string>;
  public courses$: Observable<Course[]>;
  public coursesFiltered$: Observable<Course[]>;
  public readonly appsRaw: BehaviorSubject<GetCourseEffortEnabledAppsQuery['getCourseEffortEnabledApps']>;

  private readonly _appId: BehaviorSubject<string>;
  private readonly _courseId: BehaviorSubject<string>;

  public constructor(private readonly _appSync: AppSyncHelper) {
    this.appsRaw   = new BehaviorSubject([]);
    this._appId    = new BehaviorSubject<string>(localStorage.getItem(STORAGE_KEY.appId));
    this._courseId = new BehaviorSubject(localStorage.getItem(STORAGE_KEY.courseId));

    this.appsRaw$ = this.appsRaw.asObservable().pipe(tap(apps => {
      if (!apps.length) {
        this.list();
      }
    }), filter(apps => Boolean(apps.length)), distinctUntilChanged(), shareReplay(1));

    this.apps$ = this.appsRaw$.pipe(map(apps => {
      return apps.reduce((prev, curr) => {
        return prev.every(app => app.id !== curr.learningAppId) ?
          [...prev, {id: curr.learningAppId, name: curr.learningAppName, image: curr.learningAppImage}] : [...prev];
      }, []);
    }));

    this.appId$ = combineLatest([this._appId, this.apps$]).pipe(
      map(([id, apps]) => (apps.some(app => app.id === id) ? id : apps[0].id)), distinctUntilChanged());

    this.app$ = combineLatest([this.appId$, this.apps$]).pipe(map(([id, apps]) => apps.find(app => app.id === id)));

    this.courses$ = this.appsRaw$.pipe(map(apps => {
      return apps
        .map(item => ({
            id: item.courseId || item.topicId, appId: item.learningAppId, name: item.courseName || item.topicName,
            subjectId: item.subjectId, type: item.courseId ? CourseType.Course : CourseType.Topic
          }))
        .sort(gradeLevelSorting);
    }), distinctUntilChanged());

    this.coursesFiltered$ = combineLatest([this.courses$, this.appId$]).pipe(
      map(([courses, id]) => courses.filter(value => value.appId === id)));

    this.courseId$ = combineLatest([this._courseId, this.coursesFiltered$]).pipe(
      filter(([_, courses]) => Boolean(courses && courses.length)),
      map(([id, courses]) => (courses.some(course => course.id === id) ? id : courses[0].id)), distinctUntilChanged());

    this.course$ = combineLatest([this.courseId$, this.coursesFiltered$]).pipe(
      map(([id, courses]) => courses.find(course => course.id === id)), filter(course => Boolean(course)));
  }

  public getAllCourses(): Observable<GetCourseEffortEnabledAppsQuery> {
    return this._appSync.query(getCourseEffortEnabledApps, '');
  }

  public list(): void {
    this._appSync
      .query(getCourseEffortEnabledApps, null)
      .pipe(take(1), map((response: GetCourseEffortEnabledAppsQuery) => response.getCourseEffortEnabledApps))
      .subscribe(apps => this.updateAppsRaw(apps));
  }

  public updateAppId(id: string): void {
    this._appId.next(id);
    localStorage.setItem(STORAGE_KEY.appId, id);
  }

  public updateAppsRaw(apps: GetCourseEffortEnabledAppsQuery['getCourseEffortEnabledApps']): void {
    this.appsRaw.next([...apps]);
  }

  public updateCourseId(id: string): void {
    this._courseId.next(id);
    localStorage.setItem(STORAGE_KEY.courseId, id);
  }
}
