import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';

import {CoachBotUiModule} from '@alpha-coach-bot/coach-bot/ui';
import {CoachBotDataAccessLeaderboardsModule} from '@coach-bot/data-access/leaderboards';
import {SharedUtilModule} from '@coach-bot/shared/util';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {IconModule} from '@progress/kendo-angular-icons';
import {LoaderModule} from '@progress/kendo-angular-indicators';
import {CardModule} from '@progress/kendo-angular-layout';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {LeaderboardEffects} from './+state/leaderboard.effects';
import {LeaderboardFacade} from './+state/leaderboard.facade';
import * as fromLeaderboard from './+state/leaderboard.reducer';
import {LeaderboardComponent} from './pages/leaderboard/leaderboard.component';

@NgModule({
  imports: [CommonModule, CoachBotDataAccessLeaderboardsModule, CoachBotUiModule, SharedUtilModule,
    StoreModule.forFeature(fromLeaderboard.LEADERBOARD_FEATURE_KEY, fromLeaderboard.reducer),
    EffectsModule.forFeature([LeaderboardEffects]), LoaderModule, CardModule, FlexModule, IconModule, TooltipModule],
  providers: [LeaderboardFacade], declarations: [LeaderboardComponent], exports: [LeaderboardComponent]
})
export class CoachBotFeatureLeaderboardsModule {}
