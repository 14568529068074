<div class="notification__block">
  <ng-container *ngIf="{
          count:              _notificationsFacade.notificationsCount | async,
          isVisible:          _notificationsFacade.isVisible$ | async,
          notifications:      _notificationsFacade.notifications$ | async,
          isStudentSelected$: _notificationsFacade.isStudentSelected$ | async,
          student:            _studentFacade.student$ | async,
          isStudent:          _authfacade.isStudent$ | async
        } as state">
    <button mat-icon-button class="notification__btn" aria-label="Notifications bell icon"
            (click)="toggleNotification($event)">
      <mat-icon [matBadge]="state.count"
                [matBadgeHidden]="!state.count"
                matBadgeColor="warn">notifications
      </mat-icon>
    </button>
    <ng-container *ngIf="state.isVisible">
      <notifications @showHide
                     [notifications]="state.notifications"
                     [hasNotifications]="state.isStudentSelected$"
                     [student]="state.student"
                     [isStudent]="state.isStudent"
                     (close)="toggleNotification($event)"
                     class="notification__popup"></notifications>
    </ng-container>
  </ng-container>
</div>
