import { StudentGradeLevel } from '@app/core/models/student-grade-level.model';

export const STUDENT_GRADE_LEVEL: StudentGradeLevel[] = [
  { level: 'ALL' },
  { level: 'K' },
  { level: '1' },
  { level: '2' },
  { level: '3' },
  { level: '4' },
  { level: '5' },
  { level: '6' },
  { level: '7' },
  { level: '8' },
  { level: '9' },
  { level: '10' },
  { level: '11' },
  { level: '12' },
];
