import {Leaderboard} from '@coach-bot/data-access/leaderboards';
import {QuicksightEmbeddedDashboardModel} from '@coach-bot/data-access/core';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as LeaderboardActions from './leaderboard.actions';

export const LEADERBOARD_FEATURE_KEY = 'leaderboard';

export interface State extends EntityState<Leaderboard> {
  embeddedLeaderboard?: QuicksightEmbeddedDashboardModel;
  error?: string | null;
  loaded: boolean;
  selectedLeaderboardId?: string | number;
}

export interface LeaderboardPartialState {
  readonly [LEADERBOARD_FEATURE_KEY]: State;
}

export const leaderboardAdapter: EntityAdapter<Leaderboard> = createEntityAdapter<Leaderboard>();

export const initialState: State = leaderboardAdapter.getInitialState({
  // set initial required properties
  loaded: false
});

const leaderboardReducer = createReducer(initialState,

  on(LeaderboardActions.init, (state) => ({
    ...state, loaded: false, error: null
  })),

  on(LeaderboardActions.loadLeaderboardListSuccess, (state, {leaderboards}) => leaderboardAdapter.setAll(leaderboards, {
    ...state, error: null
  })),

  on(LeaderboardActions.loadLeaderboardListFailure, (state, {error}) => ({
    ...state, error
  })),

  on(LeaderboardActions.selectLeaderBoard, (state, {id}) => ({
    ...state, loaded: false, selectedLeaderboardId: id,
  })),

  on(LeaderboardActions.loadLeaderboardSuccess,
    (state, {embeddedLeaderboard}) => ({...state, error: null, loaded: true, embeddedLeaderboard})),

  on(LeaderboardActions.loadLeaderboardFailure, (state, {error}) => ({
    ...state, error, loaded: true
  })));

export function reducer(state: State | undefined, action: Action) {
  return leaderboardReducer(state, action);
}
