import {JsonProperty} from 'json-object-mapper';

const ASSETS_PATH = 'assets/img/leaderboards/types';

export class Leaderboard {
  @JsonProperty() public additionalDataHeader?: string;
  @JsonProperty() public dataLastUpdatedAt?: string;
  @JsonProperty() public description?: string;
  @JsonProperty() public id: string;
  @JsonProperty() public image?: string;
  /** Used for routes */
  @JsonProperty() public key: string;
  @JsonProperty() public metricHeader: string;
  @JsonProperty() public name: string;
  @JsonProperty() public quicksightDashboard?: string;
  @JsonProperty() public shortName: string;

  constructor() {
    this.id                   = '';
    this.key                  = '';
    this.name                 = '';
    this.description          = '';
    this.shortName            = '';
    this.image                = '';
    this.quicksightDashboard  = '';
    this.metricHeader         = '';
    this.additionalDataHeader = '';
    this.dataLastUpdatedAt    = '';
  }

  public get icon() {
    return `${ASSETS_PATH}/${this.key}.svg`;
  }
}
