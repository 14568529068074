import {NotificationType} from '@app/modules/notifications/models/notification-type';
import {JsonProperty} from 'json-object-mapper';

const INSIGHT_URL = 'assets/notification/icon/insight.svg';
const GT_ICON_URL = 'assets/icons/gt-icon.svg';

export class NotificationModel {
  @JsonProperty() public createdOn: Date | string;
  @JsonProperty() public id: string;
  @JsonProperty() public isRead: boolean;
  @JsonProperty() public message: string;
  @JsonProperty() public notificationType: NotificationType;
  @JsonProperty() public studentId: string;
  @JsonProperty() public title: string;

  public constructor() {
    this.message          = null;
    this.title            = null;
    this.createdOn        = null;
    this.isRead           = false;
    this.id               = null;
    this.studentId        = null;
    this.notificationType = null;
  }

  /** Returns notification image url */
  public get image(): string {
    return this.notificationType === NotificationType.AppInsight ?
      INSIGHT_URL : GT_ICON_URL;
  }
}
