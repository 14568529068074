import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { API } from '@aws-amplify/api';
import Amplify from '@aws-amplify/core';
import 'hammerjs';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { BrowserTracing } from '@sentry/tracing';

import { environment } from './environments/environment.develop';
import * as version from './version.json';

Sentry.init({
  dsn: environment.sentryID,
  environment: environment.mode,
  release: version.version,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', environment.productUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  tracesSampleRate: 1.0,
});

Amplify.configure(environment);
API.configure(environment);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
