import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {WindowRefService} from '@app/core/services/window.service';
import AuthenticationFacade from '@app/core/facades/authentication.facade';
import {CUSTOM_FIELD_VALUES} from './field-values';
import {JIRA_COLLECTOR_SCRIPT} from './jira-collector-script';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector:        'app-feedback',
  styleUrls:       ['./feedback.component.scss'],
  templateUrl:     './feedback.component.html'
})
export class FeedbackComponent implements OnInit, OnDestroy {
  private readonly destroy: Subject<null> = new Subject<null>();
  @Input() public text: string;

  public userEmail: string;
  public userName: string;
  public isLoggedIn: boolean;

  public constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _auth: AuthenticationFacade,
    private readonly _window: WindowRefService
  ) {
    this.text = 'FEEDBACK';
  }

  public ngOnInit(): void {
    this.intializeJiraCollector();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public showCollectorDialog(): void {
    this._window.nativeWindow.showCollectorDialog();
  }

  private getValues(): {[key: string]: string | string[]} {
    if (this.isLoggedIn) {
      return {
        ...CUSTOM_FIELD_VALUES,
        fullname: this.userName,
        email:    this.userEmail
      };
    } else {
      return CUSTOM_FIELD_VALUES;
    }
  }

  private intializeJiraCollector(): void {
    this._auth.isLoggedIn$.pipe(takeUntil(this.destroy)).subscribe(data => {
      this.isLoggedIn = data;
      if (this.isLoggedIn) {
        this._auth.userDetails$.pipe(takeUntil(this.destroy)).subscribe(userDetails => {
          this.userName  = `${userDetails.firstName} ${userDetails.lastName}`;
          this.userEmail = userDetails.email;
          this._initJiraCollector();
        });
      } else {
        this._initJiraCollector();
      }
    });
  }

  private _initJiraCollector(): void {
    const script = this._renderer.createElement('script');
    script.src   = JIRA_COLLECTOR_SCRIPT;
    this._renderer.appendChild(this._host.nativeElement, script);

    this._window.nativeWindow.ATL_JQ_PAGE_PROPS = {
      fieldValues:     this.getValues(),
      triggerFunction: showCollectorDialog => this._window.nativeWindow.showCollectorDialog = showCollectorDialog
    };
  }
}
