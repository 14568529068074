import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

import {FeedbackComponent} from './feedback.component';

@NgModule({
  declarations: [FeedbackComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [FeedbackComponent],
})
export class FeedbackModule {}
