export const GET_SKILLS = `
query GetSkillsCourses($studentId: String!, $excludeNotCovered: Boolean, $startGrade: Int) {
  getSkillsCourses(studentId: $studentId, excludeNotCovered: $excludeNotCovered, startGrade: $startGrade) {
      description,
      grade,
      order,
      masteryExternalObjectives {
        name
      }
      masteryPercentage
      name
      courseStatus
      standards {
        externalId,
        description,
        domainName,
        domainAlias,
        learningResources {
          name,
          url,
          learningAppName,
          type,
          order,
        }
        masteryStatus,
        isSelfAssessed,
      }
      testUrl
  }
}`;
