import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export default class SubHeaderWidgetsState {
  get widgets(): {[key: string]: TemplateRef<any> | undefined} {
    return this._widgets;
  }
  set widgets(widgets: {[key: string]: TemplateRef<any> | undefined}) {
    this._widgets = widgets;
  }
  private _widgets: {[key: string]: any} = {}

  get(token: string): TemplateRef<any> | undefined {
    return this._widgets[token];
  }

  register(token: string, tpl?: TemplateRef<any> | undefined): void {
    this._widgets[token] = tpl;
  }

  unRegister(token: string): void {
    delete this._widgets[token];
  }
}
