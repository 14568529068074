import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {ChartDataInput} from '@app/core/models/chart-data-input.model';
import {ChartData} from '@app/core/models/chart-data.model';
import {ChartType} from '@app/core/models/chart-type.model';
import {DashboardActivityDataPoint} from '@app/core/models/dashboard-activity-data-point.model';
import {DashboardMapDataPoint} from '@app/core/models/dashboard-map-data-point.model';
import {DashboardProductivityDataPoint} from '@app/core/models/dashboard-productivity-data-point.model';
import {DashboardTimeDataPoint} from '@app/core/models/dashboard-time-data-point.model';
import {DataPoint} from '@app/core/models/data-point.model';

import {AppSyncHelper} from '@coach-bot/data-access/core';

import {
  GetAppActivityChartDataPointsQuery, GetMapPercentileChartDataPointsQuery, GetMinutesPerDayChartDataPointsQuery,
  GetProductivityChartDataPointsQuery
} from 'app/API.service';
import {SubjectCardTabType} from 'app/modules/dashboard/models/subject-card-tab-type.model';

import {isAfter, isBefore, isSameDay, parseISO, startOfDay} from 'date-fns';
import {
  getAppActivityChartDataPoints, getMapPercentileChartDataPoints, getMinutesPerDayChartDataPoints,
  getProductivityChartDataPoints
} from 'graphql/queries';
import {ObjectMapper} from 'json-object-mapper';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {ChartDataWebWorkerHelper} from './chart-data-web-worker.helper';

@Injectable({providedIn: 'root'})
export class ChartDataService {
  public constructor(private readonly _appSyncHelper: AppSyncHelper, private readonly _datePipe: DatePipe) {}

  /**
   * Filters the input data using a date range.
   * If there are no valid values in the resulting array, returns an empty one instead.
   *
   * @param data Original array to be filtered.
   * @param start Starting filter Date. Inclusive.
   * @param end End filter Date. Inclusive.
   * @param properties Object properties present in the raGw data point. Used to check for valid values.
   *
   * @returns A filtered array.
   */
  public filterData(start: Date, end: Date, data: ChartData[] = [], properties: string[] = []): ChartData[] {
    if (!properties.length) {
      return [];
    }

    const startDate = startOfDay(start);
    const endDate   = startOfDay(end);

    const result = data.filter(value => {
      const time = parseISO(value.name);
      return (isSameDay(time, startDate) || isAfter(time, startDate)) && isBefore(time, endDate);
    });

    return result.some(v1 => v1.series.some(v2 => {
      if (!v2.raw) {
        return false;
      }

      if (Array.isArray(v2.raw)) {
        return properties.some(property => {
          const raw = v2.raw as DataPoint[];
          return raw.some((value: DataPoint) => value[property] !== null && value[property] !== undefined);
        });
      } else {
        return properties.some(property => {
          const raw = v2.raw as DataPoint;
          return raw[property] !== null && raw[property] !== undefined;
        });
      }
    })) ? result : [];
  }

  public getActivity(studentId: string, subjectId: string): Observable<ChartData[][]> {
    if (!studentId || !subjectId) {
      return of([]);
    }

    return this._appSyncHelper
      .query<GetAppActivityChartDataPointsQuery, ChartDataInput>(getAppActivityChartDataPoints, {studentId, subjectId})
      .pipe(switchMap(activity => {
        const worker    = new ChartDataWebWorkerHelper();
        const _activity = ObjectMapper.deserializeArray(DashboardActivityDataPoint,
          activity.getAppActivityChartDataPoints);

        worker.post({
          activity: _activity, type: SubjectCardTabType.Activity
        });
        return worker.data$;
      }), catchError(() => of(null)));
  }

  public getDataProperties(type: ChartType): string[] {
    switch (type) {
      case ChartType.PassedActivity:
      case ChartType.TotalActivity: {
        return ['activityUnitsCorrect'];
      }

      case ChartType.Productivity: {
        return ['totalRelativeProductivity'];
      }

      case ChartType.Progress: {
        return ['topicLevelsPassed'];
      }

      case ChartType.Time: {
        return ['minutesAtHome', 'minutesAtSchool'];
      }

      default: {
        return [];
      }
    }
  }

  public getMap(studentId: string, subjectId: string): Observable<ChartData[]> {
    if (!studentId || !subjectId) {
      return of([]);
    }

    return this._appSyncHelper
      .query<GetMapPercentileChartDataPointsQuery, ChartDataInput>(getMapPercentileChartDataPoints, {
        studentId, subjectId
      })
      .pipe(
        map(response => ObjectMapper.deserializeArray(DashboardMapDataPoint, response.getMapPercentileChartDataPoints)),
        map(points => {
          if (!points?.length) {
            return [];
          }

          return [{
            name: 'Benchmark Results', series: points.map(value => {
              const date = new Date(value.date);
              const year = this._datePipe.transform(date, 'yy');

              return {
                name: `${value.termSeason} '${year}`, raw: value, value: value.percentile || 0
              };
            })
          }];
        }), catchError(() => of(null)));
  }

  public getProgress(studentId: string, subjectId: string): Observable<ChartData[][]> {
    if (!studentId || !subjectId) {
      return of([]);
    }

    return forkJoin([this._appSyncHelper
      .query<GetAppActivityChartDataPointsQuery, ChartDataInput>(getAppActivityChartDataPoints, {
        studentId, subjectId
      }), this._appSyncHelper
      .query<GetProductivityChartDataPointsQuery, ChartDataInput>(getProductivityChartDataPoints, {
        studentId, subjectId
      })]).pipe(switchMap(([activity, productivity]) => {
      const worker        = new ChartDataWebWorkerHelper();
      const _activity     = ObjectMapper.deserializeArray(DashboardActivityDataPoint,
        activity.getAppActivityChartDataPoints);
      const _productivity = ObjectMapper.deserializeArray(DashboardProductivityDataPoint,
        productivity.getProductivityChartDataPoints);

      worker.post({
        activity: _activity, productivity: _productivity, type: SubjectCardTabType.Progress
      });

      return worker.data$;
    }), catchError(() => of(null)));
  }

  public getTime(studentId: string, subjectId: string): Observable<ChartData[]> {
    if (!studentId || !subjectId) {
      return of([]);
    }

    return this._appSyncHelper
      .query<GetMinutesPerDayChartDataPointsQuery, ChartDataInput>(getMinutesPerDayChartDataPoints,
        {studentId, subjectId})
      .pipe(map(
        response => ObjectMapper.deserializeArray(DashboardTimeDataPoint, response.getMinutesPerDayChartDataPoints)),
        switchMap(data => {
          const worker = new ChartDataWebWorkerHelper();
          worker.post({time: data, type: SubjectCardTabType.Time});
          return worker.data$;
        }), catchError(() => of(null)));
  }
}
