import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StorageHelper } from 'app/core/aws/storage.helper';
import { AVATAR } from 'app/core/config/defaults.config';
import AuthenticationFacade from 'app/core/facades/authentication.facade';
import StudentFacade from 'app/core/facades/student.facade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { ImageUploaderService } from '../../services';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent {
  public isAdmin$: Observable<boolean>;
  public isStudent$: Observable<boolean>;
  public isGuideOrAdmin$: Observable<boolean>;
  public isViewSkillsStackAccess$: Observable<boolean>;
  public isGuide$: Observable<boolean>;
  public image$: Observable<string>;
  public background$: Observable<string>;
  public isNotBlockHostname$: Observable<boolean>;

  constructor(
    protected readonly _auth: AuthenticationFacade,
    protected readonly _dialog: MatDialog,
    protected readonly _imageUploaderService: ImageUploaderService,
    protected readonly _storage: StorageHelper,
    protected readonly _student: StudentFacade
  ) {
    this.isAdmin$ = this._auth.isAdmin$;
    this.isStudent$ = this._auth.isStudent$;
    this.isViewSkillsStackAccess$ = this._auth.isViewSkillsStackAccess$;
    this.isGuideOrAdmin$ = this.isStudent$.pipe(map((student) => student === false));
    this.isGuide$ = this._auth.isGuide$;
    this.image$ = this._auth.userDetails$.pipe(map((details) => details.profileImageURL || AVATAR));
    this.background$ = this.image$.pipe(map((image) => `url(${image})`));
  }

  public logout(): void {
    this._auth.logout();
  }

  public openImageUploader(image: string): void {
    this._imageUploaderService.openImageUploader(image);
  }
}
