import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { STORAGE_KEY } from '@app/core/config/app.config';
import { ErrorService } from '@app/core/services/error.service';

import { CloudWatchLogs, PutLogEventsRequest } from '@aws-sdk/client-cloudwatch-logs';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

import { AuthenticationService } from '@coach-bot/data-access/auth';
import { DURATION } from 'app/core/config/snack-bar.config';
import { RapidLoggerError } from 'app/rapid-logger/rapid-logger-error.model';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as app from 'package.json';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment.develop';

const prefix = '/app';
const retention = 30;
const region = 'us-east-1';
const identityPoolId = 'us-east-1:2e997bdc-bd8b-4935-96a3-d9dae83ebe1a';

@Injectable({ providedIn: 'root' })
export class RapidLoggerService implements ErrorHandler {
  client: CloudWatchLogs;
  errors: RapidLoggerError[];
  group = `${app.name}-logger`;
  groupName: string;
  groupReady: boolean;
  production = environment.production;
  recordingURL: string;
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  user: any;

  constructor(
    private readonly authService: AuthenticationService,
    private deviceChecker: DeviceDetectorService,
    private errorService: ErrorService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {
    this.client = new CloudWatchLogs({
      region,
      credentials: fromCognitoIdentityPool({
        identityPoolId,
        client: new CognitoIdentityClient({ region }),
      }),
    });

    this.errors = [];
    this.groupName = `${prefix}/${this.group}`;
    this.groupReady = false;
    this.initializeCloudResources(this.groupName);

    this.authService.user$.pipe(take(1)).subscribe((user) => (this.user = user));
  }

  async handleError(error: Error, showWarning: boolean = true): Promise<void> {
    if (environment.production) {
      this.errors.push({ stream: this.router.url, message: error.stack });

      if (this.groupReady && this.recordingURL) {
        await this.processErrorQueue(showWarning);
      }
    } else {
      // Need to show console errors for develop environment
      console.error(error);
    }
  }

  async handleInfo(infoLog: string): Promise<void> {
    if (environment.production) {
      await this.putLogEvent(infoLog, this.router.url);
    } else {
      // Need to show console errors for develop environment
      console.info(infoLog);
    }
  }

  private async initializeCloudResources(group: string) {
    if (environment.production) {
      const groups = await this.client.describeLogGroups({ logGroupNamePrefix: group });

      if (groups && groups.logGroups.every((item) => item.logGroupName !== group)) {
        await this.client.createLogGroup({ logGroupName: group });
        await this.client.putRetentionPolicy({ logGroupName: group, retentionInDays: retention });
      }

      this.groupReady = true;
    }
  }

  private async processErrorQueue(showWarning?: boolean) {
    for (const error of this.errors) {
      const user = this.user?.attributes;
      if (user) {
        const [role] = this.user.signInUserSession.idToken.payload['cognito:groups'];

        const message = {
          user: {
            userId: user['custom:userID'],
            firstName: user.name,
            lastName: user.family_name,
            email: user.email,
            userRole: role,
          },
          appVersion: localStorage.getItem(STORAGE_KEY.version),
          deviceInformation: {
            os: this.deviceChecker.getDeviceInfo().os,
            device: this.deviceChecker.getDeviceInfo().device,
            os_version: this.deviceChecker.getDeviceInfo().os_version,
            deviceType: this.deviceChecker.getDeviceInfo().deviceType,
          },
          browserInformation: {
            userAgent: this.deviceChecker.getDeviceInfo().userAgent,
            browser: this.deviceChecker.getDeviceInfo().browser,
            browser_version: this.deviceChecker.getDeviceInfo().browser_version,
            windowWidth: `${window.innerWidth}px`,
          },
        };

        await this.putLogEvent(JSON.stringify(message), error.stream);
      }

      const snackBarMessage = this.errorService.getStaticMessage();

      if (showWarning) {
        this.zone.run(() => {
          this.snackBarRef = this.snackBar.open(snackBarMessage, 'X', {
            panelClass: ['alert-snackbar'],
            duration: DURATION,
            verticalPosition: 'bottom',
          });

          this.snackBarRef.afterDismissed().subscribe(() => (this.snackBarRef = undefined));
        });
      }

      console.error(error);
    }

    this.errors = [];
  }

  private async putLogEvent(message: string, stream: string) {
    const payload: PutLogEventsRequest = {
      logEvents: [{ message: message, timestamp: new Date().getTime() }],
      logGroupName: this.groupName,
      logStreamName: stream,
    };
    const streams = await this.client.describeLogStreams({
      logGroupName: this.groupName,
      logStreamNamePrefix: payload.logStreamName,
    });
    if (streams && streams.logStreams.every((item) => item.logStreamName !== payload.logStreamName)) {
      await this.client.createLogStream({ logGroupName: this.groupName, logStreamName: payload.logStreamName });
      await this.client.putLogEvents(payload);
    } else {
      const stream = streams.logStreams.find((item) => (item.logStreamName = payload.logStreamName));
      payload.sequenceToken = stream.uploadSequenceToken;
      await this.client.putLogEvents(payload);
    }
  }
}
