import { Injectable } from '@angular/core';
import { AppSyncHelper } from '@coach-bot/data-access/core';

import { ObjectMapper } from 'json-object-mapper';
import { ASSIGN_DIAGNOSTIC_TEST } from '../resolvers/queries/assign-diagnostic-test.mutation';
import { CREATE_TARGETED_LEARNING_TEST } from '../resolvers/queries/create-targeted-learning-test.mutation';
import { AssignDiagnosticTestStatus } from '../enums/assign-diagnostic-test-status.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Course } from '../models/course.model';
import { GetSkillResponse } from '../models/get-skill-response.model';
import { GET_SKILLS } from '../resolvers/queries/get-skills.query';


@Injectable()
export class SkillStackService {
  constructor(private appSyncHelper: AppSyncHelper) {}

  public getSkills(studentId: string): Observable<Course[]> {
    return this.appSyncHelper
      .query<GetSkillResponse, { studentId: string; excludeNotCovered: boolean; startGrade: number }>(GET_SKILLS, {
        studentId,
        excludeNotCovered: true,
        startGrade: 5,
      })
      .pipe(map((response) => ObjectMapper.deserializeArray(Course, response.getSkillsCourses)));
  }

  public assignDiagnosticTest(studentId: string, courseName: string): Observable<{status: AssignDiagnosticTestStatus, testUrl: string}> {
    return this.appSyncHelper.mutate<{assignDiagnosticTest: {status: AssignDiagnosticTestStatus, testUrl: string, }}, {studentId: string, courseName: string}>
      (ASSIGN_DIAGNOSTIC_TEST, {studentId, courseName})
      .pipe(map((response) => response.assignDiagnosticTest));
  }

  public createTargetedLearningTest(courseName: string, studentId: string):Observable<{status: AssignDiagnosticTestStatus, testUrl: string}> {
    return this.appSyncHelper.mutate<{createTargetedLearningTest: {status: AssignDiagnosticTestStatus, testUrl: string, }}, {courseName: string, studentId: string}>
    (CREATE_TARGETED_LEARNING_TEST, {courseName, studentId})
      .pipe(map((response) => response.createTargetedLearningTest));
  }
}
