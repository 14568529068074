import {JsonProperty} from 'json-object-mapper';

export class MaintenanceModel {
  @JsonProperty() public message: string;
  @JsonProperty() public startTime: Date | string;
  @JsonProperty() public endTime: Date | string;
  @JsonProperty() public isActive: boolean;
  @JsonProperty() public hasMaintenanceAccess: boolean;

  constructor() {
    this.message = null;
    this.startTime = null;
    this.endTime = null;
    this.isActive = false;
    this.hasMaintenanceAccess = false;
  }
}
