import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';

import {ErrorService} from '@app/core/services/error.service';

import {SNACK_BAR_DURATION} from '../config/app.config';

const CHUNK_FAILED_REGEX  = /Loading chunk [\d]+ failed/;
const CHUNK_ERROR_MESSAGE = 'There is a New Version available, let\'s reload the application.';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public constructor(
    private readonly _error: ErrorService,
    private readonly _snack: MatSnackBar,
    private readonly _zone: NgZone
  ) {}

  public handleError(_error: Error | HttpErrorResponse): void {
    if(CHUNK_FAILED_REGEX.test(_error.message)) {
      alert(CHUNK_ERROR_MESSAGE);
      window.location.reload();
    }

    if(this.snackBarRef) {
      return;
    }

    this._zone.run(() => {
      this.snackBarRef = this._snack.open(this._error.getStaticMessage(), 'X', {
        panelClass:       ['alert-snackbar'],
        duration:         SNACK_BAR_DURATION,
        verticalPosition: 'bottom'
      });

      this.snackBarRef.afterDismissed().subscribe(() => {
        this.snackBarRef = null;
      });
    });
  }
}
