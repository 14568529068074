<div class="error-container">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px">
    <div>
      <div class="oops">{{errorHeader}}.</div>
      <div *ngIf="isShowErrorCode" class="error-code">{{error}}</div>
      <p class="error-text">{{errorText}}</p>
    </div>
    <img [src]="imgSrc" alt="logo" height="423" width="345">
  </div>
</div>
