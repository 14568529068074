import {Injectable} from '@angular/core';
import {STORAGE_KEY} from '@app/core/config/app.config';
import {CourseEffortEnabledApp} from '@app/core/models/course-effort-enabled-app.model';
import {CourseType} from '@app/core/models/course-type.model';
import {Course} from '@app/core/models/course.model';
import {gradeLevelSorting} from '@app/shared/utilities/sort.utility';

import {AppSyncHelper} from '@coach-bot/data-access/core';

import {GetCourseEffortEnabledAppsQuery, ListLearningAppsQuery} from 'app/API.service';
import {getCourseEffortEnabledApps, listLearningApps} from 'graphql/queries';

import {ObjectMapper} from 'json-object-mapper';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, take, tap} from 'rxjs/operators';

@Injectable()
export class NonIntegratedAppService {
  public allApps$: Observable<ListLearningAppsQuery['listLearningApps']>;
  public appsRaw$: Observable<GetCourseEffortEnabledAppsQuery['getCourseEffortEnabledApps']>;
  public course$: Observable<Course>;
  public courseId$: Observable<string>;
  public courses$: Observable<Course[]>;
  public coursesFiltered$: Observable<Course[]>;
  public nonIntegratedApp$: Observable<ListLearningAppsQuery['listLearningApps'][number]>;
  public nonIntegratedAppId$: Observable<string>;

  private readonly _allAppsRaw: BehaviorSubject<ListLearningAppsQuery['listLearningApps']>;
  private readonly _appsRaw: BehaviorSubject<GetCourseEffortEnabledAppsQuery['getCourseEffortEnabledApps']>;
  private readonly _courseId: BehaviorSubject<string>;
  private readonly _nonIntegratedAppId: BehaviorSubject<string>;

  public constructor(private readonly _appSync: AppSyncHelper) {
    this._appsRaw            = new BehaviorSubject([]);
    this._allAppsRaw         = new BehaviorSubject([]);
    this._nonIntegratedAppId = new BehaviorSubject<string>(localStorage.getItem(STORAGE_KEY.nonIntegratedAppId));
    this._courseId           = new BehaviorSubject(localStorage.getItem(STORAGE_KEY.courseId));

    this.allApps$ = this._allAppsRaw.asObservable().pipe(tap(apps => {
      if (!apps.length) {
        this.getAllApps();
      }
    }), filter(apps => Boolean(apps.length)), distinctUntilChanged(), shareReplay(1));

    this.appsRaw$ = this._appsRaw.asObservable().pipe(tap(apps => {
      if (!apps.length) {
        this.list();
      }
    }), filter(apps => Boolean(apps.length)), distinctUntilChanged(), shareReplay(1));

    this.nonIntegratedAppId$ = combineLatest([this._nonIntegratedAppId, this.allApps$]).pipe(
      map(([id, apps]) => (apps.some(app => app.id === id) ? id : apps[0].id)), distinctUntilChanged());

    this.nonIntegratedApp$ = combineLatest([this.nonIntegratedAppId$, this.allApps$])
      .pipe(map(([id, apps]) => apps.find(app => app.id === id)));

    this.courses$ = this.appsRaw$.pipe(map(apps => {
      return apps
        .map(item => ({
            id: item.courseId || item.topicId, appId: item.learningAppId, name: item.courseName || item.topicName,
            subjectId: item.subjectId, type: item.courseId ? CourseType.Course : CourseType.Topic
          }))
        .sort(gradeLevelSorting);
    }), distinctUntilChanged());

    this.coursesFiltered$ = combineLatest([this.courses$, this.nonIntegratedAppId$]).pipe(
      map(([courses, id]) => courses.filter(value => value.appId === id)));

    this.courseId$ = combineLatest([this._courseId, this.coursesFiltered$]).pipe(map(([id, courses]) => {
      if (!courses.length) {
        return null;
      }

      return courses.some(c => c.id === id) ? id : courses[0].id;
    }), distinctUntilChanged());

    this.course$ = combineLatest([this.courseId$, this.coursesFiltered$]).pipe(
      map(([id, courses]) => courses ? courses.find(course => course.id === id) || null : null));
  }

  public getAllApps(): void {
    this._appSync.query(listLearningApps, null)
      .pipe(take(1), map((response: ListLearningAppsQuery) => response.listLearningApps)).subscribe(
      allApps => this.updateAllAppsRaw(allApps));
  }

  public getAllCourses(): Observable<CourseEffortEnabledApp[]> {
    return this._appSync.query(getCourseEffortEnabledApps, '')
      .pipe(map((response: GetCourseEffortEnabledAppsQuery) => ObjectMapper.deserializeArray(CourseEffortEnabledApp,
        response.getCourseEffortEnabledApps)));
  }

  public getNotIntegratedApps(): Observable<ListLearningAppsQuery> {
    return this._appSync.query(listLearningApps, '');
  }

  public list(): void {
    this._appSync
      .query(getCourseEffortEnabledApps, null)
      .pipe(take(1), map((response: GetCourseEffortEnabledAppsQuery) => response.getCourseEffortEnabledApps))
      .subscribe(apps => {
        this.updateAppsRaw(apps);
      });
  }

  public updateAllAppsRaw(apps: ListLearningAppsQuery['listLearningApps']): void {
    /* sort apps alphabetically by name */
    const sortedApps = [...apps].sort(
      (a, b) => ((a.learningAppName).toLowerCase() > (b.learningAppName).toLowerCase()) ? 1 : -1);
    this._allAppsRaw.next(sortedApps);
  }

  public updateAppId(id: string): void {
    this._nonIntegratedAppId.next(id);
    localStorage.setItem(STORAGE_KEY.nonIntegratedAppId, id);
  }

  public updateAppsRaw(apps: GetCourseEffortEnabledAppsQuery['getCourseEffortEnabledApps']): void {
    this._appsRaw.next([...apps]);
  }

  public updateCourseId(id: string): void {
    this._courseId.next(id);
    localStorage.setItem(STORAGE_KEY.courseId, id);
  }
}
