import {Injectable} from '@angular/core';

import {RapidLoggerService} from 'app/rapid-logger/rapid-logger.service';

@Injectable({providedIn: 'root'})
export default class RapidLoggerFacade {
  static instance: RapidLoggerFacade;

  constructor(private readonly _rapidLogger: RapidLoggerService) {
    RapidLoggerFacade.instance = this;
  }

  handleError(error: Error, showWarning = true): Promise<void> {
    return this._rapidLogger.handleError(error, showWarning);
  }

  handleInfo(infoLog: string): Promise<void> {
    return this._rapidLogger.handleInfo(infoLog);
  }
}
