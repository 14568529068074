<button [style.background-image]="background"
        class="profile"
        #profileMenu
        data-private>
    <kendo-contextmenu #profilePopup [alignToAnchor]="true" [target]="profileMenu"
                       [popupAlign]="{horizontal: 'center', vertical: 'top'}"
                       showOn="click">
    <ng-template kendoContextMenuTemplate>
      <div (click)="onUpdatePhoto()" class="k-p-3 k-cursor-pointer">
        Update Photo
      </div>
      <div (click)="onLogout()" class="k-p-3 k-cursor-pointer">
        Logout
      </div>
    </ng-template>
  </kendo-contextmenu>
</button>
