import {Leaderboard} from '@coach-bot/data-access/leaderboards';
import {QuicksightEmbeddedDashboardModel} from '@coach-bot/data-access/core';
import {createAction, props} from '@ngrx/store';

export const init = createAction('[Leaderboard Page] Init');

export const loadLeaderboardSuccess = createAction('[Leaderboard/API] Load Leaderboard Success',
  props<{ embeddedLeaderboard: QuicksightEmbeddedDashboardModel }>());

export const loadLeaderboardFailure = createAction('[Leaderboard/API] Load Leaderboard Failure',
  props<{ error: string }>());

export const loadLeaderboardListSuccess = createAction('[Leaderboard/API] Load Leaderboard List Success',
  props<{ leaderboards: Leaderboard[] }>());

export const loadLeaderboardListFailure = createAction('[Leaderboard/API] Load Leaderboard List Failure',
  props<{ error: string }>());

export const selectLeaderBoard = createAction('[Leaderboard/PAGE] Set Leaderboard as selected',
  props<{ id: string, studentId?: string }>());
