import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';

@Component({
  selector: 'cb-profile', templateUrl: './coach-bot-profile.component.html',
  styleUrls: ['./coach-bot-profile.component.scss'], changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoachBotProfileComponent {
  @ViewChild('profilePopup') profilePopup: ContextMenuComponent;

  @Input() public background?: string;
  @Input() public image?: string;

  @Output() public logout      = new EventEmitter();
  @Output() public uploadPhoto = new EventEmitter<string>();

  public onUpdatePhoto(): void {
    this.profilePopup.hide();
    this.uploadPhoto.emit(this.image)
  }

  public onLogout(): void {
    this.profilePopup.hide();
    this.logout.emit();
  }
}
