import {JsonProperty} from 'json-object-mapper';

export class UserDetails {
  @JsonProperty() public id: string;
  @JsonProperty() public firstName: string;
  @JsonProperty() public lastName: string;
  @JsonProperty() public preferredName: string;
  @JsonProperty() public email: string;
  @JsonProperty() public roleName: string;
  @JsonProperty() public profileImageURL?: string | null;
  @JsonProperty() public coverImageURL?: string | null;
  @JsonProperty() public acceptedTerms?: boolean | null;
  @JsonProperty() public permissions: string[];

  constructor() {
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.preferredName = null;
    this.email = null;
    this.roleName = null;
    this.profileImageURL = null;
    this.coverImageURL = null;
    this.acceptedTerms = null;
    this.permissions = [];
  }
}
