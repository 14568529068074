import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {Injectable, TemplateRef, ViewContainerRef} from '@angular/core';

@Injectable()
export class TooltipService<T> {
  overlayRef: OverlayRef;

  constructor(private readonly _overlay: Overlay) {}

  hideTooltip() {
    if (!this.overlayRef) {
      return;
    }

    this.overlayRef.dispose();
  }

  showTooltip(bar: HTMLElement, data: T, wrapper: TemplateRef<null>, container: ViewContainerRef, offsetY = 0) {
    const half = 2;
    const arrowSize = 7;
    const rect = bar.getBoundingClientRect();
    const point = { x: (rect.left + rect.right) / half, y: rect.top };

    this.overlayRef = this._overlay.create({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(point)
        .withPositions([
          {
            offsetY: -arrowSize - offsetY,
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ]),
    });

    this.overlayRef.attach(new TemplatePortal(wrapper, container, { data }));
  }
}
