import {MasteryStatus} from '../enums/mastery-status.enum';

export const MasteryStatusTextConfig: {[key: string]: string} = {
  [MasteryStatus.Mastered]: 'Mastered',
  [MasteryStatus.NotMastered]: 'Not Mastered',
  [MasteryStatus.NotCovered]: 'Unknown',
  [MasteryStatus.NotEvaluated]: 'Not Evaluated',
  [MasteryStatus.Proficient]: 'Proficient',
  [MasteryStatus.NotProficient]: 'Not Proficient',
  [MasteryStatus.NotFullyMastered]: 'Not Fully Mastered'
}
