export const USER_COVER_IMAGE_UPDATE = /* GraphQL */ `
  mutation UpdateUserCoverImage($userDetails: UpdateUserCoverImageInput!) {
    updateUserCoverImage(userDetails: $userDetails) {
      id
      firstName
      lastName
      email
      roleName
      profileImageURL
      coverImageURL
      acceptedTerms
    }
  }
`;
