import {DashboardActivityDataPoint} from '@app/core/models/dashboard-activity-data-point.model';
import {DashboardProductivityDataPoint} from '@app/core/models/dashboard-productivity-data-point.model';
import {DashboardTimeDataPoint} from '@app/core/models/dashboard-time-data-point.model';

import {JsonProperty} from 'json-object-mapper';

export class DashboardSubject {
  @JsonProperty() public savedMAPPercentile: number;
  @JsonProperty() public goalMAPPercentile: number;
  @JsonProperty() public subjectName: string;
  @JsonProperty() public subjectId: string;
  @JsonProperty() public lastYearMAPPercentile: number;
  @JsonProperty() public minutesAtHome: number;
  @JsonProperty() public minutesAtSchool: number;
  @JsonProperty() public goalMinutesPerDay?: number;
  @JsonProperty() public goalProductiveTime?: number;
  @JsonProperty() public estimatedGoalDate?: string;
  @JsonProperty() public isMAPSubject: boolean;

  @JsonProperty({
    name: 'minutesPerDayDataPoints',
    type: DashboardTimeDataPoint
  }) public minutesPerDayDataPoints: DashboardTimeDataPoint[];

  @JsonProperty({
    name: 'productivityDataPoints',
    type: DashboardProductivityDataPoint
  }) public productivityDataPoints: DashboardProductivityDataPoint[];

  @JsonProperty({
    name: 'appActivityDataPoints',
    type: DashboardActivityDataPoint
  }) public appActivityDataPoints: DashboardActivityDataPoint[];

  public constructor() {
    this.appActivityDataPoints   = [];
    this.estimatedGoalDate       = null;
    this.goalMAPPercentile       = null;
    this.goalMinutesPerDay       = null;
    this.goalProductiveTime      = null;
    this.isMAPSubject            = null;
    this.lastYearMAPPercentile   = null;
    this.minutesAtHome           = null;
    this.minutesAtSchool         = null;
    this.minutesPerDayDataPoints = [];
    this.productivityDataPoints  = [];
    this.savedMAPPercentile      = null;
    this.subjectId               = null;
    this.subjectName             = null;
  }
}
