import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_URL, STORAGE_KEY } from '@app/core/config/app.config';
import { MaintenanceModel } from '@app/core/models/maintenance.model';
import { Role } from '@app/core/models/role.model';
import { User } from '@app/core/models/user.model';
import { SIGN_UP } from '@app/core/resolvers/mutations/sign-up.mutation';
import { SOFT_DELETE_USER } from '@app/core/resolvers/mutations/soft-delete-user.mutation';
import { UPDATE_USER_DETAILS } from '@app/core/resolvers/mutations/update-user-details.mutation';
import { USER_COVER_IMAGE_UPDATE } from '@app/core/resolvers/mutations/user-cover-image-update.mutation';
import { USER_PROFILE_IMAGE_UPDATE } from '@app/core/resolvers/mutations/user-profile-image-update.mutation';
import { USER_DETAILS } from '@app/core/resolvers/queries/user-details.query';
import { MaintenanceService } from '@app/core/services/maintenance/maintenance.service';
import * as Sentry from '@sentry/angular';

import { Auth } from '@aws-amplify/auth';

import { AppSyncHelper } from '@coach-bot/data-access/core';
import { CURRENT_LANGUAGE_LOCAL_STORAGE_KEY } from '@coach-bot/shared/translate';
import { getEnumValues, withRetry, withRetryPromise } from '@coach-bot/shared/util';

import {
  GetUserDetailsQuery,
  GetUserDetailsQueryVariables,
  UpdateUserCoverImageInput,
  UpdateUserDetailsInput,
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables,
  UpdateUserProfileImageInput,
} from 'app/API.service';
import { Permission } from 'app/core/models/permission.enum';
import { UserDetails } from 'app/core/models/user-details.model';
import { ObjectMapper } from 'json-object-mapper';
import { BehaviorSubject, combineLatest, defer, EMPTY, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { SignUpData } from '../interfaces';

export enum AuthErrorCode {
  NotAllowedException = 'NotAllowedException',
  NotAuthorizedException = 'NotAuthorizedException',
  UserDisabledException = 'UserDisabledException',
  UserNotFoundException = 'UserNotFoundException',
}

const AUTH_ERROR_CODES: AuthErrorCode[] = getEnumValues(AuthErrorCode);
const STORAGE_KEYS_TO_KEEP = [
  CURRENT_LANGUAGE_LOCAL_STORAGE_KEY,
  STORAGE_KEY.signUpEmail,
  STORAGE_KEY.signUpProgramEnrollmentsData,
];

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public error = new Subject<string>();
  public isAdmin$: Observable<boolean>;
  public isGuide$: Observable<boolean>;
  public isLoggedIn$: Observable<boolean>;
  public isSessionCreated$ = new BehaviorSubject<boolean>(false);
  public isStudent$: Observable<boolean>;
  public isViewSkillsStackAccess$: Observable<boolean>;
  public isFullReportAccess$: Observable<boolean>;
  public isReducedFullReport$: Observable<boolean>;
  public isViewLearningLossReportAccess$: Observable<boolean>;
  public maintenance = new Subject<MaintenanceModel>();
  public openModal: BehaviorSubject<boolean | null>;
  public redirectUrl = '';
  public resetPasswordError = new Subject<string>();
  public resetPasswordSuccessfully = new Subject<boolean>();
  public user$: Observable<User | null>;
  public userDetails$: Observable<UserDetails | null>;

  private readonly _details: BehaviorSubject<UserDetails | null>;
  private readonly _user: Subject<User | null>;

  public constructor(
    private readonly _appSync: AppSyncHelper,
    private readonly _router: Router,
    private readonly _maintenance: MaintenanceService
  ) {
    this.openModal = new BehaviorSubject<boolean | null>(null);
    this._user = new ReplaySubject();

    Auth.currentSession()
      .then(() => {
        this.updateUser(JSON.parse(localStorage.getItem(STORAGE_KEY.user) as string) as User);
      })
      .catch(() => {
        this.updateUser(null);
      });

    this._details = new BehaviorSubject<UserDetails | null>(null);

    this.user$ = this._user.asObservable().pipe(
      filter((user) => Boolean(user)),
      distinctUntilChanged()
    );

    this.isLoggedIn$ = this._user.asObservable().pipe(map((user) => Boolean(user)));

    this.userDetails$ = combineLatest([this._details.asObservable(), this.user$]).pipe(
      tap(([details, user]) => {
        if (!details && user) {
          this.loadUserDetails(user.attributes['custom:userID']);
        }
      }),
      map(([details]) => details),
      filter((details) => Boolean(details)),
      shareReplay(1)
    );

    const groups = this._user.asObservable().pipe(
      filter((user) => {
        return Boolean(
          user &&
            user.signInUserSession &&
            user.signInUserSession.idToken &&
            user.signInUserSession.idToken.payload &&
            user.signInUserSession.idToken.payload['cognito:groups']
        );
      }),
      map((user) => (user ? user.signInUserSession.idToken.payload['cognito:groups'] : []))
    );

    this.isAdmin$ = groups.pipe(map((group) => group.includes(Role.Admin)));
    this.isGuide$ = groups.pipe(map((group) => group.includes(Role.Guide)));
    this.isStudent$ = groups.pipe(map((group) => group.includes(Role.Student)));
    this.isViewSkillsStackAccess$ = this.userDetails$.pipe(
      map(
        (details) =>
          !!details &&
          (details.permissions.includes(Permission.ViewSkillsStack) ||
            details.permissions.includes(Permission.CovidLearningLossProgram))
      )
    );
    this.isFullReportAccess$ = this.userDetails$.pipe(
      map(
        (details) =>
          !!details &&
          (details.permissions.includes(Permission.CovidLearningLossProgram) ||
            details.permissions.includes(Permission.QuickLearnAcceleratedMathProgram))
      )
    );
    this.isViewLearningLossReportAccess$ = this.userDetails$.pipe(
      map((details) => !!details && details.permissions.includes(Permission.CovidLearningLossProgram))
    );
    this.isReducedFullReport$ = this.userDetails$.pipe(
      map((details) => !!details && details.permissions.includes(Permission.QuickLearnAcceleratedMathProgram))
    );
  }

  public forgotPassword(username: string): Observable<string> {
    return defer(() => from(Auth.forgotPassword(username, { origin: window.location.origin })));
  }

  public login(
    username: string,
    password: string,
    refreshSession = false,
    sessionCreatedCallback?: (user: User) => Promise<void>
  ): void {
    const userPromise: Promise<User | null> = Auth.currentAuthenticatedUser()
      .then((user: User) => {
        if (!user || refreshSession) {
          throw new Error('Needs to perform login');
        }
        return user;
      })
      .catch(() => withRetryPromise(this.amplifyLogin(username, password)));

    userPromise
      .then(async (user: User | null) => {
        if (!user) {
          return;
        }
        this.isSessionCreated$.next(true);
        if (sessionCreatedCallback) {
          await sessionCreatedCallback(user);
        }

        const userId = user.signInUserSession.idToken.payload['custom:userID'];

        withRetry(this._maintenance.getMaintenanceStatusByUserId(userId))
          .pipe(
            switchMap((status) => {
              localStorage.setItem(STORAGE_KEY.clientId, userId);
              this.maintenance.next(status);

              return !status.isActive || status.hasMaintenanceAccess ? withRetry(this.getUserDetails(userId)) : EMPTY;
            }),
            switchMap((data) => {
              if (data.acceptedTerms) {
                this.openModal.next(false);
                this.updateUser(user);
                return this.isStudent$.pipe(take(1));
              }

              this.openModal.next(true);
              return EMPTY;
            })
          )
          .subscribe({
            next: () => {
              if (location.pathname === LOGIN_URL) {
                this._router.navigateByUrl('/');
              }
            },
            error: (err) => {
              this.error.next(err.message);
              Sentry.captureException(err);
            },
          });
      })
      .catch((err) => {
        this.error.next(err.message);
        Sentry.captureException(err);
      });
  }

  public register(data: SignUpData): Observable<void> {
    return this._appSync.mutateViaApiKeyAuth<{ signUp: string }, SignUpData>(SIGN_UP, data).pipe(
      map((result) => {
        if (!result.signUp || result.signUp !== 'User created') {
          throw new Error('Failed to create user: ' + result);
        }
      }),
      catchError((error) => {
        if (error.graphQLErrors?.length > 0) {
          this.error.next(error.message);
          return of(void 0);
        }
        throw error;
      })
    );
  }

  public async resetSession(): Promise<void> {
    try {
      const user: User = await Auth.currentAuthenticatedUser();
      if (user || this.isSessionCreated$.value) {
        Auth.signOut();
        this.isSessionCreated$.next(false);
      }
    } catch {}
  }

  public logout(sessionDestroyedCallback?: () => void): void {
    Auth.signOut().then(() => {
      this.clearLocalStorage();

      this.isSessionCreated$.next(false);
      if (sessionDestroyedCallback) {
        sessionDestroyedCallback();
      }

      location.href = LOGIN_URL;
    });
  }

  public resetPassword(username: string, code: string, newPassword: string): void {
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(() => this.resetPasswordSuccessfully.next(true))
      .catch((err) => {
        this.resetPasswordError.next(err.message);
        Sentry.captureException(err);
      });
  }

  /***
   * To be used to update user cover image.
   * @param {Partial<UpdateUserProfileImageInput & UpdateUserCoverImageInput>} userDetails
   * @returns {Observable<void>}
   */
  public updateCoverImage(userDetails: UpdateUserCoverImageInput): Observable<void> {
    return this._appSync.mutate(USER_COVER_IMAGE_UPDATE, { userDetails });
  }

  public updateUser(user: User | null): void {
    this._user.next(user);

    if (user) {
      localStorage.setItem(STORAGE_KEY.user, JSON.stringify(user));
      Sentry.setUser({
        id: user.attributes['custom:userID'],
        studentId: user.attributes['custom:studentID'],
        email: user.attributes.email,
        name: `${user.attributes.name} ${user.attributes.family_name}`,
      });
    } else {
      this.clearLocalStorage();
      Sentry.setUser(null);
    }
  }

  public updateStateUserDetails(details: Partial<UserDetails>): void {
    this._details.next({ ...this._details.value, ...details } as UserDetails);
  }

  public updateUserDetails(details: UpdateUserDetailsInput): Observable<void> {
    return this._appSync
      .mutate<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>(UPDATE_USER_DETAILS, { input: details })
      .pipe(
        map(() => {}),
        tap(() => {
          const data: Partial<UpdateUserDetailsInput> = { ...details };
          delete data.userId;
          this.updateStateUserDetails(data as Partial<UserDetails>);
        })
      );
  }

  public updateUserProfileImage(userDetails: UpdateUserProfileImageInput): Observable<void> {
    return this._appSync.mutate(USER_PROFILE_IMAGE_UPDATE, { userDetails });
  }

  public deleteUser(): Observable<void> {
    return this._appSync.mutate(SOFT_DELETE_USER, {});
  }

  public getCurrentAuthenticatedUser(): Observable<User> {
    return from(Auth.currentAuthenticatedUser());
  }

  private getUserDetails(userId: string): Observable<UserDetails> {
    return this._appSync.query<GetUserDetailsQuery, GetUserDetailsQueryVariables>(USER_DETAILS, { userId }).pipe(
      take(1),
      map((response) => ObjectMapper.deserialize(UserDetails, response.getUserDetails!))
    );
  }

  private loadUserDetails(userId: string): void {
    this.getUserDetails(userId).subscribe((details) => this.updateStateUserDetails(details));
  }

  private async amplifyLogin(username: string, password: string): Promise<User | null> {
    return Auth.signIn({ password, username }).catch((err) => {
      if (err.code && AUTH_ERROR_CODES.includes(err.code)) {
        this.error.next(err.message);
        Sentry.captureException(err);
        return null;
      } else {
        throw err;
      }
    });
  }

  private clearLocalStorage(): void {
    const keys: string[] = Object.keys(localStorage);
    keys.forEach((key) => {
      if (!STORAGE_KEYS_TO_KEEP.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  }
}
