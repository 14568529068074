export enum ChartType {
  /**
   * The Activity property will be deprecated in future releases since there's no activity chart anymore.
   * Activity information has been split into PassedActivity and TotalActivity.
   * The Activity tab still exists and a new model has been created to track SubjectCardTabTypes.
   */
  Activity,
  Map,
  PassedActivity,
  Productivity,
  Progress,
  Time,
  TotalActivity,
}
