export const UPDATE_USER_DETAILS = `
  mutation updateUserDetails($input: UpdateUserDetailsInput!) {
    updateUserDetails(input: $input) {
      id
      firstName
      lastName
      preferredName
      email
      roleName
      locale
      profileImageURL
      coverImageURL
      acceptedTerms
      permissions
    }
  }
`;
