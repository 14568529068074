import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';

import * as LeaderboardActions from './leaderboard.actions';

import * as LeaderboardSelectors from './leaderboard.selectors';

@Injectable()
export class LeaderboardFacade {
  embeddedLeaderboard$ = this.store.pipe(select(LeaderboardSelectors.getEmbeddedLeaderboard));
  error$               = this.store.pipe(select(LeaderboardSelectors.error));
  hasError$            = this.error$.pipe(map(error => error != null));
  leaderboards$        = this.store.pipe(select(LeaderboardSelectors.getLeaderboards));
  loaded$              = this.store.pipe(select(LeaderboardSelectors.loaded));
  selectedLeaderboard$ = this.store.pipe(select(LeaderboardSelectors.getSelectedLeaderboard));

  constructor(private readonly store: Store) {}

  getLeaderboard(id: string, studentId: string) {
    this.store.dispatch(LeaderboardActions.selectLeaderBoard({id, studentId}));
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(LeaderboardActions.init());
  }
}
