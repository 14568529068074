import {MenuItem} from '../../models';

/** holds items for analytics dropdown used in navbar */
export const ANALYTICS_LIST: MenuItem[] = [{
  title: 'Delivery of our 2nd promise', link: '/learning-rate-quadrant-report'
}, {title: 'Learning Rate - Aggregated', link: '/learning-rate-aggregated'},
  {title: 'Learning Rate - Deep Dive', link: '/learning-rate-deep-dive'},
  {title: 'Learning Rate - % Students at 2x', link: '/learning-rate-students-at-2x'},
  {title: 'MAP Percentile vs Time at Alpha', link: '/map-percentile-vs-time'},
  {title: 'MAP Percentile Histogram', link: '/map-percentile-histogram'},
  {title: 'Effort Profile - MAP Achievement', link: '/effort-map-achievement'},
  {title: 'Effort Profile - Course Completion', link: '/effort-course-completion'},
  {title: 'Learning Tracker', link: '/learning-tracker'}];
