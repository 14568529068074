import { Injectable } from '@angular/core';

import { ObjectMapper } from 'json-object-mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ValidateVoucherOutput } from '@app/core/models/voucher.model';
import { VALIDATE_VOUCHER } from '@app/core/resolvers/mutations/validate-voucher.mutation';
import { AppSyncHelper } from '@coach-bot/data-access/core';

import {ValidateVoucherMutation} from '../../../API.service';

@Injectable({providedIn: 'root'})
export class VoucherService {
  constructor(private readonly appSyncHelper: AppSyncHelper) {}

  public validateVoucher(voucher: string, program?: string): Observable<boolean> {
    return this.appSyncHelper
      .mutateViaApiKeyAuth(VALIDATE_VOUCHER, { voucher, program })
      .pipe(
        map((response: ValidateVoucherMutation) => ObjectMapper.deserialize(ValidateVoucherOutput, response.validateVoucher)),
        map((output: ValidateVoucherOutput) => output.valid),
      );
  }
}
