import { YearsAtAlpha } from '@app/core/models/years-at-alpha.model';

export const YEARS_AT_ALPHA: YearsAtAlpha[] = [
  { yearAtAlpha: 'ALL' },
  { yearAtAlpha: '0' },
  { yearAtAlpha: '1' },
  { yearAtAlpha: '2' },
  { yearAtAlpha: '3' },
  { yearAtAlpha: '4' },
  { yearAtAlpha: '5+' },
];
