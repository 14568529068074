import {Pipe, PipeTransform} from '@angular/core';

const ALL = 'ALL';
const PREFIX = 'L';
const SUFFIX = 'LEVELS';

@Pipe({ name: 'levelLabel' })
export class LevelLabelPipe implements PipeTransform {
  transform(level: string): string {
    return level === ALL ? `${level} ${SUFFIX}` : `${PREFIX}${level}`;
  }
}
