export const GET_STUDENT = `
  query GetStudent($studentId: String!, $includeInactiveEnrollments: Boolean!) {
    getStudent(studentId: $studentId, includeInactiveEnrollments: $includeInactiveEnrollments) {
      user_id
			first_name
			last_name
			email
			role_name
			student_id
			campus_id
			alpha_student_id
			alpha_learning_level
			date_of_birth
			preferred_name
			map_grade_level
			gender
			is_active
			profile_image
			cover_image
			school_id
			school_name
			program_enrollments {
				programId
				programKey
				startDate
				endDate
				startCourseName
				endCourseName
				subjectName
			}
			placement_grades {
				grade
				gradeId
				gradeName
				subject
				subjectId
			}
    }
  }
`;
