import {JsonProperty} from 'json-object-mapper';

import {DashboardLearningApp} from '@app/core/models/dashboard-learning-app.model';

export class DashboardProductivityDataPoint {
  @JsonProperty() public date: string;
  @JsonProperty() public totalMinutesSpent: number;
  @JsonProperty() public totalRelativeProductivity: number;

  @JsonProperty({name: 'learningApps', type: DashboardLearningApp}) public learningApps: DashboardLearningApp[];

  public constructor() {
    this.date                      = null;
    this.learningApps              = [];
    this.totalMinutesSpent         = null;
    this.totalRelativeProductivity = null;
  }
}
