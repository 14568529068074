/**
 * Returns the array of grades
 */
export const GET_GRADES = /* GraphQL */ `
  query getGrades {
    getGrades {
      id
      grade
      name
      orderNumber
    }
  }
`;
