import {ComponentType} from '@angular/cdk/portal';
import {Injectable, NgZone} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Event, NavigationEnd, Router} from '@angular/router';
import {captureException} from '@sentry/angular';

import {MAINTENANCE_DATE_FORMAT, STORAGE_KEY} from '@app/core/config/app.config';
import {MaintenanceModel} from '@app/core/models/maintenance.model';
import {ProgramEnrollment} from '@app/core/models/program-enrollment.model';
import {User} from '@app/core/models/user.model';
import {MaintenanceService} from '@app/core/services/maintenance/maintenance.service';
import {StudentService} from '@app/core/services/student/student.service';
import {VoucherService} from '@app/core/services/voucher/voucher.service';
import {
  AcceptedTermsDialogComponent
} from '@app/modules/auth/pages/components/accepted-terms-dialog/accepted-terms-dialog.component';
import {AcceptedTermService} from '@app/modules/auth/services/accepted-term.service';
import {AuthenticationService, ProgramEnrollmentData, SignUpData} from '@coach-bot/data-access/auth';
import {withRetry} from '@coach-bot/shared/util';

import * as moment from 'moment-timezone';
import {BehaviorSubject, EMPTY, Observable, combineLatest, from, merge, of} from 'rxjs';
import {catchError, concatMap, delay, filter, map, switchMap, take, tap, toArray} from 'rxjs/operators';

const ACCEPT_TERMS_POPUP_CONFIG: MatDialogConfig = { width: '600px' };
const REGISTER_MAX_RETRY = 3;
const ONE_SECOND_MS = 1000;

const UNKNOWN_ERROR_MESSAGE = 'An error occurred. Please try again.';
const MAINTENANCE_LOGIN_ERROR_MESSAGE = 'You cannot login during the maintenance window.';
const VOUCHER_VALIDATION_ERROR_MESSAGE = 'Provided voucher not exists or already used.';

@Injectable({providedIn: 'root'})
export class LoginState {
  private readonly isTermsModalOpened   = new BehaviorSubject<boolean>(false);
  public isTermsModalOpened$   = this.isTermsModalOpened.asObservable();
  public isLoggedIn$           = this.authService.isLoggedIn$;
  public isSessionCreated$     = this.authService.isSessionCreated$.asObservable();
  private readonly disableBackground    = new BehaviorSubject<boolean>(false);
  public disableBackground$    = this.disableBackground.asObservable();
  private readonly error                = new BehaviorSubject<string>(null);
  public error$                = this.error.asObservable();
  private readonly loading              = new BehaviorSubject<boolean>(false);
  public loading$              = this.loading.asObservable();
  private readonly maintenance          = new BehaviorSubject<MaintenanceModel>(null);
  public maintenance$          = this.maintenance.asObservable();
  private readonly maintenanceEndTime   = new BehaviorSubject<string>(null);
  public maintenanceEndTime$   = this.maintenanceEndTime.asObservable();
  private readonly maintenanceStartTime = new BehaviorSubject<string>(null);
  public maintenanceStartTime$ = this.maintenanceStartTime.asObservable();
  private readonly resetForm            = new BehaviorSubject<boolean>(false);
  public resetForm$            = this.resetForm.asObservable();
  private readonly submitted            = new BehaviorSubject<boolean>(false);
  public submitted$            = this.submitted.asObservable();

  private acceptTermsComponent: ComponentType<unknown> = AcceptedTermsDialogComponent;

  constructor(
    public readonly dialog: MatDialog,
    private readonly authService: AuthenticationService,
    private readonly acceptedTermService: AcceptedTermService,
    private readonly maintenanceService: MaintenanceService,
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly studentService: StudentService,
    private readonly voucherService: VoucherService,
  ) {
    this.getMaintenanceStatus().subscribe();
    this.handleAuthErrorChanges();
    this.handleRouterEvents();
  }

  public getRedirectUrl(): string {
    return this.authService.redirectUrl;
  }

  public setRedirectUrl(url: string): void {
    this.authService.redirectUrl = url;
  }

  public login(
    email: string,
    password: string,
    refreshSession = false,
    sessionCreatedCallback?: (user: User) => Promise<void>
  ): void {
    this.loading.next(true);
    this.error.next(null);
    this.authService.login(email, password, refreshSession, sessionCreatedCallback);

    merge(this.error, this.authService.maintenance)
      .pipe(filter((result) => !!result), take(1))
      .subscribe((result: MaintenanceModel | string) => {
        if (!(result instanceof MaintenanceModel)) {
          return;
        }
        this.processLoginSteps(result, email, password, refreshSession);
      });
  }

  public register(data: SignUpData, programEnrollmentsData: ProgramEnrollmentData[]): void {
    this.loading.next(true);
    this.error.next(null);
    this.isSessionCreated$.pipe(take(1)).subscribe((isSessionCreated: boolean) => {
      if (isSessionCreated) {
        this.processRegisterSteps(data, programEnrollmentsData);
        return;
      }
      this.validateVoucherAndProcessRegisterSteps(data, programEnrollmentsData);
    });
  }

  public setCustomAcceptedTermsDialogComponent(component: ComponentType<unknown>): void {
    this.acceptTermsComponent = component;
  }

  public resetError(): void {
    if (this.error.value) {
      this.error.next(null);
    }
  }

  private processLoginSteps(
    maintenanceModel: MaintenanceModel,
    email: string, password: string,
    refreshSession = false,
  ): void {
    this.authService.getCurrentAuthenticatedUser()
      .pipe(
        take(1),
        switchMap((user: User) => this.handleEnrollmentDuringLogin(user)),
        switchMap(() => this.shouldOpenTerms(maintenanceModel)), take(1),
        // Open the accepted terms dialog and wait for it to be closed.
        switchMap((open) => this.isTermsAccepted(open)), take(1),

        // Modal closed.
        switchMap((result) => this.updateUserTerms(result)), take(1),
        switchMap((result) => this.shouldLogin(result, email, password, refreshSession))
      )
      .subscribe({
        next: () => {
          this.submitted.next(true);
        },
        error: (err) => {
          this.setError(err.message);
          captureException(err);
        },
      });
  }

  private validateVoucherAndProcessRegisterSteps(data: SignUpData, programEnrollmentData: ProgramEnrollmentData[]): void {
    combineLatest(
      programEnrollmentData.filter((programEnrollmentDataItem) => programEnrollmentDataItem.voucher
    ).map((programEnrollmentDataItem) =>
      this.voucherService.validateVoucher(programEnrollmentDataItem.voucher, programEnrollmentDataItem.program)
    )).subscribe({
      next: (valid: boolean[]) => {
        if (valid.every((isValid) => isValid)) {
          this.processRegisterSteps(data, programEnrollmentData);
        } else {
          this.setError(VOUCHER_VALIDATION_ERROR_MESSAGE);
        }
      },
      error: (err: Error) => {
        this.setError(err.message);
        captureException(err);
      },
    });
  }

  private processRegisterSteps(data: SignUpData, programEnrollmentsData: ProgramEnrollmentData[]): void {
    localStorage.setItem(STORAGE_KEY.signUpEmail, data.email);
    localStorage.setItem(STORAGE_KEY.signUpProgramEnrollmentsData, JSON.stringify(programEnrollmentsData));

    const login = () => {
      this.login(
        data.email,
        data.password,
        false,
        async (user: User) => {
          await this.enrollStudentInPrograms(user, programEnrollmentsData).toPromise();
        }
      );
    };
    if (this.authService.isSessionCreated$.value) {
      login();
    } else {
      withRetry(this.authService.register(data), REGISTER_MAX_RETRY, 0).subscribe({
        next: () => {
          if (this.error.value) {
            return;
          }
          login();
        },
        error: (err) => {
          this.setError(err.message);
          captureException(err);
        },
      });
    }
  }

  private setError(error: string): void {
    this.submitted.next(true);
    let processedError: string;
    if (error) {
      processedError = error
        .replace(/^UserMigration failed with error /, '')
        .replace('GraphQL error: ', '');
    } else {
      processedError = UNKNOWN_ERROR_MESSAGE;
    }
    this.error.next(processedError);
    this.loading.next(false);
  }

  private handleAuthErrorChanges(): void {
    this.authService.error.subscribe(errorMessage => {
      this.setError(errorMessage);
    });
  }

  private getMaintenanceStatus(): Observable<MaintenanceModel> {
    return this.maintenanceService
      .getMaintenanceStatus()
      .pipe(take(1), tap(maintenance => {
        this.maintenance.next(maintenance);
        this.maintenanceStartTime.next(moment(maintenance.startTime).format(MAINTENANCE_DATE_FORMAT));
        this.maintenanceEndTime.next(moment(maintenance.endTime).format(MAINTENANCE_DATE_FORMAT));
      }));
  }

  private isTermsAccepted(open: boolean): Observable<boolean> {
    if (open) {
      return this.ngZone.run(() => {
        this.isTermsModalOpened.next(true);
        this.loading.next(false);
        const ref = this.dialog.open(this.acceptTermsComponent, ACCEPT_TERMS_POPUP_CONFIG);
        return ref.afterClosed().pipe(tap((result: boolean) => {
          if (!result) {
            this.authService.resetSession();
          }
          this.isTermsModalOpened.next(false);
        }));
      });
    }

    return of(false);
  }

  private shouldLogin(
    result: boolean,
    email: string,
    password: string,
    refreshSession = false
  ): Observable<string | boolean> {
    this.loading.next(result);

    if (result) {
      this.authService.login(email, password, refreshSession);
    }

    return EMPTY;
  }

  private shouldOpenTerms(maintenance: MaintenanceModel): Observable<boolean> {
    if (maintenance.isActive && !maintenance.hasMaintenanceAccess) {
      this.disableBackground.next(true);
      this.resetForm.next(true);
      this.maintenance.next({...this.maintenance.value, message: MAINTENANCE_LOGIN_ERROR_MESSAGE});
      return EMPTY;
    } else {
      return this.authService.openModal.pipe(filter((open) => open !== null));
    }
  }

  private updateUserTerms(result: boolean): Observable<boolean> {
    const inputs = {
      userId: localStorage.getItem(STORAGE_KEY.clientId), acceptedTerms: result
    };

    // If accepted terms is true, keep going.
    if (result) {
      this.loading.next(true);
      return withRetry(this.acceptedTermService.acceptTerm(inputs)).pipe(map(res => !!res));
    }

    return EMPTY;
  }

  private enrollStudentInPrograms(user: User, programEnrollmentsData: ProgramEnrollmentData[]): Observable<ProgramEnrollment[]> {
    return from(programEnrollmentsData).pipe(
      concatMap((programEnrollmentData: ProgramEnrollmentData) =>
        withRetry(
          this.studentService.enrollStudentInProgram(
            user.attributes['custom:studentID'],
            programEnrollmentData.program,
            programEnrollmentData.voucher,
          )
        ).pipe(delay(ONE_SECOND_MS))
      ),
      toArray(),
      tap(() => this.removeSignUpStorageData())
    );
  }

  private handleEnrollmentDuringLogin(user: User): Observable<void> {
    const email: string | null = localStorage.getItem(STORAGE_KEY.signUpEmail);
    if (email === user.attributes.email) {
      const programEnrollmentsData: ProgramEnrollmentData[] | null =
        JSON.parse(localStorage.getItem(STORAGE_KEY.signUpProgramEnrollmentsData));
      if (programEnrollmentsData) {
        return this.enrollStudentInPrograms(user, programEnrollmentsData).pipe(
          catchError((err) => {
            captureException(err);
            return of(undefined)
          }),
        );
      }
    }
    return of(undefined);
  }

  private handleRouterEvents(): void {
    this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe(() => {
      // Reset error when leaving current page
      this.error.next(null);
    });
  }

  private removeSignUpStorageData(): void {
    localStorage.removeItem(STORAGE_KEY.signUpEmail);
    localStorage.removeItem(STORAGE_KEY.signUpProgramEnrollmentsData);
  }
}
