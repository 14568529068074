/**
 * When we need to iterate over a collection using the ngFor directive,
 * Angular will instantiate a template once per item from the collection.
 *
 * If we need at some point to change the data in the collection, for example
 * as a result of an API request, we have a problem because Angular can’t keep track of items in the collection
 * and has no knowledge of which items have been removed or added.
 *
 * As a result, Angular needs to remove all the DOM elements that associated with the data and create them again.
 * That means a lot of DOM manipulations especially in a case of a big collection,
 * and as we know, DOM manipulations are expensive.
 *
 * We can help Angular to track which items added or removed by providing a trackBy function.
 * The trackBy function takes the index and the current item as arguments
 * and needs to return the unique identifier for this item.
 *
 * This class provides common trackers that we are using in our application.
 *
 * Why use trackBy with ngFor directive:
 * - ngFor directive may perform poorly with large lists.
 * - A small change to the list like, adding a new item or removing an existing item
 *   may trigger several DOM manipulations.
 */
export class LoopTrackerUtility {
  itemTracker(_: number, item: any): any {
    return item;
  }

  idTracker(_: number, item: any): any {
    return item.id;
  }

  public trackByIndex (index: number): number {
    return index;
  }
}
