import {JsonProperty} from 'json-object-mapper';

export class DashboardActivityDataPoint {
  @JsonProperty() public activityProductivityMean: number;
  @JsonProperty() public activityUnitLabel: string;
  @JsonProperty() public activityUnitsAttempted: number;
  @JsonProperty() public activityUnitsCorrect: number;
  @JsonProperty() public activityUnitsCorrectLabel: string;
  @JsonProperty() public date: string;
  @JsonProperty() public learningAppName: string;
  @JsonProperty() public learningUnit: string;
  @JsonProperty() public learningUnitPassed: string;
  @JsonProperty() public mostActivityCourseName: string;
  @JsonProperty() public mostActivityTopicName: string;
  @JsonProperty() public mostProgressCourseName: string;
  @JsonProperty() public mostProgressTopicName: string;
  @JsonProperty() public topicLevelsPassed: number;

  [key: string]: string | number;

  public constructor() {
    this.activityProductivityMean  = null;
    this.activityUnitLabel         = null;
    this.activityUnitsAttempted    = null;
    this.activityUnitsCorrect      = null;
    this.activityUnitsCorrectLabel = null;
    this.date                      = null;
    this.learningAppName           = null;
    this.learningUnit              = null;
    this.learningUnitPassed        = null;
    this.mostActivityCourseName    = null;
    this.mostActivityTopicName     = null;
    this.mostProgressCourseName    = null;
    this.mostProgressTopicName     = null;
    this.topicLevelsPassed         = null;
  }
}
