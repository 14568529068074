import {Injectable} from '@angular/core';
import {minutesAtSchool} from '@app/core/config/defaults.config';
import {ChartData} from '@app/core/models/chart-data.model';
import {ChartType} from '@app/core/models/chart-type.model';
import {DashboardSubject} from '@app/core/models/dashboard-subject.model';
import {DataPoint} from '@app/core/models/data-point.model';
import {GoalStatus} from '@app/core/models/goal-status.model';
import {StudentDashboardDetails} from '@app/core/models/student-dashboard-details.model';
import {StudentDashboardInput} from '@app/core/models/student-dashboard-input.model';
import {Subject} from '@app/core/models/subject.model';
import {STUDENT_DASHBOARD_DETAILS} from '@app/core/resolvers/queries/student-dashboard-detail.querys';
import * as defaults from '@app/core/services/dashboard/dashboard.defaults';
import {SubjectService} from '@app/core/services/subject/subject.service';
import {MINUTES_PER_HOUR} from '@app/shared/utilities/common-values.utility';
import { DateUtility } from '@alpha-coach-bot/shared-util';

import {AppSyncHelper} from '@coach-bot/data-access/core';

import {GetStudentDashboardDetailsQuery} from 'app/API.service';
import {DashboardDetails} from 'app/modules/dashboard/models/dashboard-details.model';
import {DashboardPlan} from 'app/modules/dashboard/models/dashboard-plan.model';
import {SubjectCardSubject} from 'app/modules/dashboard/models/subject-card-subject.model';
import {SubjectCardTabType} from 'app/modules/dashboard/models/subject-card-tab-type.model';
import {SubjectCardTab} from 'app/modules/dashboard/models/subject-card-tab.model';

import {ObjectMapper} from 'json-object-mapper';
import * as moment from 'moment-timezone';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';

import {ChartDataService} from '../chart-data/chart-data.service';
import {processPassedActivity, processProgress, processTimeData} from '../chart-data/chart-data.utility';
import {EnabledAppService} from '../enabled-app/enabled-app.service';
import {RangeSliderService} from '../range-slider/range-slider.service';

@Injectable()
export class DashboardService {
  public getChartData = this._chartDataService.getMap.bind(this._chartDataService);

  public constructor(
    private readonly _appSyncHelper: AppSyncHelper, private readonly _chartDataService: ChartDataService,
    private readonly _enabledAppService: EnabledAppService, private readonly _rangeSliderService: RangeSliderService,
    private readonly _subjectService: SubjectService) {}

  private static getDaysInRange(data: ChartData[]) {
    const [first]     = data;
    const last        = data[data.length - 1];
    const firstDate   = moment(first.name);
    const lastDate    = moment(last.name);
    const daysInRange = lastDate.diff(firstDate, 'days') + 1;
    return {daysInRange, firstDate: firstDate.toDate(), lastDate: lastDate.toDate()};
  }

  private static getDial(middle: number) {
    const twice        = 2;
    const end          = twice * middle;
    const fortyPercent = 0.4;
    const middleRange  = fortyPercent * middle;
    const half         = 0.5;
    const extreme      = (end - middleRange) * half;
    return [extreme, middleRange, extreme];
  }

  private static getSchoolMinutes(details: StudentDashboardDetails, detailSubject: DashboardSubject) {
    if (details?.subjects?.length) {
      return details.subjects
               .every(subject => subject.minutesAtSchool == null) ? minutesAtSchool : detailSubject.minutesAtSchool;
    }

    return minutesAtSchool;
  }

  private static getWorkingDaysAverage(total: number, data: ChartData[]): number {
    const {daysInRange, firstDate, lastDate} = DashboardService.getDaysInRange(data);
    const workingDays                        = DateUtility.differenceInBusinessDays( lastDate, firstDate);

    if (daysInRange === 1 || workingDays === 0) {
      return total;
    }

    return total / workingDays;
  }

  public getActivityAverage(data: ChartData[]): number {
    if (!data || !data.length) {
      return null;
    }

    const {mostUsedApp} = this.getMostUsedApp(data);

    if (!mostUsedApp) {
      return null;
    }

    const rawData = data
      .reduce((result, current) => [...result, ...current.series], [])
      .filter(item => item?.raw?.learningAppName === mostUsedApp.learningAppName)
      .map(item => item.raw);

    if (rawData && !rawData.length) {
      return null;
    }

    const total = rawData.reduce((result, current) => result + current.activityUnitsCorrect, 0);
    return DashboardService.getWorkingDaysAverage(total, data);
  }

  public getActivityDial(data: ChartData[], plans: DashboardPlan[], subject: DashboardSubject): number[] {
    const {mostUsedApp} = this.getMostUsedApp(data);

    if (!mostUsedApp) {
      return defaults.activityDial;
    }

    const goal = this.getActivityGoal(mostUsedApp, plans, subject);

    if (goal < 1) {
      return defaults.activityDial;
    }

    return DashboardService.getDial(goal);
  }

  public getActivityGoal(mostUsedApp: DataPoint, plans: DashboardPlan[], subject: DashboardSubject): number {
    const plan = this.getDashboardPlan(mostUsedApp, plans, subject as SubjectCardSubject);
    let middle;

    if (!plan) {
      middle = (subject.goalMinutesPerDay / MINUTES_PER_HOUR) * Number(mostUsedApp.activityProductivityMean);
    } else {
      middle = plan.activity;
    }

    return middle;
  }

  public getDashboardDetails(studentId: string, periodStartDate: string,
    periodEndDate: string): Observable<DashboardDetails> {
    return forkJoin([this._appSyncHelper
      .query<GetStudentDashboardDetailsQuery, StudentDashboardInput>(STUDENT_DASHBOARD_DETAILS, {
        studentId, periodStartDate, periodEndDate
      }), this.getPlans(studentId), this._subjectService.subjects$.pipe(take(1))]).pipe(map(
      ([details, plans, subjects]) => this.processDetails(
        ObjectMapper.deserialize(StudentDashboardDetails, details.getStudentDashboardDetails), plans, subjects)),
      catchError(() => of(defaults.details)));
  }

  public getDashboardPlan(mostUsedApp: DataPoint, plans: DashboardPlan[], subject: SubjectCardSubject): DashboardPlan {
    const range = this._rangeSliderService.range.value;

    const relatedPlans = plans.filter(item => {
      return (item.details.status !== GoalStatus.Overwritten && item.learningAppName === mostUsedApp.learningAppName &&
        item.subjectId === subject.subjectId && (moment(range.start).isSameOrAfter(item.sessionStartDate) ||
          moment(range.end).isSameOrBefore(item.sessionEndDate)));
    });

    let plan: DashboardPlan;

    if (relatedPlans.length > 1) {
      const course = this._enabledAppService.appsRaw.value.find(item => {
        const name = subject.tab === SubjectCardTabType.Activity ? mostUsedApp.mostActivityCourseName :
                     mostUsedApp.mostProgressCourseName;

        return item.courseName === name && item.learningAppName === mostUsedApp.learningAppName;
      });

      if (course) {
        plan = relatedPlans.find(item => item.learningAppCourseId === course.courseId);
      }
    } else if (relatedPlans.length === 1) {
      [plan] = relatedPlans;
    }

    return plan;
  }

  public getMostUsedActivityUnit(data: ChartData[]): string {
    const {mostUsedApp} = this.getMostUsedApp(data);
    return mostUsedApp ? `${mostUsedApp.activityUnitsCorrectLabel} per day` : '';
  }

  /**
   * Calculate the most used app in the specific data set using the activity units attempted parameter.
   */
  public getMostUsedApp(data: ChartData[] = []): { mostUsedApp: DataPoint, usedApps: DataPoint[] } {
    const usedApps = data
      // Get all the raw values available in the data set.
      .reduce((result, current) => [...result, ...current.series], [])
      .map(item => item.raw)
      .filter(Boolean)

      // Store unique instances of each app details and calculate the total units attempted.
      .reduce((result: DataPoint[], current: DataPoint) => {
        if (!current?.learningAppName || !current?.activityUnitsAttempted) {
          return result;
        }

        const app = result.find(item => item.learningAppName === current.learningAppName);

        if (!app) {
          return [...result, current];
        }

        return result.map(item => item.learningAppName === app.learningAppName ? {
          ...item, activityUnitsAttempted: Number(item.activityUnitsAttempted) + Number(current.activityUnitsAttempted)
        } : item);
      }, [])

      // Put the most used app details first.
      .sort((a: DataPoint, b: DataPoint) => Number(b.activityUnitsAttempted) - Number(a.activityUnitsAttempted));

    const [mostUsedApp] = usedApps;
    return {mostUsedApp, usedApps};
  }

  public getPlans(_: string): Observable<DashboardPlan[]> {
    return of<DashboardPlan[]>([]);
  }

  public getProductivityAverage(data: ChartData[]): number {
    if (!data.length) {
      return null;
    }

    const denominator = data.filter(day => day.series.some(value => value.raw)).length;

    if (!denominator) {
      return null;
    }

    return (data
        .reduce((result, current) => [...result, ...current.series.map(value => value.original)], [])
        .reduce((result, current) => result + current, 0) / denominator);
  }

  public getProgressAverage(data: ChartData[]): number {
    if (!data.length) {
      return null;
    }

    const {mostUsedApp} = this.getMostUsedApp(data);

    if (!mostUsedApp) {
      return null;
    }

    const filteredSeries = data
      .reduce((reduce, current) => [...reduce, ...current.series], [])
      .filter(item => item.raw && item.name === mostUsedApp.learningAppName);

    const denominator = filteredSeries.length;

    if (!denominator) {
      return null;
    }

    const total = filteredSeries.reduce((result, current) => result + current.original, 0);
    return total / denominator;
  }

  public getProgressDial(data: ChartData[], plans: DashboardPlan[], subject: DashboardSubject,
    type = ChartType.Progress): number[] {
    if (type === ChartType.Productivity) {
      return defaults.productivityDial;
    }

    const {mostUsedApp} = this.getMostUsedApp(data);

    if (!mostUsedApp || (mostUsedApp && !mostUsedApp.alphaAverageProductivity)) {
      return defaults.progressDial;
    }

    const goal = this.getProgressGoal(mostUsedApp, plans, subject);

    if (goal < 1) {
      return defaults.progressDial;
    }

    return DashboardService.getDial(goal);
  }

  public getProgressGoal(app: DataPoint, plans: DashboardPlan[], subject: DashboardSubject): number {
    const plan = this.getDashboardPlan(app, plans, subject as SubjectCardSubject);
    let middle = 0;

    if (!plan) {
      const minutesPerHour = 60;

      if (!app.alphaAverageProductivity) {
        return middle;
      }

      middle = 1 / (Number(app.alphaAverageProductivity) / minutesPerHour);
    } else {
      middle = plan.progress;
    }

    return middle;
  }

  public getProgressTitle(type: ChartType): string {
    return type === ChartType.Progress ? defaults.progressTitle : 'Weekly Learning Productivity';
  }

  public getProgressUnit(data: ChartData[], type = ChartType.Progress): string {
    if (type === ChartType.Productivity) {
      return 'average productivity';
    }

    const {mostUsedApp} = this.getMostUsedApp(data);

    if (!mostUsedApp) {
      return `${defaults.progressUnit} per week`;
    }

    return `${mostUsedApp.learningUnitPassed || mostUsedApp.activityUnitsCorrectLabel} per week`;
  }

  public getTimeAverage(data: ChartData[]): number {
    if (!data || !data.length) {
      return null;
    }

    const rawData = data.reduce((result, current) => {
      const series = current.series.find(item => item.raw);
      return series ? [...result, ...(series.raw as DataPoint[])] : [...result];
    }, []);

    if (rawData && !rawData.length) {
      return null;
    }

    const total = rawData.reduce((result, current) => result + current.minutesAtHome + current.minutesAtSchool, 0);
    return DashboardService.getWorkingDaysAverage(total, data);
  }

  public getTimeDial(goal: number): number[] {
    const twentyPercent = 0.2;
    const middle        = twentyPercent * goal;

    if (middle < 1) {
      return defaults.timeDial;
    }

    const twice   = 2;
    const total   = twice * goal;
    const half    = 0.5;
    const extreme = (total - middle) * half;
    return [extreme, middle, extreme];
  }

  public processDetails(details: StudentDashboardDetails, plans: DashboardPlan[],
    subjects: Subject[]): DashboardDetails {
    const subjectsAll = subjects.map(subject => {
      const detailSubject = details.subjects.find(v => v.subjectId === subject.id) ||
        defaults.subjects.find(v => v.name === subject.name);

      const result = {
        ...subject, ...detailSubject, goalMAPPercentile: detailSubject.goalMAPPercentile,
        goalMinutesPerDay: detailSubject.goalMinutesPerDay || minutesAtSchool,
        minutesAtSchool: DashboardService.getSchoolMinutes(details, detailSubject)
      };

      return {...result, tabs: this.getInitialTabs(result, plans)};
    });

    const subjectsMap = subjectsAll.filter(subject => subject.isMAPSubject);

    return {
      ...details, subjectsAll, subjectsMap
    };
  }

  private getInitialTabs(subject: DashboardSubject, plans: DashboardPlan[]): Map<SubjectCardTabType, SubjectCardTab> {
    const range = this._rangeSliderService.range.value;
    const start = new Date(range.start);
    const end   = new Date(range.end);

    const time = this._chartDataService.filterData(start, end, processTimeData(subject.minutesPerDayDataPoints),
      this._chartDataService.getDataProperties(ChartType.Time));

    const passedActivity = this._chartDataService.filterData(start, end,
      processPassedActivity(subject.appActivityDataPoints),
      this._chartDataService.getDataProperties(ChartType.PassedActivity));

    const progress = this._chartDataService.filterData(start, end,
      processProgress(subject.appActivityDataPoints, subject.productivityDataPoints),
      this._chartDataService.getDataProperties(ChartType.Progress));

    return new Map([[SubjectCardTabType.Time, {
      average: this.getTimeAverage(time), dial: this.getTimeDial(subject.goalMinutesPerDay), title: 'Daily App Time',
      type: SubjectCardTabType.Time, unit: 'minutes per day'
    }], [SubjectCardTabType.Activity, {
      average: this.getActivityAverage(passedActivity), dial: this.getActivityDial(passedActivity, plans, subject),
      image: 'assets/icons/tachometer.svg', title: 'Daily App Activity', type: SubjectCardTabType.Activity,
      unit: this.getMostUsedActivityUnit(passedActivity)
    }], [SubjectCardTabType.Progress, {
      average: this.getProgressAverage(progress), dial: this.getProgressDial(progress, plans, subject),
      image: 'assets/icons/chart-line.svg', title: defaults.progressTitle, type: SubjectCardTabType.Progress,
      unit: this.getProgressUnit(progress)
    }]]);
  }
}
