<input #input
       class="input"
       aria-label="file"
       type="file"
       id="file"
       (change)="updateEventAndReadNewFile($event)" />

<mat-dialog-content>
  <image-cropper data-private
                 *ngIf="!loading; else showLoading"
                 format="webp"
                 [aspectRatio]="data.ratio"
                 [imageBase64]="imageOriginal"
                 [imageChangedEvent]="event"
                 [maintainAspectRatio]="true"
                 [roundCropper]="data.round"
                 [style.max-height]="maxHeight"
                 [style.max-width]="maxWidth"
                 [resizeToWidth]="resizeToWidth"
                 (imageCropped)="imageCropped = $event.base64"></image-cropper>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-raised-button
          class="secondary"
          (click)="input.click()">SELECT ANOTHER</button>

  <button mat-raised-button
          class="primary"
          (click)="save()">SAVE</button>
</mat-dialog-actions>

<ng-template #showLoading>
  <div class="loading"
       fxLayoutAlign="center center">
    <i class="loader" aria-hidden="true"></i>
  </div>
</ng-template>
