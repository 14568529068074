import {JsonProperty} from 'json-object-mapper';

export class CourseEffortEnabledApp {
  @JsonProperty() public courseId: string;
  @JsonProperty() public courseName: string;
  @JsonProperty() public learningAppId: string;
  @JsonProperty() public learningAppImage: string;
  @JsonProperty() public learningAppName: string;
  @JsonProperty() public subjectId: string;
  @JsonProperty() public topicId: string;
  @JsonProperty() public topicName: string;

  public constructor() {
    this.courseId         = null;
    this.courseName       = null;
    this.learningAppId    = null;
    this.learningAppImage = null;
    this.learningAppName  = null;
    this.subjectId        = null;
    this.topicId          = null;
    this.topicName        = null;
  }
}
