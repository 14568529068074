import {Injectable} from '@angular/core';
import {SubHeaderFacade} from '@app/core/facades/sub-header.facade';
import {Assignment} from '@app/core/models/assignment.model';
import {StudentService} from '@app/core/services/student/student.service';

import {AppSyncHelper} from '@coach-bot/data-access/core';

import {GetStudentHouseAssignmentsQuery} from 'app/API.service';
import {getStudentHouseAssignments} from 'graphql/queries';

import {ObjectMapper} from 'json-object-mapper';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, shareReplay, tap} from 'rxjs/operators';

const defaultAssignment = {
  guideId: '', guideName: 'None', houseId: '', houseImage: '', houseName: 'None', studentId: ''
};

@Injectable()
export class StudentHouseAssignmentService {
  public setFilters = this._subHeaderFacade.setFilters.bind(this._subHeaderFacade);
  /**
   * Temporary Placement for setting filters on dashboard page.
   * As Dashboard page already have lot of services injected in constructor
   */

  public setTitle   = this._subHeaderFacade.setTitle.bind(this._subHeaderFacade);
  /** Dashboard filter function ends here */

  private readonly _studentHouseAssignments = new BehaviorSubject<Assignment[]>([]);

  public studentHouseAssignments$ = this._studentHouseAssignments.asObservable().pipe(tap(assignments => {
    if (!assignments.length) {
      this.getAll();
    }
  }), filter(assignments => Boolean(assignments.length)), distinctUntilChanged(), shareReplay(1));

  public studentHouseAssignment$ = combineLatest([this.studentHouseAssignments$, this._studentService.studentId$]).pipe(
    map(([assignments, id]) => assignments.find(value => value.studentId === id) || defaultAssignment));

  public constructor(private readonly _appSync: AppSyncHelper, private readonly _studentService: StudentService,
    private readonly _subHeaderFacade: SubHeaderFacade) {}

  public getAll(): void {
    this._appSync
      .query(getStudentHouseAssignments, null)
      .pipe(map((response: GetStudentHouseAssignmentsQuery) => ObjectMapper.deserializeArray(Assignment,
        response.getStudentHouseAssignments)), catchError(() => of([defaultAssignment])))
      .subscribe(data => this.updateStudentHouseAssignments(data));
  }

  public updateStudentHouseAssignments(values: Assignment[]): void {
    this._studentHouseAssignments.next([...values]);
  }
}
