import {STORAGE_KEY} from '@app/core/config/app.config';

import {BehaviorSubject, of} from 'rxjs';
import {distinctUntilChanged, filter, first, shareReplay, tap} from 'rxjs/operators';

import {STUDENT_GRADE_LEVEL} from '@app/core/mock/student-grade-level.mock';
import {StudentGradeLevel} from '@app/core/models/student-grade-level.model';

export const defaultStudentGradeLevel = 'ALL';

export class StudentGradeLevelServiceMock {
  private readonly _studentGradeLevel = new BehaviorSubject<string>(
    localStorage.getItem(STORAGE_KEY.studentGradeLevel) || defaultStudentGradeLevel
  );

  public studentGradeLevel$ = this._studentGradeLevel.asObservable().pipe(
    distinctUntilChanged(),
    filter(studentGradeLevel => Boolean(studentGradeLevel))
  );

  private readonly _studentGradeLevels = new BehaviorSubject<StudentGradeLevel[]>([]);

  public studentGradeLevels$ = this._studentGradeLevels.asObservable().pipe(
    tap(studentGradeLevels => {
      if(!studentGradeLevels.length) {
        this.getAll();
      }
    }),
    filter(studentGradeLevels => Boolean(studentGradeLevels.length)),
    distinctUntilChanged(),
    shareReplay(1)
  );

  public getAll(): void {
    of(STUDENT_GRADE_LEVEL)
    .pipe(first())
    .subscribe(data => this.updateLevels(data));
  }

  public updateLevel(value: string): void {
    this._studentGradeLevel.next(value);
    localStorage.setItem(STORAGE_KEY.studentGradeLevel, value);
  }

  public updateLevels(values: StudentGradeLevel[]): void {
    this._studentGradeLevels.next(values);
  }
}
