import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';

import {ButtonModule} from '@progress/kendo-angular-buttons';
import {LoaderModule} from '@progress/kendo-angular-indicators';
import {PopupModule} from '@progress/kendo-angular-popup';
import {MenusModule} from '@progress/kendo-angular-menu';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {
  CoachBotNavigationComponent, CoachBotProfileComponent, CoachBotTopBarComponent,
  QuickSightDashboardComponent, CoachBotSkillPopupComponent
} from '../components';

const COMPONENTS = [CoachBotNavigationComponent, CoachBotProfileComponent, CoachBotTopBarComponent,
  QuickSightDashboardComponent, CoachBotSkillPopupComponent];

const MAX_PERCENT = 100;
const RADIUS = 18;
const SPACE = -5;
const OUTER_STROKE_WIDTH = 5;
const INNER_STROKE_WIDTH = 5;
const OUTER_STROKE_COLOR = '#63D97F';
const INNER_STROKE_COLOR = '#101B450F';

@NgModule({
  declarations: [...COMPONENTS], exports: [...COMPONENTS],
  imports: [CommonModule,
    LoaderModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    PopupModule,
    MenusModule,
    ButtonModule,
    FlexModule,
    NgCircleProgressModule.forRoot({
      maxPercent: MAX_PERCENT,
      showUnits: false,
      backgroundPadding: 0,
      showZeroOuterStroke: false,
      lazy: false,
      radius: RADIUS,
      space: SPACE,
      outerStrokeGradient: false,
      outerStrokeWidth: OUTER_STROKE_WIDTH,
      innerStrokeWidth: INNER_STROKE_WIDTH,
      showTitle: false,
      showSubtitle: true,
      subtitleFontSize: '.75rem',
      subtitleColor: '#000',
      outerStrokeColor: OUTER_STROKE_COLOR,
      innerStrokeColor: INNER_STROKE_COLOR})
  ]
})
export class CoachBotUiModule {}
