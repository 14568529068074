import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { Widget } from '@app/core/models/enumerations/widget.enum';
import { FilterType } from '@app/shared/components/sub-header/filter-type.model';
import SubHeaderWidgetsState from '@app/shared/states/sub-header-widgets.state';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubHeaderState {
  private readonly _filters$ = new BehaviorSubject<FilterType[]>([]);
  private readonly _showHeader$ = new BehaviorSubject<boolean>(true);
  private readonly _title$ = new BehaviorSubject<string>(null);
  private readonly _icon$ = new BehaviorSubject<string>(null);
  private readonly _widget$ = new BehaviorSubject<Widget>(null);
  private readonly _showAllSubjects$ = new BehaviorSubject<Boolean>(false);
  /** Show all list of subject obtained from listSubjects query  */
  public showAllSubjects$ = this._showAllSubjects$.asObservable();
  /** Represents the list of filters to be used on sub header */
  public filters$ = this._filters$.asObservable().pipe(distinctUntilChanged(), debounceTime(0));
  /** Whether or not to show the header */
  public showHeader$ = this._showHeader$.asObservable().pipe(distinctUntilChanged());
  /** Represents the title of the page used in sub header */
  public title$ = this._title$.asObservable().pipe(distinctUntilChanged());
  public icon$ = this._icon$.asObservable().pipe(distinctUntilChanged());
  /**
   * Represents the widget to be used as template for sub header
   * For Example: On Rewards page we have sub header with toggle button
   * so widget type is representing the toggle button
   */
  public widget$ = this._widget$.asObservable().pipe(distinctUntilChanged());

  constructor(private readonly _widgetsState: SubHeaderWidgetsState) {}

  /** To be used to set the filters list state. */
  public setFilters(filters: FilterType[]) {
    this._filters$.next(filters);
  }

  public setShowAllSubjects(value: boolean) {
    this._showAllSubjects$.next(value);
  }

  public setSubheader(value: boolean) {
    this._showHeader$.next(value);
  }

  /** To be used to set the title state. */
  public setTitle(title: string) {
    this._title$.next(title);
  }

  public setIcon(icon: string) {
    this._icon$.next(icon);
  }

  /** To be used to set the widget type state. */
  public setWidget(widget: Widget, template: TemplateRef<ElementRef>) {
    this._widgetsState.register(widget, template);
    this._widget$.next(widget);
  }

  /** To be used to unregister the widget template */
  public removeWidget(widget: Widget) {
    this._widgetsState.unRegister(widget);
  }
}
