import { DependencyDirection, DependencyLevel } from '../enums';
import { Dependency, DependencyMap } from '../interfaces';

/**
 * This service is responsible for handling dependency-related operations for skill standards.
 */
export class DependencyService {
  /**
   * Gets dependencies for specific block.
   * @param dependencies
   * @param blockId
   * @param direction
   * @param level
   */
  public getDependencyGraph(
    dependencies: string,
    blockId: string,
    direction: DependencyDirection = DependencyDirection.DependsOn,
    level: DependencyLevel = DependencyLevel.Direct
  ): Dependency[] {
    const graph: Dependency[] = [];
    const dependencyMap: DependencyMap[] = JSON.parse(dependencies);
    if (direction === DependencyDirection.All) {
      graph.push(
        ...this.lookup(blockId, DependencyDirection.DependsOn, 1, dependencyMap, level),
        ...this.lookup(blockId, DependencyDirection.DependencyFor, 1, dependencyMap, level)
      );
    } else {
      graph.push(...this.lookup(blockId, direction, 1, dependencyMap, level));
    }
    return Array.from(
      graph
        .sort((a, b) => b.dependencyLevel - a.dependencyLevel)
        .reduce((map, dependency) => map.set(dependency.standard, dependency), new Map())
        .values()
    );
  }

  private lookup(
    block: string,
    direction: DependencyDirection,
    depth: number,
    dependencyMap: DependencyMap[],
    level: DependencyLevel
  ): Dependency[] {
    const graph: Dependency[] = [];
    const standardIndex = direction === DependencyDirection.DependsOn ? 'to' : 'from';
    const value = direction === DependencyDirection.DependsOn ? 'from' : 'to';
    dependencyMap
      .filter((d) => d[standardIndex] === block)
      .map((d) => d[value])
      .forEach((dependency) => {
        graph.push({
          standard: dependency,
          dependencyLevel: depth,
          dependencyType: direction,
        });
        if (level === DependencyLevel.All) {
          graph.push(...this.lookup(dependency, direction, depth + 1, dependencyMap, level));
        }
      });
    return graph;
  }
}
