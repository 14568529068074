export class QuicksightDashboardParametersModel {
  public additionalDataHeader?: string;
  public leaderboardKey: string;
  public metricHeader: string;
  public period: string;
  public periodicity: string;
  public studentId: string;

  constructor() {
    this.studentId = '';
    this.leaderboardKey = '';
    this.metricHeader = '';
    this.additionalDataHeader = '';
    this.periodicity = '';
    this.period = '';
  }
}
