import {MapGradeLevel} from '@app/core/models/enumerations/map-grade-level.enum';
import {SubjectCardSubject} from 'app/modules/dashboard/models/subject-card-subject.model';
import {SubjectCardTabType} from 'app/modules/dashboard/models/subject-card-tab-type.model';
import {DashboardDetails} from 'app/modules/dashboard/models/dashboard-details.model';

import {defaultGoalMAPPercentile, minutesAtSchool} from '../../config/defaults.config';

const activityDialExtremeDefault = 40;
const activityDialMiddleDefault  = 20;

export const activityDial = [
  activityDialExtremeDefault,
  activityDialMiddleDefault,
  activityDialExtremeDefault
];

const productivityDialExtremeDefault = 80;
const productivityDialMiddleDefault  = 40;

export const productivityDial = [
  productivityDialExtremeDefault,
  productivityDialMiddleDefault,
  productivityDialExtremeDefault
];

const progressDialExtremeDefault = 3.2;
const progressDialMiddleDefault  = 1.6;
export const progressDial        = [progressDialExtremeDefault, progressDialMiddleDefault, progressDialExtremeDefault];
export const progressTitle       = 'Weekly App Progress';
export const progressUnit        = 'levels passed';
const timeDialExtremeDefault     = 20;
const timeDialMiddleDefault      = 10;
export const timeDial            = [timeDialExtremeDefault, timeDialMiddleDefault, timeDialExtremeDefault];

const defaultSubjectBase: SubjectCardSubject = {
  minutesAtSchool,
  appActivityDataPoints:   [],
  estimatedGoalDate:       null,
  goalMAPPercentile:       defaultGoalMAPPercentile,
  goalMinutesPerDay:       minutesAtSchool,
  goalProductiveTime:      null,
  id:                      '',
  image:                   '',
  isMAPSubject:            true,
  lastYearMAPPercentile:   null,
  minutesAtHome:           null,
  minutesPerDayDataPoints: [],
  name:                    '',
  productivityDataPoints:  [],
  savedMAPPercentile:      null,
  subjectId:               null,
  subjectName:             ''
};

export const subjects: SubjectCardSubject[] = [
  {...defaultSubjectBase, name: 'Math', subjectName: 'Math'},
  {...defaultSubjectBase, name: 'Language', subjectName: 'Language'},
  {...defaultSubjectBase, name: 'Science', subjectName: 'Science'},
  {...defaultSubjectBase, name: 'Reading', subjectName: 'Reading'},
  {...defaultSubjectBase, isMAPSubject: false, name: 'Test Prep', subjectName: 'Test Prep'},
  {...defaultSubjectBase, isMAPSubject: false, name: 'Non-Core Skills', subjectName: 'Non-Core Skills'}
];

const subjectsWithTypename = subjects.map(subject => (
  {...subject, __typename: 'SubjectDashboard' as 'SubjectDashboard'}
));

const tabs = new Map([
  [
    SubjectCardTabType.Time,
    {
      average: 0,
      dial:    timeDial,
      title:   'Daily App Time',
      type:    SubjectCardTabType.Time,
      unit:    'minutes per day'
    }
  ],
  [
    SubjectCardTabType.Activity,
    {
      average: 0,
      dial:    activityDial,
      title:   'Daily App Activity',
      type:    SubjectCardTabType.Activity,
      unit:    'activity per day'
    }
  ],
  [
    SubjectCardTabType.Progress,
    {
      average: 0,
      dial:    progressDial,
      title:   progressTitle,
      type:    SubjectCardTabType.Progress,
      unit:    'weekly progress'
    }
  ]
]);

export const details: DashboardDetails = {
  firstName:          '',
  lastName:           '',
  alphaLearningLevel: '',
  mapGradeLevel:      '' as MapGradeLevel,
  alphaStudentId:     '',
  isActive:           true,
  subjects:           subjectsWithTypename,
  subjectsAll:        subjectsWithTypename.map(item => ({...item, tabs})),
  subjectsMap:        subjectsWithTypename.filter(item => item.isMAPSubject).map(item => ({...item, tabs}))
};
