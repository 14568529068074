export const CREATE_NOTIFICATION = `
 mutation CreateNotification(
  $input: CreateNotificationInput!
 ) {
  createNotification(input: $input) {
    createdOn
    id
    isRead
    message
    notificationType
    studentId
    title
  }
}`;
