import {JsonProperty} from 'json-object-mapper';

export class StudentUser {
  /** stores Alpha learning level **/
  @JsonProperty({name: 'alpha_learning_level'}) public alphaLearningLevel: string;
  /** stores student's alpha id **/
  @JsonProperty({name: 'alpha_student_id'}) public alphaStudentId: string;
  /** stores student's campus id **/
  @JsonProperty({name: 'campus_id'}) public campusId?: string | null;
  /** hold the cover image student uploaded for his profile **/
  @JsonProperty({name: 'cover_image'}) public coverImage?: string | null;
  /** student's date of birth**/
  @JsonProperty({name: 'date_of_birth'}) public dateOfBirth?: string | null;
  /**stores student's id **/
  @JsonProperty() public email: string;
  /** student's first name **/
  @JsonProperty({name: 'first_name'}) public firstName: string;
  /** joins the first and last names for a student **/
  @JsonProperty() public fullName?: string;
  /** stores student's gender i.e. female or male **/
  @JsonProperty() public gender: string | null;
  /** shows if user is an active student **/
  @JsonProperty({name: 'is_active'}) public isActive?: boolean | null;
  /** student's last name **/
  @JsonProperty({name: 'last_name'}) public lastName: string;
  /** stores MAP Score **/
  @JsonProperty({name: 'map_grade_level'}) public mapGradeLevel?: string | null;
  /** student's preferred or nickname **/
  @JsonProperty({name: 'preferred_name'}) public preferredName?: string | null;
  /** hold student's uploaded profile image **/
  @JsonProperty({name: 'profile_image'}) public profileImage?: string | null;
  /** stores user's role i.e. student, guide or admin **/
  @JsonProperty({name: 'role_name'}) public roleName: string;
  /** relates the student to the school that this student studies */
  @JsonProperty({name: 'school_id'}) public schoolId?: string;
  /** relates the student to the school that this student studies */
  @JsonProperty({name: 'school_name'}) public schoolName?: string;
  /** stores student's id **/
  @JsonProperty({name: 'student_id'}) public studentId: string;
  /** user's id **/
  @JsonProperty({name: 'user_id'}) public userId: string;

  constructor() {
    this.userId             = null;
    this.firstName          = null;
    this.lastName           = null;
    this.email              = null;
    this.roleName           = null;
    this.studentId          = null;
    this.campusId           = null;
    this.alphaStudentId     = null;
    this.alphaLearningLevel = null;
    this.dateOfBirth        = null;
    this.preferredName      = null;
    this.mapGradeLevel      = null;
    this.gender             = null;
    this.isActive           = null;
    this.profileImage       = null;
    this.coverImage         = null;
    this.fullName           = null;
    this.schoolId           = null;
    this.schoolName         = null;
  }

  public mapGradeLevelNumber = (): number => {
    return Number(this.mapGradeLevel);
  };
}
