import {Injectable} from '@angular/core';
import {AppSyncHelper} from '@coach-bot/data-access/core';
import {STORAGE_KEY} from 'app/core/config/app.config';

import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, filter, first, shareReplay, tap} from 'rxjs/operators';

import {getAlphaLearningLevels} from '../../../../graphql/queries';
import {GetAlphaLearningLevelsQuery} from '../../../API.service';

const DEFAULT = '3';

@Injectable()
export class AlphaLevelService {
  private readonly _allEnabled$ = new BehaviorSubject<boolean>(false);
  private readonly level        = new BehaviorSubject<string>(localStorage.getItem(STORAGE_KEY.level) || DEFAULT);
  level$                        = this.level.asObservable().pipe(distinctUntilChanged(),
    filter(level => Boolean(level)));
  private readonly levels       = new BehaviorSubject<GetAlphaLearningLevelsQuery['getAlphaLearningLevels']>([]);
  levels$                       = this.levels.asObservable().pipe(tap(levels => {
    if (!levels.length) {
      this.getAll();
    }
  }), filter(levels => Boolean(levels.length)), distinctUntilChanged(), shareReplay(1));

  constructor(private readonly appSyncHelper: AppSyncHelper) {
  }

  /** Enables option to select All Levels */
  public enableAll(value: boolean): void {
    this._allEnabled$.next(value);
    if (value) {
      this.updateLevel('ALL');
    } else {
      this.updateLevel(DEFAULT);
    }
  }

  public getAll() {
    this.appSyncHelper
      .query<GetAlphaLearningLevelsQuery, string>(getAlphaLearningLevels, '')
      .pipe(first())
      .subscribe(data => this.updateLevels(data.getAlphaLearningLevels));
  }

  updateLevel(value: string) {
    this.level.next(value);
    localStorage.setItem(STORAGE_KEY.level, value);
  }

  updateLevels(values: GetAlphaLearningLevelsQuery['getAlphaLearningLevels']) {
    this.levels.next(values);
  }
}
