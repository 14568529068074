<header>
  <mat-toolbar color="primary">
    <mat-toolbar-row class="toolbar-row content-container">
      <img *ngIf="isAdmin" alt="Alpha" class="logo" height="40" src="/assets/logo/coachbot-logo.svg"
           width="auto" />
      <strong class="logo" *ngIf="!isAdmin">TeachTower for Math</strong>
      <cb-navigation [isAdmin]="isAdmin" [isGuideOrAdmin]="isGuideOrAdmin"
                     [isGuide]="isGuide" [isStudent]="isStudent"
                     [isViewSkillsStackAccess]="isViewSkillsStackAccess"
                     [studentId]="studentFacade.studentId$ | async"></cb-navigation>
      <div class="spacer"></div>
      <ng-content></ng-content>
      <cb-profile (logout)="logout.emit()" (uploadPhoto)="uploadPhoto.emit($event)"
                  [background]="background"
                  [image]="image"
      ></cb-profile>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
