import {Injectable} from '@angular/core';

import {Subject} from '@app/core/models/subject.model';
import {SUBJECT_LIST} from '@app/core/resolvers/queries/subject-list.query';

import {AppSyncHelper} from '@coach-bot/data-access/core';
import {ListSubjectsQuery} from 'app/API.service';

import {ObjectMapper} from 'json-object-mapper';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, tap} from 'rxjs/operators';

@Injectable()
export class SubjectService {
  private readonly _allEnabled$ = new BehaviorSubject<boolean>(false);
  private readonly subjectId    = new BehaviorSubject<string>(null);
  private readonly subjects     = new BehaviorSubject<Subject[]>([]);
  public subjects$ = this.subjects.asObservable().pipe(tap(subjects => {
    if (!subjects.length) {
      this.list();
    }
  }), filter(subjects => Boolean(subjects.length)), distinctUntilChanged(), shareReplay(1));
  public subjectId$ = combineLatest([this.subjects$, this.subjectId]).pipe(
    filter(([subjects]) => Boolean(subjects && subjects.length)),
    map(([subjects, id]) => this._getSubjectId(subjects, id)), distinctUntilChanged(), shareReplay(1));
  public subject$ = combineLatest([this.subjectId$, this.subjects$]).pipe(
    filter(([id, subjects]) => Boolean(id && subjects)), map(([id, subjects]) => this._getSubject(id, subjects)),
    filter(subject => Boolean(subject)));
  private readonly subjectsMap  = new BehaviorSubject<Subject[]>([]);
  public subjectsMap$ = this.subjectsMap.asObservable().pipe(tap(subjects => {
    if (!subjects.length) {
      this.list(true);
    }
  }), filter(subjects => Boolean(subjects.length)), distinctUntilChanged(), shareReplay(1));

  constructor(private readonly appSyncHelper: AppSyncHelper) {}

  /** Enables option to select All Students */
  public enableAll(value: boolean): void {
    this._allEnabled$.next(value);
    if (value) {
      this.updateSubjectId('ALL');
    } else {
      this.updateSubjectId(this.subjects.value[0].id);
    }
  }

  public list(onlyMapSubjects = false): void {
    this.appSyncHelper
      .query<ListSubjectsQuery, { onlyMapSubjects: boolean }>(SUBJECT_LIST, {onlyMapSubjects})
      .pipe(map(res => ObjectMapper.deserializeArray(Subject, res.listSubjects)))
      .subscribe(subjects => {
        if (onlyMapSubjects) {
          this.updateSubjectsMap(subjects);
        } else {
          this.updateSubjects(subjects);
        }
      });
  }

  public updateSubjectId(id: string): void {
    this.subjectId.next(id);
  }

  public updateSubjects(values: Subject[]): void {
    this.subjects.next([...values]);
  }

  public updateSubjectsMap(values: Subject[]): void {
    this.subjectsMap.next([...values]);
  }

  private _findSubjectIs(subjects: Subject[], id: string) {
    return subjects.some(item => item.id === id) ? id : subjects[0].id;
  }

  private _getSubject(id: string, subjects: Subject[]) {
    return id !== 'ALL' ? subjects.find(subject => subject.id === id) : subjects[0];
  }

  private _getSubjectId(subjects: Subject[], id: string) {
    return id !== 'ALL' ? this._findSubjectIs(subjects, id) : 'ALL';
  }
}
