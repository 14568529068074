/**
 * Returns the array of recommended skills to learn from our Skill Recommender API
 * Note that for UI we only need externalId so other properties available from API were omitted to avoid transfer
 * of redundant information.
 */
export const SKILL_STACK_RESOURCE_RECOMMENDATIONS = /* GraphQL */ `
  query getResourcesRecommendations($appName: String!, $gapFillingStartGrade: String!, $studentGrade: String!, $subjectName: String!, $studentId: String!, $numberOfResources: Int) {
    getResourcesRecommendations(appName: $appName, gapFillingStartGrade: $gapFillingStartGrade, studentGrade: $studentGrade, subjectName: $subjectName, studentId: $studentId, numberOfResources: $numberOfResources) {
      standardExternalId
    }
  }
`
