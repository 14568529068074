import localeEn from '@angular/common/locales/en';
import localeHu from '@angular/common/locales/hu';
import localeUk from '@angular/common/locales/uk';
import { Language } from '../enums';
import { LanguageData } from '../interfaces';

export const LANGUAGES: LanguageData[] = [
  {
    code: 'en',
    countryCode: 'us',
    currencySign: '$',
    flagSymbol: '🇺🇸',
    name: 'English',
    nativeName: 'English',
    nativeShortName: 'en',
    locale: 'en-US',
  },
  {
    code: 'hu',
    countryCode: 'hu',
    currencySign: '€',
    flagSymbol: '🇭🇺',
    name: 'Hungarian',
    nativeName: 'Magyar',
    nativeShortName: 'hu',
    locale: 'hu-HU',
  },
  {
    code: 'uk',
    countryCode: 'ua',
    currencySign: '€',
    flagSymbol: '🇺🇦',
    name: 'Ukrainian',
    nativeName: 'українська',
    nativeShortName: 'uk',
    locale: 'uk-UA',
  },
];

export const LOCALES_MAP: Map<Language, unknown> = new Map([
  [Language.English, localeEn],
  [Language.Hungarian, localeHu],
  [Language.Ukrainian, localeUk],
]);
