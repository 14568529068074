export const GET_NOTIFICATIONS = `
query GetNotifications($studentId: String!) { 
  getNotifications(studentId: $studentId) {
    createdOn
    id
    isRead
    message
    notificationType
    studentId
    title
  }
}`;
