export const UPDATE_NOTIFICATION = `
 mutation MarkNotificationAsRead(
  $notificationId: String!
 ) {
  markNotificationAsRead(input: {notificationId: $notificationId}) {
    id
    title
    studentId
    notificationType
    message
    isRead
  }
}`;
