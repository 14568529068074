import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ErrorCodesEnum } from '@app/core/models/enumerations/error-codes.enum';

@Component({
  selector: 'error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorScreenComponent {
  /** Holds the error code */
  @Input() public isShowErrorCode = true;
  /** Holds the error code */
  @Input() public errorCode: number = ErrorCodesEnum.Code_404;
  /** Holds the error title */
  public errorHeader = 'Oops..';
  /** Holds the error detail */
  public errorText = 'The page you are looking for might have been removed or is temporarily unavailable.';
  /** Holds the image src */
  public imgSrc = 'assets/img/error-screen/error-img.png';

  /** Returns the currently set error code */
  public get error(): string {
    return `Error ${this.errorCode}`;
  }
}
