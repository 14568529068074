<div class="content">
  <div class="title">
    Welcome to TeachTower for Math!
  </div>
  <button class="close-btn close"
          type="button"
          mat-dialog-close
          (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="subtitle">
    In order to start using TeachTower for Math please check the box below
    indicating that you have read and acknowledged the TeachTower for Math
    <a target="_blank" rel="noopener" [href]="privacyPolicyLinkCoachBot">privacy policy</a> and
    <a target="_blank" [href]="termsOfServiceLink">terms of service</a>.
  </div>

  <div class="checkbox-container">
    <mat-checkbox (change)="checkBoxChange($event)">
      <div class="checkbox-text">
        I agree to the TeachTower for Math privacy policy and terms of service.</div>
    </mat-checkbox>
  </div>
  <div class="button-container">
    <button mat-raised-button class="continue-button" [disabled]="!checked" (click)="next()">Continue</button>
  </div>
</div>
