import { QuicksightDashboardParametersModel } from './quicksight-dashboard-parameters.model';

export class QuicksightEmbeddedDashboardModel {
  public embedUrl?: string;
  public url?: string;
  public parameters: QuicksightDashboardParametersModel;

  constructor() {
    this.url = '';
    this.embedUrl = '';
    this.parameters = null;
  }
}
