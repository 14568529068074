import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AdminOnlyGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private readonly _router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot) {
    return this.authService.isAdmin$.pipe(
      tap((isAdmin) => {
        if (!isAdmin) {
          this._router.navigate(['not-found'], {});
        }
      })
    );
  }
}
