import {JsonProperty} from 'json-object-mapper';
import {
  MasteryStatusTextConfig
} from 'libs/coach-bot/data-access/math-skills-stack/src/lib/config/mastery-status-text.config';
import {
  StandardHeaderColorConfig
} from 'libs/coach-bot/data-access/math-skills-stack/src/lib/config/standard-header-color.config';
import {MasteryColorConfig} from '../config/mastery-color.config';
import {MasteryStatus} from '../enums/mastery-status.enum';
import {LearningResource} from './learning-resource.model';

const HUNDRED = 100;
const MASTERED_COLOR = '#28A745';
const NOT_FULLY_MASTERY_COLOR = '#63D97F';
const NOT_MASTERED_COLOR = '#F2F2F2';

export class Standard {
  @JsonProperty() public externalId: string;
  @JsonProperty() public description: string;
  @JsonProperty() public domainName: string;
  @JsonProperty() public domainAlias: string;
  @JsonProperty() public isSelfAssessed: boolean;
  @JsonProperty() public masteryPercentage?: number;
  @JsonProperty({ type: LearningResource }) public learningResources: LearningResource[];
  @JsonProperty() public masteryStatus: MasteryStatus;
  public learnSectionResources: LearningResource[] = []
  public practiceSectionResources: LearningResource[] = []
  public additionalSectionResources: LearningResource[] = []

  constructor() {
    this.externalId = '';
    this.description = '';
    this.domainAlias = '';
    this.domainName = '';
    this.learningResources = [];
    this.masteryPercentage = null;
    this.masteryStatus = null;
    this.isSelfAssessed = false;
  }

  public get color() {
    return MasteryColorConfig[this._masteryStatus];
  }

  public get headerColor() {
    return StandardHeaderColorConfig[this._masteryStatus];
  }

  public get masteryTooltip() {
    return this._masteryStatus === MasteryStatus.NotFullyMastered ? MasteryStatusTextConfig[MasteryStatus.NotMastered] : MasteryStatusTextConfig[this._masteryStatus];
  }

  public get showGrade(): boolean {
    return this._masteryStatus === MasteryStatus.NotMastered || this._masteryStatus === MasteryStatus.Mastered || this._masteryStatus === MasteryStatus.NotFullyMastered || this._masteryStatus === MasteryStatus.NotEvaluated;
  }

  public get isMastered(): boolean {
    return this.masteryPercentage === HUNDRED;
  }

  public get progressValue(): string {
    return `${this.masteryPercentage}%`;
  }

  public get progressColor(): string {
    if (this.masteryPercentage === HUNDRED) {
      return MASTERED_COLOR;
    } else if (this.masteryPercentage === 0) {
      return NOT_MASTERED_COLOR;
    } else {
      return NOT_FULLY_MASTERY_COLOR;
    }
  }

  public get isNotProficient(): boolean {
    return this._masteryStatus === MasteryStatus.NotProficient;
  }

  public get isShowSelfAssessedFeature(): boolean {
    return this.masteryStatus === MasteryStatus.NotProficient
        || this.masteryStatus === MasteryStatus.NotEvaluated
        || this.masteryStatus === MasteryStatus.NotMastered
        || this.masteryStatus === MasteryStatus.NotFullyMastered;
    }

  private get _masteryStatus() {
    return this.masteryStatus;
  }
}
