import { Injectable } from '@angular/core';

import { AppSyncHelper } from '../helpers';
import { TOGGLE_SELF_ASSESSED_STATUS_FOR_STANDARD } from '../resolvers';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StandardService {

  constructor(public appSyncHelper: AppSyncHelper) {}

  public toggleSelfAssessedStatusForStandard(studentId: string, standardExternalId: string, selfAssessed: boolean): Observable<boolean> {
    return this.appSyncHelper.mutate(TOGGLE_SELF_ASSESSED_STATUS_FOR_STANDARD, {studentId, standardExternalId, selfAssessed}).pipe(
      map((response: { toggleSelfAssessedStatusForStandard: boolean }) => response.toggleSelfAssessedStatusForStandard),
      map(_data => selfAssessed));
  }
}
