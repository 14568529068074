export const GET_LEADERBOARDS_V2 = /* GraphQL */ `
  query GetLeaderboardsV2 {
    getLeaderboardsV2 {
      id
      key
      name
      description
      shortName
      image
      quicksightDashboard
      metricHeader
      additionalDataHeader
      dataLastUpdatedAt
    }
  }
`;
