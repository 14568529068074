import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

import {Student} from '@app/core/models/student.model';
import {NotificationModel} from '@app/modules/notifications/models/notification.model';

@Component({
  selector: 'notifications',
  styleUrls: ['./notifications.component.scss'],
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent {
  /** Holds the notifications list. */
  @Input() public notifications: Array<NotificationModel>;

  /** To be used to identify if the student is selected for notifications. */
  @Input() public hasNotifications: boolean;

  /** To be used to get the student info, for which notifications will be shown */
  @Input() public student: Student;

  /** Whether the logged in user is student or not. */
  @Input() public isStudent: boolean;

  /** Emitter for closing the popup. */
  @Output() close: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(private readonly elRef: ElementRef) {}

  /** Trigger the event on close icon click. */
  public onClose(event: MouseEvent): void {
    this.close.emit(event);
  }

  /** Detect the click event is outside the popup. */
  @HostListener('document:click', ['$event'])
  public clickOut(event: MouseEvent): void {
    if(!this.elRef.nativeElement.contains(event.target)) {
      this.onClose(event);
    }
  }
}
