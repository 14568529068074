export const DATE_FNS_ISO_DATE = 'Y-MM-dd';
export const DATE_FNS_WEEKDAY = 'EEEE';
export const MOMENT_ISO_DATE = 'Y-MM-DD';
export const PIPE_ISO_DATE = 'yyyy-MM-dd';
export const PIPE_SHORT_MONTH_DAY = 'MMM d';
export const PIPE_SHORT_MONTH_YEAR = 'MMM yyyy';
export const PIPE_SHORT_TIME = 'h a';
export const PIPE_SHORT_TIME_WITH_MINUTES = 'h:mm a';
export const UPDATED_ON_LABEL_DATE = 'MMM d, y, h:mm a';
export const MONTH_PIPE = 'MMM';
export const DATE_PIPE = 'dd';
export const FULL_DATE_DD_MONTH_YEAR = 'do LLLL yyyy';
