import {BehaviorSubject, of, Subscription} from 'rxjs';
import { distinctUntilChanged, filter, first, shareReplay, tap } from 'rxjs/operators';

import { STORAGE_KEY } from '@app/core/config/app.config';
import { YearsAtAlpha } from '@app/core/models/years-at-alpha.model';
import {YEARS_AT_ALPHA} from "@app/core/mock/years-at-alpha.mock";

export const defaultYearAtAlpha = '2+';

export class YearsAtAlphaServiceMock {
  private readonly yearAtAlpha   = new BehaviorSubject<string>(localStorage.getItem(STORAGE_KEY.yearAtAlpha) || defaultYearAtAlpha);
  private readonly yearsAtAlpha  = new BehaviorSubject<YearsAtAlpha[]>([]);

  public yearsAtAlpha$ = this.yearsAtAlpha.asObservable().pipe(
    tap(yearsAtAlpha => {
      if (!yearsAtAlpha.length) {
        this.getAll();
      }
    }),
    filter(studentGradeLevels => Boolean(studentGradeLevels.length)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public getAll(): Subscription {
    return of(YEARS_AT_ALPHA)
      .pipe(first())
      .subscribe(data => this.updateYears(data));
  }

  public updateYear(value: string): void {
    this.yearAtAlpha.next(value);
    localStorage.setItem(STORAGE_KEY.yearAtAlpha, value);
  }

  public updateYears(values: YearsAtAlpha[]): void {
    this.yearsAtAlpha.next(values);
  }
}
