export const USER_PROFILE_IMAGE_UPDATE = /* GraphQL */ `
  mutation UpdateUserProfileImage($userDetails: UpdateUserProfileImageInput!) {
    updateUserProfileImage(userDetails: $userDetails) {
      id
      firstName
      lastName
      email
      roleName
      profileImageURL
      coverImageURL
      acceptedTerms
    }
  }
`;
