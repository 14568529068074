const MAX_MINUTES_FLAG                = 'minutes-of-practice-per-day';
const SLIDER_LABELS                   = 'map-goals-slider-labels';
const STUDENT_ICONS                   = 'student-picker-school-icon';
const DEFAULT_MAX_MINUTES             = 120;
const DEFAULT_SLIDER_LABELS: number[] = ['30', '45', '60', '75', '90', '105', '120']
  .map(value => Number(value));
const DEFAULT_STUDENT_ICONS           = {alpha: false, 'gt-school': false};

export const DEFAULT_FLAGS: { [key: string]: unknown } = {
  [MAX_MINUTES_FLAG]: DEFAULT_MAX_MINUTES,
  [SLIDER_LABELS]:    DEFAULT_SLIDER_LABELS,
  [STUDENT_ICONS]:    DEFAULT_STUDENT_ICONS
};
